import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, createTheme } from '@mui/material/styles';
import {
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination,
    TableRow, Paper, Card, FormControl, Snackbar, Alert, List, ListItemButton, ListItemIcon, Checkbox, ListItemText,
    InputLabel, Dialog, DialogTitle, DialogContent, Button, Grid, useMediaQuery, TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { setTimeoutsecs, FormatDT, pBtn, PerChk, setAutoHideDurationTimeoutsecs, TPA } from '../../../common/Common';
import { getRoletypeList, updateRoletype } from '../../../services/RoletypeService';
import { PermissionList } from '../../../services/PermissionService';

export const mainTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiTextField: { styleOverrides: { root: { marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
        MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
        MuiInputBase: { styleOverrides: { root: { backgroundColor: '#ffffff' } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
        MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } }
    }
})

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function RoletypeMgmt({ setRefresh }) {
    const userRole = sessionStorage.getItem('uRole') || null;
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [roletypeList, setRoletypeList] = React.useState([]);
    const [permissionList, setpermissionList] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [tableDialog, setTableDialog] = useState(false);
    const [edittableDialog, setEditTableDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [formObj, setFormObj] = useState({
        description: selectedRow && selectedRow.description ? selectedRow.description : "",
        permission: [],
    })
    const [message, setMessage] = useState(false);
    const [severityColor, setSeverityColor] = useState('');

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    const [checked, setChecked] = React.useState([]);
    const [unselected, setUnselected] = React.useState([]);
    const [selected, setSelected] = React.useState([]);


    /* left to be renamed as unselected
    right to be renamed as selected */

    // const [left, setLeft] = React.useState([0, 1, 2, 3]);
    // const [right, setRight] = React.useState([4, 5, 6, 7]);

    const leftChecked = intersection(checked, unselected);
    const rightChecked = intersection(checked, selected);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setSelected(selected.concat(unselected));
        setUnselected([]);
    };

    const handleCheckedRight = () => {
        setSelected(selected.concat(leftChecked));
        setUnselected(not(unselected, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setUnselected(unselected.concat(rightChecked));
        setSelected(not(selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setUnselected(unselected.concat(selected));
        setSelected([]);
    };

    const customList = (items) => (
        <Paper sx={{ overflow: 'auto', height: 400 }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    const disableSubmit = !formObj.description;
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleRowClick = async (row) => {
        setSelectedRow(row);
        setTableDialog(true);
    };

    const handleEditRowClick = async (row) => {
        setEditTableDialog(true);
        setSelectedRow(row);
        let unselectedPTemp

        if (row.permission) {
            unselectedPTemp = permissionList
                .filter(item => !row.permission.includes(item.key))
                .map(item => item.key)

            setUnselected(unselectedPTemp)
            setSelected(row.permission)

        }

        setFormObj(prevState => ({
            ...prevState,
            title: row.title,
            key: row.key,
            description: row.description,
            permission: row.permission,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            let data = {
                title: selectedRow.title,
                key: selectedRow.key,
                description: formObj.description ? formObj.description : selectedRow.description,
                permission: selected,
            }
            let resp = await updateRoletype(selectedRow._id, data);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setSeverityColor("success")
                    setMessage("Role permissions updated")
                    setOpenSnackbar(true);
                    setEditTableDialog(false)
                    fetchRoleTypes();
                }, setTimeoutsecs);
            }
            let resp2 = await getRoletypeList({ "key": `${sessionStorage.getItem('uRole')}` }); if (resp2) {
                sessionStorage.setItem('uPer', JSON.stringify(resp2.result[0].permission)); setRefresh(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setMessage(error.response.data.message)
            } else {
                setMessage(error.response.data.message)
                console.log("An unexpected error occurred:", error.message);
            }
            console.log("An unexpected error occurred:", error);
            setSeverityColor("error")
            setOpenSnackbar(true);
            setTimeout(() => {
                setLoading(false);
            }, setTimeoutsecs);
        }
    }

    const fetchRoleTypes = async () => {
        try {
            let resp = await getRoletypeList();
            setRoletypeList(resp.result);
        } catch (error) {

            console.log(error)
        }
    };

    const fetchPermissions = async () => {
        try {
            let resp = await PermissionList();
            setpermissionList(resp.result);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        fetchRoleTypes();
        fetchPermissions();
    }, []);

    const filteredData = roletypeList.filter(user => {
        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });

        return matchesSearchQuery;
    });
    const nonMobileStyles = {
        flexGrow: 1,
        p: 3,
    };

    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}><Container component="main" >
        <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                <FormControl sx={{ width: '130px' }}>
                    <TextField
                        label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
            </Box >
        </Box>

        {filteredData.length > 0 ? <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}><Table>
                <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                    <TableRow sx={{ alignItems: 'center' }}>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "15%" }}>Title</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "20%" }}>Description</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "15%" }}>Key</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "40%" }}>Permission</TableCell>
                        {userRole === 'SUPER_ADMIN' && (
                            <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "10%" }}></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredData
                    ).map((row) => (
                        <TableRow key={row.email_id} onClick={() => handleRowClick(row)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#fcf2e7',
                                },
                            }}>
                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                {row.description}
                            </TableCell>
                            <TableCell component="th" scope="row" >
                                {row.key}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ wordWrap: 'break-word', wordBreak: 'break-all', flowWrap: 'break-word' }}>
                                {row.permission && (row.permission.join(' , '))}
                            </TableCell>
                            <TableCell>{!isMobile &&
                                <EditIcon hidden={PerChk('EDIT_USERROLE')} sx={{ color: '#b5651d' }} title="Edit"
                                    onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={filteredData.length}
                            page={page} rowsPerPage={rowsPerPage}
                            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                            onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                            ActionsComponent={TPA}
                        />
                    </TableRow>
                </TableFooter>
            </Table></TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}


        {selectedRow && <Dialog open={tableDialog} fullWidth maxWidth='lg' sx={{ '& .MuiDialog-paper': { height: '100%', maxHeight: '100%', margin: 0 } }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Grid container spacing={2} style={{ alignItems: "center", display: "flex" }}>
                    <Grid item sm={11} style={{ display: "flex", justifyContent: "start" }}>
                        <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.title : 'Roletype Detail'}</Typography>
                    </Grid>
                    <Grid item sm={1} style={{ display: "flex", justifyContent: "end" }}><CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} /></Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>{selectedRow && (
                <Card><Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Title</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{selectedRow.title}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Description</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{selectedRow.description}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Key</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography >{selectedRow.key}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Permission</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all', flowWrap: 'break-word', }}>{selectedRow.permission && (selectedRow.permission.join(' , '))}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Created At</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{FormatDT(selectedRow.created_at)}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Updated At</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{FormatDT(selectedRow.updated_at)}</Typography>
                    </Grid>
                </Grid></Card>
            )}</DialogContent>
        </Dialog>}

        <Dialog open={edittableDialog} fullWidth maxWidth='lg' sx={{ '& .MuiDialog-paper': { height: '100%', maxHeight: '100%', margin: 0 } }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow !== null ? selectedRow.title : 'Edit'}</Typography>
                <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setEditTableDialog(false)} />
            </DialogTitle>

            <DialogContent>
                {selectedRow && <Card><Grid container spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                    <Grid item sm={6}> <TextField required label='Title' value={selectedRow.title} disabled /></Grid>

                    <Grid item sm={6}> <TextField required label="Description" value={formObj.description}
                        onChange={(e) => setFormObj({ ...formObj, description: e.target.value })}></TextField>
                    </Grid>

                    <Grid item sm={6}> <TextField required label='Key' value={selectedRow.key} disabled /></Grid>

                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginLeft: "unset", marginBottom: "16px" }}>
                        <Grid item sm={12} style={{ marginTop: "16px" }}>
                            <InputLabel required>Permissions</InputLabel>
                        </Grid>
                        <Grid item sm={5}>{customList(unselected)}</Grid>
                        <Grid item sm={2}>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={unselected.length === 0}
                                    aria-label="move to selected"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected to selected section"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected to unselected section"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={selected.length === 0}
                                    aria-label="move to unselected"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item sm={5}>{customList(selected)}</Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button sx={pBtn} onClick={handleSubmit} disabled={disableSubmit || loading}>{loading ? <CircularProgress /> : 'Save'}</Button>
                    </Grid>
                </Grid></Card>}
            </DialogContent>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={setAutoHideDurationTimeoutsecs} onClose={() => setOpenSnackbar(false)}>
            <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={severityColor}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    </Container></Box>)
}