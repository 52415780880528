import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, Card, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Suicide() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  return (<Card>
    <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
      <Box component='img' style={{ width: '100%', height: isMobile ? '200px' : '500px', objectFit: 'cover', borderRadius: '15px' }}
        src='images/About_Suicide.jpg' alt='About Suicide' />
    </Box>

    <Typography variant="h6" sx={{ mt: '5px' }}>About Suicide</Typography>

    <Typography sx={{ mb: '10px' }}>
      According to WHO, every year, more than 800,000 people die by suicide i.e. every 40 seconds a person dies by suicide somewhere in the world.
      Suicide is now the second leading cause of death in the age group 15-29.
    </Typography>

    <Typography sx={{ mb: '10px' }}>
      In India, according to the latest National Crime Records Bureau (NCRB) Report,
      1,70,924 people died by suicide in the year 2022. The enormity of the situation is reflected in the fact that for every completed suicide,
      there are at least  20 attempted suicides. This suggests that even by a conservative estimate, there are about  2.5 to 3 million suicide attempts
      in India every year. This makes it a major public health concern and requires immediate concerted effort from all stakeholders.
    </Typography>

    <Typography sx={{ mb: '30px' }}>
      Suicides can be prevented
      with effective care and timely support. Suicide Prevention is everyone’s responsibility. Let us take the first step by acknowledging and accepting
      every person with suicide ideation, and follow it through with our unconditional offer to support him/her through this painful phase.
    </Typography>

    <Typography variant="h6" >FAQ</Typography>

    <Accordion sx={{ mb: '10px' }}><AccordionSummary expandIcon={<ExpandMoreIcon />} id="1"><Typography fontWeight='bold'>
      Why do people attempt suicide ?
    </Typography></AccordionSummary><AccordionDetails><Typography>
      Suicide is a cry for help. A person who is thinking of ending his / her own life
      is going through so much emotional pain that he / she finds death less painful than living.
      It is not that they do not want to live; but only that they do not know how to continue living
      with their emotional pain anymore. Such a person needs immediate support from those around him.
    </Typography></AccordionDetails></Accordion>

    <Accordion sx={{ mb: '10px' }}><AccordionSummary expandIcon={<ExpandMoreIcon />} id="1"><Typography fontWeight='bold'>
      Do people who talk of suicide often really mean to die ?
    </Typography></AccordionSummary><AccordionDetails><Typography >
      People who speak about wanting to die are conveying their distress. It is a sign that
      they are finding life difficult to cope with and that the thought of death is recurring
      in their mind. They keep oscillating between life and death. As such, it is important that
      those of us around listen to them and help them feel cared for.
    </Typography></AccordionDetails></Accordion>

    <Accordion sx={{ mb: '10px' }}><AccordionSummary expandIcon={<ExpandMoreIcon />} id="1"><Typography fontWeight='bold'>
      What should you do if someone tells you they are feeling suicidal ?
    </Typography></AccordionSummary><AccordionDetails><Typography sx={{ mb: '5px' }}>
      Someone who has chosen to talk to you about his / her suicidal feelings, is desperately looking
      for help. Listen to them, don’t criticise them, or brush aside their feelings.Remember, that it
      could have taken a lot of effort on their part to open up about their suicidal feelings.Just listening
      to them and being with them, will go a long way in helping them feel cared for. Assure them that you are
      always available for them to talk to.
    </Typography>
        <Typography >
          You could also encourage them to talk to a suicide prevention helpline. Additionally, you could explore
          the possibility of and their willingness to take professional help for their suicidal feelings.
        </Typography></AccordionDetails></Accordion>

    <Accordion sx={{ mb: '10px' }}><AccordionSummary expandIcon={<ExpandMoreIcon />} id="1"><Typography fontWeight='bold'>
      What are some of the signs that one should look out for?
    </Typography></AccordionSummary><AccordionDetails><Typography >
      A person who is suicidal almost always gives out indications about his suicidal ideation. These signals
      could take many different forms. Some signs that could alert you to a person who is thinking of ending his/her
      life are – talking of being a burden to others or wanting to die, drastic changes in usual behaviour, withdrawing
      from people, disinterest in things that were earlier enjoyed, extreme mood swings, increased use of alcohol or drugs,
      any other behaviour that could endanger his/her life.
    </Typography></AccordionDetails></Accordion>

    <Accordion sx={{ mb: '20px' }}><AccordionSummary expandIcon={<ExpandMoreIcon />} id="1"><Typography fontWeight='bold'>
      Are some people more likely to go through feelings of wanting to end their lives ?
    </Typography></AccordionSummary><AccordionDetails><Typography >
      Like all other human emotions, the feeling of wanting to end one’s life can come to anyone
      in their moment of distress. Irrespective of a person’s background or status, education, race,
      culture, creed, suicidal feelings are prevalent across the board making it a universal leveler.
      But there are a few risk factors that could increase the suicide risk of a person. This includes
      suicide attempts in the past, history of mental health conditions(Bipolar, Schizophrenia, Depression, etc.),
      recent illness(physical and mental) which is causing distress, death of a family member or a close friend by suicide,
      irrecoverable personal loss, and abuse. People with these risk factors require support until they feel able to cope with their situation.
    </Typography></AccordionDetails></Accordion>
  </Card>)
}