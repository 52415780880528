import React, { useState, useEffect } from 'react';
import { Card, Toolbar, Typography, Tab, Tabs, useMediaQuery, MenuItem, Menu, Avatar, Grid, Modal, Box, Alert, Tooltip, Link, TextField } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { LoginRounded, CloseRounded, ShareRounded, EmailRounded, CallRounded, ArrowBack } from '@mui/icons-material';
import { styled } from '@mui/system';
import { FacebookShareButton, EmailShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, EmailIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import Sas from './Sas';
import Sneha from './Sneha';
import Suicide from './Suicide';
import Survivors from './Survivors';
import Support from './Support';
import Media from './Media';
import Experiences from './Experiences';
import MemoWall from './MemoWall';
import Administration from './Administration';
import MyAc from './MyAc';
import MyMeet from './MyMeet';
import MyCon from './MyCon';
import checkTknExpiry from '../common/TokenCheck';
import { PerChk } from '../common/Common';
import { user, phqAnsList } from '../services/UserService';
import { AddVisitor } from '../services/VisitorService';

export default function TabsCore() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const uId = sessionStorage.getItem('userId') || null;
  const userRole = sessionStorage.getItem('uRole') || null;
  const snehaURL = "https://snehaindia.org/";
  const shareURL = "https://sas.snehaindia.org/sas";
  const [tokenExpired, setTokenExpired] = useState(false);
  const [fName, setfName] = useState('');
  const [modShow, setmodShow] = useState(false);
  const [alertShow, setalertShow] = useState(false);
  const [phqShow, setphqShow] = useState(false);
  const [tI, settI] = useState('0');
  const [avatarAnchor, setavatarAnchor] = useState(null);
  const handleClickAv = (event) => { setavatarAnchor(event.currentTarget) }
  const handleCloseAv = () => { setavatarAnchor(null) }
  const FtrTxt = ({ txt, aln }) => (<Grid item xs={isMobile ? 12 : 4} sx={{ textAlign: isMobile ? 'center' : aln }}><Typography sx={{ fontSize: isMobile ? '50%' : '100%', color: '#b5651d' }}>{txt}</Typography></Grid>)

  useEffect(() => {
    const result = checkTknExpiry((expired) => { setTokenExpired(expired) }); if (result) {
      const { timerRef, tknExpired } = result;
      setTokenExpired(tknExpired);
      return () => clearTimeout(timerRef);
    };
  }, [])

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (uId && isMounted) {
        try {
          const resp1 = await user(uId); if (resp1) {
            setfName(resp1.result.full_name);
            if (!userRole) {
              sessionStorage.setItem('uRole', resp1.result.roletype_key);
              setalertShow(true); setTimeout(() => { setalertShow(false) }, 3000);
            }
          }
          if (userRole === 'SAS_MEMBER') {
            const resp2 = await phqAnsList(); if (resp2) {
              const resp2Tmp = resp2.result.find(item => item.user_id === uId);
              if (resp2Tmp) { resp2Tmp.response.length <= 0 && setphqShow(true) }
              else { setphqShow(true) }
            }
          }
        }
        catch (error) { console.log(error) }
      }
    }; fetchData();
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    const VisitorCount = async () => {
      const now = new Date().toISOString();
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      try {
        if (!localStorage.getItem('countId')) {
          let randomId = '';
          for (let i = 0; i < 16; i++) { randomId += characters.charAt(Math.floor(Math.random() * characters.length)) }
          const vData = { uuid: randomId, uuid_createdAt: now }
          const resp = await AddVisitor(vData); if (resp) {
            localStorage.setItem('countId', randomId);
            localStorage.setItem('countTime', now);
          }
        }
        else if (new Date(now) - new Date(localStorage.getItem('countTime')) >= 3600000) {
          const vData = { uuid: localStorage.getItem('countId'), uuid_createdAt: now }
          const resp = await AddVisitor(vData); if (resp) {
            localStorage.setItem('countTime', now)
          }
        }
      }
      catch (error) { console.log(error) }
    }; VisitorCount();
  }, [tI])

  return (<ThemeProvider theme={mainTheme}>
    {alertShow && <StyledAlert>Signed in as {sessionStorage.getItem('email_id') || fName}</StyledAlert>}
    {tokenExpired && <StyledAlert severity='error'>Your token has expired</StyledAlert>}
    {phqShow && <StyledAlert severity='info'>Please finish filling up your PHQ9 form in 'My account'<CloseRounded sx={{ cursor: 'pointer', ml: 1, color: '#f00' }} onClick={() => setphqShow(false)} /></StyledAlert>}

    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: isMobile ? '40px' : '25px' }}>
      <header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden', marginLeft: "10px", paddingRight: "20px" }}>{isMobile ? <>
        <img src='images/SnehaP.png' alt='Sneha SAS' href={snehaURL} style={{ width: '40%' }} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Typography component='a' href="tel:+919445120050" sx={{ fontWeight: 'bold', fontSize: '50%', color: '#b5651d', '&:hover': { color: '#000' } }}><CallRounded />+91 94451 20050</Typography>
          <Typography component='a' href="mailto:sas@snehaindia.org" sx={{ fontWeight: 'bold', fontSize: '50%', color: '#b5651d', '&:hover': { color: '#000' } }}><EmailRounded />sas@snehaindia.org</Typography>
        </div>
        <ShareRounded sx={{ mt: '10px', color: '#c57530', '&:hover': { cursor: 'pointer', color: 'black' } }} onClick={() => setmodShow(true)} />
        <Avatar onClick={userRole ? handleClickAv : null}>{userRole ? fName[0] : <a href='signin'><LoginRounded sx={{ color: '#fff', '&:hover': { color: '#000' } }} /></a>}</Avatar>
      </> : <>
        <img src='images/SnehaL.png' alt='Sneha SAS' href={snehaURL} style={{ height: '50px' }} />
        <div className='row' style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
          <Typography component='a' href="tel:+919445120050" sx={{ fontWeight: 'bold', fontSize: '150%', color: '#b5651d', '&:hover': { color: '#000' } }}><CallRounded />+91 94451 20050</Typography>
          <Typography component='a' href="mailto:sas@snehaindia.org" sx={{ fontWeight: 'bold', fontSize: '150%', color: '#b5651d', '&:hover': { color: '#000' } }}><EmailRounded />sas@snehaindia.org</Typography>
          <Tooltip title="Share this website with others"><ShareRounded sx={{ color: '#c57530', '&:hover': { cursor: 'pointer', color: 'black' } }} onClick={() => setmodShow(true)} /></Tooltip>
          <Avatar onClick={userRole ? handleClickAv : null}>{userRole ? fName[0] : <a href='signin'><LoginRounded sx={{ color: '#fff', '&:hover': { color: '#000' } }} /></a>}</Avatar>
        </div>
      </>}</header>

      <Toolbar sx={{ display: 'flex', alignItems: 'center', bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>{tI === '10' || tI === '8' ?
        <Typography variant='h6' sx={{ color: '#fff', display: 'flex', mb: 0 }}>
          <ArrowBack sx={{ color: '#fff', '&:hover': { color: '#d09d70', cursor: 'pointer' } }} onClick={() => settI('0')} />
          {tI === '10' ? 'MY ACCOUNT' : 'MY MEETINGS'}
        </Typography>
        :
        <>{isMobile ? <TextField select value={tI} onChange={(e) => settI(e.target.value)}>
          <MenuItem value='0'>Home</MenuItem>
          <MenuItem value='1'>SNEHA</MenuItem>
          <MenuItem value='2'>About Suicide</MenuItem>
          <MenuItem value='3'>Survivors</MenuItem>
          <MenuItem value='4'>Support Us</MenuItem>
          <MenuItem value='5'>Media</MenuItem>
          <MenuItem value='6'>Experiences</MenuItem>
          <MenuItem value='7'>Memorial Wall</MenuItem>
          <MenuItem value='9' hidden={PerChk('ADMINISTRATION')}>Administration</MenuItem>
        </TextField> : <ThemeProvider theme={tabTheme}><Tabs value={tI} onChange={(e, v) => settI(v)} sx={{ width: '100%' }} centered>
          <Tab value="0" label="Home" />
          <Tab value="1" label="SNEHA" />
          <Tab value="2" label="About Suicide" />
          <Tab value="3" label="Survivors" />
          <Tab value="4" label="Support Us" />
          <Tab value="5" label="Media" />
          <Tab value="6" label="Experiences" />
          <Tab value="7" label="Memorial Wall" />
          <Tab value="9" label="Administration" hidden={PerChk('ADMINISTRATION')} />
        </Tabs></ThemeProvider>}</>
      }</Toolbar>

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: (tI === '6' || tI === '9' || tI === '11') ? 0 : 2 }}>
        {tI === '0' && <Sas />}
        {tI === '1' && <Sneha />}
        {tI === '2' && <Suicide />}
        {tI === '3' && <Survivors />}
        {tI === '4' && <Support />}
        {tI === '5' && <Media />}
        {tI === '6' && <Experiences />}
        {tI === '7' && <MemoWall />}
        {tI === '8' && <MyMeet />}
        {tI === '9' && <Administration setCr={settI} />}
        {tI === '10' && <MyAc />}
        {tI === '11' && <MyCon />}
      </Box>

      <Grid container component='footer' className='fixed-bottom' sx={{ alignItems: 'start', bgcolor: '#e0cbbf', height: isMobile ? '40px' : '25px', p: '2px' }}>
        <FtrTxt txt={<Link href={snehaURL}>© Copyright 2024 SNEHA Foundation Trust</Link>} aln='start' />
        <FtrTxt txt={<b>Supported by Chennai Willingdon Corporate Foundation</b>} aln='center' />
        <FtrTxt txt={<p>Powered by Abyantra</p>} aln='end' />
      </Grid>
    </Box>

    <Modal open={modShow} onClose={() => setmodShow(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: isMobile ? '90%' : '60%', height: isMobile ? '80%' : 'auto', overflow: 'auto' }}><Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" >Share this website with someone who might need help</Typography>
          <CloseRounded onClick={() => setmodShow(false)} sx={{ color: 'white', bgcolor: 'red', borderRadius: '5px' }} />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <FacebookShareButton url={shareURL}><FacebookIcon size='48' round={true} /></FacebookShareButton>
          <EmailShareButton url={shareURL}><EmailIcon size='48' round={true} /></EmailShareButton>
          <TwitterShareButton url={shareURL}><TwitterIcon size='48' round={true} /></TwitterShareButton>
          <WhatsappShareButton url={shareURL}><WhatsappIcon size='48' round={true} /></WhatsappShareButton>
        </Grid>

        <Grid item xs={12} component={Typography}>
          <span style={{ display: 'block', marginBottom: '8px' }}>
            The experience of grief isn't confined to a specific timeframe following the passing of a loved one; rather, it can persist even beyond initial stages. Triggers frequently resurrect the ache of their absence, leading to encounters with grief repeatedly, perhaps even long after their departure.
          </span>
          <span style={{ display: 'block', marginBottom: '8px' }}>
            If you know someone who is suffering from a loss due to suicide, you can refer them to SNEHA - SAS. This is an open space where everyone can share their feelings, emotions and experiences. We sometimes feel alone in our struggles and forget to reach out to others who may have had similar experiences. Sharing our experiences can strengthen us, helps us feel less alone and brings us closer to others.
          </span>
        </Grid>
      </Grid></Card>
    </Modal>

    <Menu anchorEl={avatarAnchor} open={Boolean(avatarAnchor)} onClose={handleCloseAv}>
      <MenuItem onClick={() => { settI('8'); handleCloseAv(); }}>My Meetings</MenuItem>
      <MenuItem onClick={() => { settI('10'); handleCloseAv(); }} hidden={PerChk('MY_ACCOUNT')}>My Account</MenuItem>
      <MenuItem onClick={() => { settI('11'); handleCloseAv(); }} hidden={PerChk('MY_CONTENT')}>My Content</MenuItem>
      <MenuItem onClick={() => { settI('10'); handleCloseAv(); }} hidden={PerChk('MY_ACCOUNT')}>Change Password</MenuItem>
      <MenuItem onClick={() => { sessionStorage.clear(); }} component={Link} href='/sas'>Logout</MenuItem>
    </Menu>
  </ThemeProvider>)
}

export const mainTheme = createTheme({
  palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
  components: {
    MuiButton: { styleOverrides: { root: { borderRadius: '12px', color: '#fff', backgroundColor: '#a5644e', textTransform: 'none', '&:hover': { color: '#000', backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } } } },
    MuiCard: { styleOverrides: { root: { display: 'flex', flexDirection: 'column', backgroundColor: '#fcf2e7', borderRadius: '15px', margin: '10px 0px', padding: 15, boxShadow: '0px 0px 3px' } } },
    MuiContainer: { styleOverrides: { root: { display: 'flex', flexDirection: 'column', backgroundColor: '#fcf2e7', borderRadius: '15px', margin: '10px 0px', padding: 15, boxShadow: '0px 0px 3px' } } },
    MuiDialog: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: 15, backdropFilter: 'blur(15px)' } }, defaultProps: { maxWidth: 'lg' } },
    MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
    MuiInputBase: { styleOverrides: { root: { backgroundColor: '#fff' } } }, MuiTextField: { defaultProps: { fullWidth: true } },
    MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
    MuiLink: { styleOverrides: { root: { color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } },
    MuiAvatar: { styleOverrides: { root: { cursor: 'pointer', backgroundColor: '#b5651d', color: '#fff', '&:hover': { color: '#000' } } } },
    MuiFab: { styleOverrides: { root: { backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' } } } },
    MuiMenuItem: { styleOverrides: { root: { '&:hover': { color: '#c9762b' } } } }, MuiAlert: { defaultProps: { variant: 'filled' } },
    MuiSnackbar: { defaultProps: { anchorOrigin: { vertical: 'top', horizontal: 'center' } } }, MuiCircularProgress: { defaultProps: { size: 20 } },
    MuiTableBody: { styleOverrides: { root: { '& .MuiTableRow-root:hover': { backgroundColor: '#fcf2e7', cursor: 'pointer' } } } },
    MuiTableCell: { styleOverrides: { root: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'break-word', maxWidth: '256px' } } },
    MuiTableRow: { styleOverrides: { root: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'break-word', maxHeight: '56px' } } }
  }
})

export const tabTheme = createTheme({
  palette: { primary: { main: '#000' }, secondary: { main: '#fff' } },
  components: { MuiTab: { styleOverrides: { root: { textTransform: 'none', fontWeight: 'bold', color: '#fff', '&:hover': { color: '#000' } } } } }
})

export const tabThemeAdmin = createTheme({
  palette: { primary: { main: '#000' }, secondary: { main: '#fff' } },
  components: { MuiTab: { styleOverrides: { root: { textTransform: 'none', fontWeight: 'bold', color: '#a5644e', '&:hover': { color: '#000' } } } } }
})

export const StyledAlert = styled(Alert)({
  color: '#fff',
  position: 'fixed',
  top: 15,
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: 1500
})