import React, { useState, useEffect } from 'react';
import { Tab, Tabs, useMediaQuery, Grid, Menu, MenuItem, Divider } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { tabThemeAdmin } from './TabsCore';
import UserManagementTab from './Admin/UserManagement/UserManagementTab';
import MeetingManagement from './Admin/MeetingManagement';
import ExperienceManagementTab from './Admin/ExperienceManagement/ExperienceManagementTab';
import MediaManagement from './Admin/MediaManagement';
import MemorialManagementTab from './Admin/MemorialWall/MemorialManagementTab';
import RoletypeMgmt from './Admin/RoletypeManagement/RoletypeList';
import VisitorCount from './Admin/VisitorCount/VisitorList';
import HomeManagement from './Admin/HomeManagement/HomeContent';
import { PerChk } from '../common/Common';

export default function Administration({ setCr }) {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tI, settI] = useState('0');
    const [mobileMenu, setMobileMenu] = useState(null);
    const handleClick = (event) => { setMobileMenu(event.currentTarget) }
    const handleClose = () => { setMobileMenu(null) }
    const [refresh, setRefresh] = useState(false);
    const userManagement = PerChk('USER_MANAGEMENT');
    const meetingManagement = PerChk('MEETING_MANAGEMENT');
    const experienceManagement = PerChk('EXPERIENCE_MANAGEMENT');
    const mediaManagement = PerChk('MEDIA_MANAGEMENT');
    const memorialManagement = PerChk('MEMORIAL_MANAGEMENT');
    const userRoleManagement = PerChk('USERROLE_MANAGEMENT');
    const homeManagement = PerChk('HOME_MANAGEMENT');
    const visitorCount = PerChk('VISITOR_VIEW');


    useEffect(() => {
        if (userManagement && !meetingManagement) {
            settI('1');
        } else if (userManagement && meetingManagement && !experienceManagement) {
            settI('2');
        } else if (userManagement && meetingManagement && experienceManagement && !mediaManagement) {
            settI('3');
        } else if (userManagement && meetingManagement && experienceManagement && mediaManagement && !memorialManagement) {
            settI('4');
        } else if (userManagement && meetingManagement && experienceManagement && mediaManagement && memorialManagement && !homeManagement) {
            settI('5');
        } else if (userManagement && meetingManagement && experienceManagement && mediaManagement && memorialManagement && homeManagement && !userRoleManagement) {
            settI('6');
        } else if (userManagement && meetingManagement && experienceManagement && mediaManagement && memorialManagement && homeManagement && userRoleManagement) {
            settI(null);
        }
        if (refresh) {
            PerChk('ADMINISTRATION'); setCr('9'); settI('6'); setRefresh(false);
        }
    },
        [refresh, userManagement, meetingManagement, experienceManagement, mediaManagement, memorialManagement, userRoleManagement, visitorCount]);

    return (<Grid container sx={{ bgcolor: 'background.paper' }}>
        <Grid item xs={isMobile ? 12 : 2}>{isMobile ?
            <>
                <MenuIcon id="basic-button" onClick={handleClick} />
                <Menu id="basic-menu" anchorEl={mobileMenu} open={mobileMenu} onClose={handleClose}>
                    <MenuItem hidden={(userManagement || (PerChk('USER_LIST') && PerChk('USER_APPROVALLIST')))} onClick={() => { setMobileMenu(null); settI('0'); }}>User Management</MenuItem>
                    <MenuItem hidden={(meetingManagement || (PerChk('FUTURE_MEETINGS') && PerChk('PAST_MEETINGS') && PerChk('CANCELLED_MEETINGS')))} onClick={() => { setMobileMenu(null); settI('1'); }}>Meeting Management</MenuItem>
                    <MenuItem hidden={(experienceManagement || (PerChk('EXPERIENCE_LIST') && PerChk('EXPERIENCE_APPROVALLIST')))} onClick={() => { setMobileMenu(null); settI('2'); }}>Experience Management</MenuItem>
                    <MenuItem hidden={(mediaManagement || (PerChk('MEDIA_LIST') && PerChk('MEDIA_APPROVALLIST')))} onClick={() => { setMobileMenu(null); settI('3'); }}>Media Management</MenuItem>
                    <MenuItem hidden={(memorialManagement || (PerChk('MEMORIAL_LIST') && PerChk('MEMORIAL_APPROVALLIST')))} onClick={() => { setMobileMenu(null); settI('4'); }}>Memorial Wall Management</MenuItem>
                    <MenuItem hidden={homeManagement} onClick={() => { setMobileMenu(null); settI('5'); }}>Home Page Management</MenuItem>
                    <MenuItem hidden={userRoleManagement} onClick={() => { setMobileMenu(null); settI('6'); }}>User Role Management</MenuItem>
                    <MenuItem hidden={visitorCount} onClick={() => { setMobileMenu(null); settI('7'); }}>Website Traffic</MenuItem>
                </Menu>
            </>
            :
            <ThemeProvider theme={tabThemeAdmin}><Tabs orientation="vertical" value={tI}
                onChange={(e, ti) => settI(ti)} sx={{ bgcolor: '#fcf2e7', height: '100vh' }}>
                <Tab hidden={(userManagement || (PerChk('USER_LIST') && PerChk('USER_APPROVALLIST')))} value='0' label="User Management" />
                <Tab hidden={(meetingManagement || (PerChk('FUTURE_MEETINGS') && PerChk('PAST_MEETINGS') && PerChk('CANCELLED_MEETINGS')))} value='1' label="Meeting Management" />
                <Tab hidden={(experienceManagement || (PerChk('EXPERIENCE_LIST') && PerChk('EXPERIENCE_APPROVALLIST')))} value='2' label="Experience Management" />
                <Tab hidden={(mediaManagement || (PerChk('MEDIA_LIST') && PerChk('MEDIA_APPROVALLIST')))} value='3' label="Media Management" />
                <Tab hidden={(memorialManagement || (PerChk('MEMORIAL_LIST') && PerChk('MEMORIAL_APPROVALLIST')))} value='4' label="Memorial Wall Management" />
                <Tab hidden={homeManagement} value='5' label="Home Page Management" />
                <Tab hidden={userRoleManagement} value='6' label="UserRole Management" />
                <Divider sx={{borderColor:'#a5644e'}} hidden={visitorCount} />
                <Tab hidden={visitorCount} value='7' label="Website Traffic" />
            </Tabs></ThemeProvider>
        }</Grid>

        <Grid item xs={isMobile ? 12 : 10} sx={{ p: 2 }}>
            {tI === '0' && <UserManagementTab />}
            {tI === '1' && <MeetingManagement />}
            {tI === '2' && <ExperienceManagementTab />}
            {tI === '3' && <MediaManagement />}
            {tI === '4' && <MemorialManagementTab />}
            {tI === '5' && <HomeManagement />}
            {tI === '6' && <RoletypeMgmt setRefresh={setRefresh} />}
            {tI === '7' && <VisitorCount />}
        </Grid>
    </Grid>)
}