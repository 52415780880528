import React from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, Typography, Card, MobileStepper, Button, useMediaQuery } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { HomeDetailslist } from '../services/HomeService';

export default function Sas() {
  const stpTheme = createTheme({ palette: { primary: { main: '#ddd' } } });
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const images = [
    { label: 'Sas (1)', imgPath: 'images/Isolated.jpg' },
    { label: 'Sas (2)', imgPath: 'images/Sas (2).jpg' },
    { label: 'Sas (3)', imgPath: 'images/Sas (3).jpg' }
  ]
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);
  const userRole = sessionStorage.getItem('uRole') || null;
  const [conList, setconList] = React.useState([]);
  const handleNext = () => { setActiveStep((prevActiveStep) => prevActiveStep + 1) }
  const handleBack = () => { setActiveStep((prevActiveStep) => prevActiveStep - 1) }
  const handleStepChange = (step) => { setActiveStep(step) }

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await HomeDetailslist()
      if (resp) { setconList(resp.result.sort((a, b) => a.order - b.order)) }
    }; fetchData();
  }, [])

  return (<>
    <Card>
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', mb: 1 }}>
        <AutoPlaySwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents interval={10000}
          sx={{ my: '10px', mx: '5px', width: '100%', height: '100%' }}>
          {images.map((step, index) => (<Box key={step.label} component="img" src={step.imgPath} alt={step.label}
            sx={{ width: '100%', height: isMobile ? '250px' : '500px', objectFit: 'cover', borderRadius: '15px' }} />))}
        </AutoPlaySwipeableViews>
        {!userRole && <Box><a href='signin'> <Button sx={{ position: 'absolute', bottom: '40px', right: '20px' }}>Sign In / Register</Button></a></Box>}
        <ThemeProvider theme={stpTheme}><MobileStepper
          steps={images.length}
          activeStep={activeStep}
          nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === images.length - 1} startIcon={<KeyboardArrowRight />}
            sx={{ backgroundColor: 'transparent', '&:hover': { color: '#b5651d', backgroundColor: 'transparent' }, marginRight: 'auto' }} />}

          backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0} endIcon={<KeyboardArrowLeft />}
            sx={{ backgroundColor: 'transparent', '&:hover': { color: '#b5651d', backgroundColor: 'transparent' }, marginLeft: 'auto' }} />}

          sx={{ position: 'absolute', zIndex: 9, bottom: 0, left: 0, right: 0, margin: '0 auto', bgcolor: 'inherit', borderRadius: '15px' }}
        /></ThemeProvider>
      </Box>

      {conList.length > 0 && <>
        <Typography variant='h6' sx={{ mb: 1 }}>{conList[0].title}</Typography>
        {conList[0].content.sort((a, b) => a.sub_order - b.sub_order).map((c, i) => <Typography key={i}>
          {c.sub_title && <b>{c.sub_title}</b>}
          <span style={{ display: 'block', whiteSpace: 'pre-line', marginBottom: '8px' }}>{c.description}</span>
        </Typography>)}
      </>}
    </Card>

    {conList.length > 1 && conList.slice(1).map((con, i) => <Card key={i}>
      <Typography variant='h6' sx={{ mb: 1 }}>{con.title}</Typography>
      {con.content.sort((a, b) => a.sub_order - b.sub_order).map((c, i) => <Typography key={i}>
        {c.sub_title && <b>{c.sub_title}</b>}
        <span style={{ display: 'block', whiteSpace: 'pre-line', marginBottom: '8px' }}>{c.description}</span>
      </Typography>)}
    </Card>)}
  </>)
}