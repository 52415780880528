import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    Grid, Paper, CircularProgress, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, ListItem,
    TableFooter, TablePagination, Select, MenuItem, FormControl, Typography, Card, Button, Fab, TextField, List,
    useMediaQuery, Toolbar, Tab, Tabs, Container, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
    Accordion, AccordionSummary, AccordionDetails, InputLabel, InputAdornment, OutlinedInput, Box,
} from '@mui/material';
import { Add, Close, Edit, Circle, ExpandMore } from '@mui/icons-material';
import { userList } from '../../services/UserService';
import { ListMemo, AddMemo, EditMemo } from '../../services/MemoService';
import { StyledAlert, tabThemeAdmin } from '../TabsCore';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { trClose, TPA, FormatDT, adminTH, formatDate, PerChk, pBtn } from '../../common/Common';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ReactQuill from 'react-quill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import '../../../public/styles/client.css';
import ImageIcon from '@mui/icons-material/Image';

const mObj = {
    _id: '',
    name: '',
    birth_date: '',
    death_date: '',
    message: '',
    memopost_status: '',
    image: '',
    memopost_createdby: '',
    memopost_updatedby: '',
    remarks: '',
    archive: '',
    visibility: ''
}

const BPoint = ({ txt }) => (<ListItem><Circle sx={{ fontSize: '65%', mr: '5px' }} />{txt}</ListItem>)

export default function MemTab() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const userId = sessionStorage.getItem('userId');
    const [isLoading, setIsLoading] = useState(false);
    const [ti, setti] = useState(PerChk('MYCONTENT_MEMOLIST') ? '1' : '0');
    const [memObj, setmemObj] = useState(mObj);
    const [memList, setmemList] = useState([]);
    const [uList, setuList] = useState([]);
    const [addAlert, setaddAlert] = useState(null);
    const [editAlert, seteditAlert] = useState(null);
    const [addModal, setaddModal] = useState(false);
    const [editModal, seteditModal] = useState(false);
    const [memModal, setmemModal] = useState(false);
    const [statusFilter, setStatusFilter] = useState('All');
    const [searchFilter, setsearchFilter] = useState('');
    const visibilitydata = ['Members', 'Public'];
    const fileInputRef = useRef('');
    const [valueData, setValue] = useState('');
    const quill = useRef();
    const [selectedFile, setSelectedFile] = useState('');
    const [preview, setPreview] = useState('');
    const [decodedHTML, setDecodedHTML] = useState('');
    const [imageView, setImageView] = useState(false);
    const disableAddBtn = !memObj.name || !memObj.visibility || !memObj.birth_date || !memObj.death_date || !valueData;
    const disableEditBtn = !memObj.name || !memObj.visibility || !memObj.birth_date || !memObj.death_date || !valueData;
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "color",
        "clean",
    ];

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link"],
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    );

    const today = new Date().toISOString().split('T')[0];
    const [page, setPage] = useState(0);
    const memListMap = ti === '1' ?
        memList.filter(m => (statusFilter === 'All' || statusFilter === m?.memopost_status) && (
            m?.name.toLowerCase().includes(searchFilter.toLowerCase()) || m?.visibility.toLowerCase().includes(searchFilter.toLowerCase()) ||
            FormatDT(m?.memopost_createdby).toLowerCase().includes(searchFilter.toLowerCase()) ||
            uList?.find(u => u._id === m?.memopost_createdby)?.full_name?.toLowerCase().includes(searchFilter.toLowerCase())
        )).sort((a, b) => new Date(b.memopost_createdby) - new Date(a.memopost_createdby)).slice(page * 10, page * 10 + 10).filter((m) => m?.memopost_status === 'Rejected')
        :
        memList.filter(m => (statusFilter === 'All' || statusFilter === m?.memopost_status) && (
            m?.name.toLowerCase().includes(searchFilter.toLowerCase()) || m?.visibility.toLowerCase().includes(searchFilter.toLowerCase()) || m?.memopost_status.toLowerCase().includes(searchFilter.toLowerCase()) ||
            FormatDT(m?.memopost_createdby).toLowerCase().includes(searchFilter.toLowerCase()) ||
            uList?.find(u => u._id === m?.memopost_createdby)?.full_name?.toLowerCase().includes(searchFilter.toLowerCase())
        )).sort((a, b) => new Date(b.memopost_createdby) - new Date(a.memopost_createdby)).slice(page * 10, page * 10 + 10)

    useEffect(() => { MemoFetch() }, [])

    const MemoFetch = async () => {
        try {
            const resp1 = await ListMemo(); if (resp1) { setmemList(resp1.result.filter(m => m.memopost_createdby === userId)) }
            const resp2 = await userList(); if (resp2) { setuList(resp2.result.filter(u => u.status === 'Active')) }
        } catch (error) { console.log(error) }
    }

    const MemoAdd = async () => {
        setIsLoading(true);
        const encodedContenttemp = encodeURIComponent(valueData);
        const base64EncodedContent = btoa(encodedContenttemp);
        try {
            let addObj = {
                name: memObj.name,
                birth_date: memObj.birth_date,
                death_date: memObj.death_date,
                visibility: memObj.visibility,
                image: preview,
                image_name: selectedFile && selectedFile.name ? selectedFile.name : selectedFile,
                message: base64EncodedContent,
                memopost_status: "Pending",
                memopost_createdby: userId,
            }
            const resp = await AddMemo(addObj); if (resp) {
                MemoFetch(); setaddModal(false); setmemObj(mObj);
                setPreview(""); setSelectedFile(""); setValue("");
                setaddAlert('success'); setTimeout(() => { setaddAlert(null) }, 3000);
            }
        }
        catch (error) {
            console.log('Error--', error); setaddAlert('error');
            setTimeout(() => { setaddAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const MemoEdit = async (id) => {
        setIsLoading(true);
        const encodedContenttemp = encodeURIComponent(valueData);
        const base64EncodedContent = btoa(encodedContenttemp);
        try {
            let editObj = {
                name: memObj.name,
                birth_date: memObj.birth_date,
                death_date: memObj.death_date,
                visibility: memObj.visibility,
                image: preview,
                image_name: selectedFile && selectedFile.name ? selectedFile.name : selectedFile,
                message: base64EncodedContent,
                memopost_status: "Pending",
                memopost_createdby: userId,
                memopost_updatedby: userId
            }

            const resp = await EditMemo(id, editObj); if (resp) {
                MemoFetch(); seteditModal(false); setmemObj(mObj);
                seteditAlert('success'); setTimeout(() => { seteditAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); seteditAlert('error');
            setTimeout(() => { seteditAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const setValues = (memData) => {
        setSelectedFile(memData.image_name);
        const base64Decoded = atob(memData.message);
        const decodedHTMLT = decodeURIComponent(base64Decoded);
        setDecodedHTML(decodedHTMLT);
        setValue(decodedHTMLT)
    }

    const handleImageView = async () => {
        setImageView(true);
    };

    return (<>
        <Fab hidden={PerChk('ADD_MEMORIAL')} title='Add a memory to memorial wall' onClick={() => { setaddModal(true); setSelectedFile(''); setValue('') }} sx={{ position: 'fixed', bottom: 40, right: 20 }}><Add sx={{ color: '#fff' }} /></Fab>
        {addAlert && <StyledAlert severity={addAlert}>{addAlert === 'success' ? 'Memory added' : 'Failed to add memory'}</StyledAlert>}
        {editAlert && <StyledAlert severity={editAlert}>{editAlert === 'success' ? 'Changes saved' : 'Changes failed to save'}</StyledAlert>}

        <Toolbar><ThemeProvider theme={tabThemeAdmin}><Tabs value={ti}
            onChange={(e, v) => { setti(v); setsearchFilter(''); setStatusFilter('All'); setPage(0); }}>
            <Tab value={'0'} label='Memorial wall list' hidden={PerChk('MYCONTENT_MEMOLIST')} />
            <Tab value={'1'} label='Rejected' hidden={PerChk('MYCONTENT_MEMO_REJECTEDLIST')} />
        </Tabs></ThemeProvider></Toolbar>

        <Container><Grid container>{((ti === '0' && memList.length > 0) || (ti === '1' && memList.filter((m) => m?.memopost_status === 'Rejected').length > 0)) ?
            <>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'flex-end', alignItems: isMobile ? 'flex-end' : null, gap: '10px', mb: 2 }}>
                    {ti === '0' && <FormControl sx={{ width: '125px' }}><Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} size='small'>
                        <MenuItem value='All'>All</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Published">Published</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select></FormControl>}
                    <FormControl sx={{ width: '130px' }}><TextField value={searchFilter} size='small' onChange={(e) => setsearchFilter(e.target.value)} label='Search' /></FormControl>
                </Grid>

                <Grid item xs={12} hidden={(ti === '0' && PerChk('MYCONTENT_MEMOLIST') || (ti === '1' && PerChk('MYCONTENT_MEMO_REJECTEDLIST')))}>{memListMap.length > 0 ?
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}><TableContainer sx={{ maxHeight: 440 }}><Table>
                        <TableHead sx={adminTH}><TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date of Birth</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date of Death</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Posted By</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Visibility</TableCell>
                            {ti === '1' && <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Remarks</TableCell>}
                            {ti === '0' && <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>}
                            <TableCell />
                        </TableRow></TableHead>

                        <TableBody>{memListMap.map((m, i) => (<TableRow key={i} onClick={() => {
                            setmemObj(m);
                            setmemModal(true);
                            const base64Decoded = atob(m.message);
                            const decodedHTMLT = decodeURIComponent(base64Decoded);
                            setDecodedHTML(decodedHTMLT);
                        }}
                            sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                            <TableCell>{m?.name}</TableCell>
                            <TableCell>{formatDate(m?.birth_date)}</TableCell>
                            <TableCell>{formatDate(m?.death_date)}</TableCell>
                            <TableCell>{(uList.find(u => u._id === m?.memopost_createdby))?.full_name}</TableCell>
                            <TableCell>{m?.visibility}</TableCell>
                            {ti === '1' && <TableCell>{m?.remarks}</TableCell>}
                            {ti === '0' && <TableCell sx={{
                                color: m?.memopost_status === 'Published' ? 'green' :
                                    m?.memopost_status === 'Rejected' ? 'red' :
                                        m?.memopost_status === 'Pending' ? 'darkblue' :
                                            'black'
                            }}>{m?.memopost_status}</TableCell>}
                            <TableCell onClick={(e) => e.stopPropagation()} align='right'>
                                {ti === '1' && <IconButton title='Edit rejected memory and resubmit' onClick={(e) => {
                                    setmemObj(m); seteditModal(true); const base64Decoded = atob(m.message);
                                    const decodedHTMLT = decodeURIComponent(base64Decoded);
                                    setDecodedHTML(decodedHTMLT);
                                    setPreview(m.image);
                                    setValues(m);
                                }}><Edit sx={{ color: '#b5651d' }} /></IconButton>}
                                {m.image &&
                                    <IconButton title='View Image' onClick={(e) => { setmemObj(m); handleImageView(m) }}><ImageIcon sx={{ color: '#b5651d' }} /></IconButton>
                                }
                            </TableCell>
                        </TableRow>))}</TableBody>

                        <TableFooter><TableRow><TablePagination count={memListMap.length} page={page} rowsPerPage={10} sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                            onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]} ActionsComponent={TPA} /></TableRow></TableFooter>
                    </Table></TableContainer></Paper>
                    :
                    <Typography variant='h6' sx={{ textAlign: 'center' }}>No results for your search</Typography>
                }</Grid>
            </>
            :
            <Grid item xs={12}><Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography></Grid>
        }</Grid></Container>

        <Dialog fullWidth open={addModal}>
            <Close sx={trClose} onClick={() => { setaddModal(false); setmemObj(mObj); }} />

            <DialogTitle sx={{ mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>Add New Memory</Typography></DialogTitle>

            <DialogContent><Card sx={{ gap: '10px' }}>
                <Accordion defaultExpanded sx={{ border: '1px solid gray' }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <b>Please note the following:</b>
                    </AccordionSummary><AccordionDetails><List>
                        <Typography sx={{ mb: '10px' }}>Share a few words – to your loved one who decided to leave or to your friends and family about what you are going through. Talk about a tough day you had and what helped you get through it. Write about your struggles as you continue to live your life without a loved one and what helps you cope. Your story may help someone find their strength or resonate with someone else who is in a similar situation.</Typography>
                        <BPoint txt='Your can choose who should see your words - only to those who have registered on the SNEHA - SAS portal or to the general public also.' />
                        <BPoint txt='You could choose to be anonymous too. ' />
                        <BPoint txt='Please be mindful of the language that you use. Avoid using any statements which could be hurtful to others' />
                        <BPoint txt='Bringing up political, religious and other sensitive topics is not accepted.' />
                        <BPoint txt='SNEHA – SAS reserves the right to pull down submissions which do not adhere to these standards.' />
                        <BPoint txt='The submissions shall be pulled down after a certain period of time.' />
                    </List></AccordionDetails>
                </Accordion>
                <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, padding: '24px 0px' }} >
                    <Grid item sm={6}><TextField required label="Name" value={memObj?.name}
                        onChange={(e) => setmemObj({ ...memObj, name: e.target.value })}
                    /></Grid>
                    <Grid item sm={6}><TextField select required label="Visibility" value={memObj?.visibility}
                        onChange={(e) => setmemObj({ ...memObj, visibility: e.target.value })}>
                        {visibilitydata.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required type="date" label="Date of Birth" value={memObj?.birth_date} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setmemObj({ ...memObj, birth_date: e.target.value })} inputProps={{ max: today }} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required type="date" label="Date of Death" value={memObj.death_date} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setmemObj({ ...memObj, death_date: e.target.value })} inputProps={{ max: today }} />
                    </Grid>
                    <Grid item xs={6}>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="icon-button-file"
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel disabled InputLabelProps={{ shrink: true }}>Upload Image</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-image"
                                value={selectedFile && selectedFile.name ? selectedFile.name : selectedFile}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="upload picture" component="span" onClick={handleClick}>
                                            <AddPhotoAlternateIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Upload Image"
                                readOnly
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        cursor: 'default',
                                        pointerEvents: 'none',
                                        color: 'rgba(0, 0, 0, 0.38)', // Disabled text color
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(0, 0, 0, 0.23)', // Disabled border color
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Box>
                <ReactQuill
                    ref={(el) => (quill.current = el)}
                    className="ql-container ql-snow"
                    value={valueData}
                    formats={formats}
                    modules={modules}
                    onChange={(content) => {
                        setValue(content)
                    }} />

            </Card></DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={MemoAdd} disabled={disableAddBtn || isLoading} sx={pBtn}>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={editModal}>
            <Close sx={trClose} onClick={() => { seteditModal(false); setmemObj(mObj); }} />

            <DialogTitle sx={{ mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>Edit Memory For Resubmission</Typography></DialogTitle>

            <DialogContent>
                <Card sx={{ gap: '10px' }}>
                    <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, padding: '24px 0px' }} >
                        <Grid item sm={6}><TextField required label="Name" value={memObj?.name}
                            onChange={(e) => setmemObj({ ...memObj, name: e.target.value })}
                        /></Grid>
                        <Grid item sm={6}><TextField select required label="Visibility" value={memObj?.visibility}
                            onChange={(e) => setmemObj({ ...memObj, visibility: e.target.value })}>
                            {visibilitydata.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required type="date" label="Date of Birth" value={memObj?.birth_date} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setmemObj({ ...memObj, birth_date: e.target.value })} inputProps={{ max: today }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required type="date" label="Date of Death" value={memObj.death_date} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setmemObj({ ...memObj, death_date: e.target.value })} inputProps={{ max: today }} />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="icon-button-file"
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel disabled InputLabelProps={{ shrink: true }}>Upload Image</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-image"
                                    value={selectedFile && selectedFile.name ? selectedFile.name : selectedFile}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="upload picture" component="span" onClick={handleClick}>
                                                <AddPhotoAlternateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Upload Image"
                                    readOnly
                                    sx={{
                                        '& .MuiOutlinedInput-input': {
                                            cursor: 'default',
                                            pointerEvents: 'none',
                                            color: 'rgba(0, 0, 0, 0.38)', // Disabled text color
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(0, 0, 0, 0.23)', // Disabled border color
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Box>
                    <ReactQuill
                        ref={(el) => (quill.current = el)}
                        className="ql-container ql-snow"
                        value={valueData}
                        formats={formats}
                        modules={modules}
                        onChange={(content) => {
                            setValue(content)
                        }} />

                </Card>
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => MemoEdit(memObj?._id)} disabled={disableEditBtn || isLoading} sx={pBtn}>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={memModal}>
            <Close sx={trClose} onClick={() => { setmemModal(false); setmemObj(mObj); }} />

            <DialogTitle sx={{ mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6'>{memObj?.name}</Typography></DialogTitle>

            <DialogContent><Card sx={{ gap: '10px' }}>
                {decodedHTML ? <Typography className="ql-editor" dangerouslySetInnerHTML={{ __html: decodedHTML }}></Typography> : <Typography className="ql-editor" >No message for {memObj?.name}</Typography>}
            </Card></DialogContent>
        </Dialog>

        {memObj && <Dialog open={imageView} sx={{ border: '2px solid black', borderRadius: '10px' }} >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{memObj.name} - Uploaded Image</Typography>
                <Close sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setImageView(false)} />
            </DialogTitle>

            <DialogContent><Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={`data:image/png;base64,${memObj.image}`} alt="Preview" style={{ width: '100%', height: '100%' }} />
            </Box></DialogContent>
        </Dialog>}
    </>)
}