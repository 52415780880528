import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography, Box, Link, TextField, Snackbar, Alert, useMediaQuery, Modal } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { mainTheme, StyledAlert } from './TabsCore';
import { phqAnsPost, phqAnsList, phqQueGet, user } from '../services/UserService';
import { regList } from '../services/RegistrationService';
import { resetPassword } from '../services/AuthService';
import { formatDate, PerChk, pBtn, nBtn } from '../common/Common';

export default function MyAc() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const userId = sessionStorage.getItem('userId');
    const userRole = sessionStorage.getItem('uRole');
    const [queGet, setqueGet] = useState([]);
    const [ansArr, setansArr] = useState([]);
    const [phqDone, setphqDone] = useState(false);
    const [alertShow, setalertShow] = useState(null);
    const [usr, setusr] = useState({});
    const [ftrA, setftrA] = useState(null);
    const [scrObj, setscrObj] = useState({ 1: 0, 2: 0, 3: 0, total: 0 });
    const [resetfunc, setResetfunc] = useState(false);
    const [clickhereEnable, setClickhereEnable] = useState(false);
    const [resetObj, setResetObj] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [resetObjerror, setResetObjError] = useState({
        oldPassworderror: '',
        newPassworderror: '',
        confirmPassworderror: '',
        resetbuttonerror: '',
    });
    const disableResetSubmitButton = !resetObj.oldPassword || !resetObj.newPassword || !resetObj.confirmPassword || resetObjerror.oldPassworderror || resetObjerror.newPassworderror || resetObjerror.confirmPassworderror;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarError, setOpenSnackbarError] = useState(false);
    const linkStyle = {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: !clickhereEnable ? '#b5651d' : '#d3d3d3',
        opacity: !clickhereEnable ? 1 : 0.6,
        pointerEvents: !clickhereEnable ? 'auto' : 'none',
    };

    const validatePassword = (passworddata) => {
        const minLength = 8; // Minimum password length
        const maxLength = 15; // Maximum password length

        if (passworddata.length < minLength) {
            return `Password must be at least ${minLength} characters long`;
        } else if (passworddata.length > maxLength) {
            return `Password must be less than ${maxLength + 1} characters long`;
        } else {
            return ''; // Password is valid
        }
    };
    const validatePasswordsMatch = (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            return "Passwords don't match";
        } else {
            return ''; // Passwords match
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const resp1 = await phqQueGet();
            const resp2 = await phqAnsList();
            const resp3 = await user(userId);
            const resp4 = await regList();
            if (resp1) { setqueGet(resp1.result) }
            if (resp2) {
                const resp2Tmp = resp2.result.find(item => item.user_id === userId);
                if (resp2Tmp) {
                    setansArr(resp2Tmp.response); setftrA(resp2Tmp.difficultyScore);
                    resp2Tmp.response.length > 0 ? setphqDone(true) : setphqDone(false);
                }
            }
            if (resp3) {
                let userData = { ...resp3.result };
                if (resp4) {
                    const resp4Tmp = resp4.result.find(item => item.user_id === userId);
                    if (resp4Tmp) {
                        userData = { ...userData, ...resp4Tmp };
                    }
                }
                setusr(userData);
            }
        }; fetchData()
    }, [])

    useEffect(() => {
        let scrUpdate = { 1: 0, 2: 0, 3: 0, total: 0 };
        scrUpdate.total = ansArr.reduce((total, obj) => total + obj.score, 0);
        ansArr.forEach(ans => { if (scrUpdate.hasOwnProperty(ans.score)) { scrUpdate[ans.score]++ } });
        setscrObj(scrUpdate);
    }, [ansArr])

    const Detail = ({ det, detVal }) => (<>
        <Grid item xs={isMobile ? 4 : 2} component={Typography}><b>{det}</b></Grid>
        <Grid item xs={isMobile ? 2 : 1} component={Typography}><b>:</b></Grid>
        <Grid item xs={isMobile ? 6 : 3} component={Typography}>{detVal}</Grid>
    </>)

    const Ques = ({ idx, que, id }) => (
        <FormControl disabled={phqDone}><RadioGroup value={ansArr.find(a => a.question_id === id)?.score} onChange={(e) => {
            const newScore = Number(e.target.value);
            setansArr(prevAs => {
                const existingEntry = prevAs.find(a => a.question_id === id);
                if (existingEntry) { existingEntry.score = newScore; return [...prevAs]; }
                else { return [...prevAs, { question_id: id, score: newScore }] }
            })
        }}><Grid container justify='center' spacing={isMobile ? 0 : 2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: isMobile ? 2 : 0 }}>
                <Grid item xs={isMobile ? 12 : 4}><FormLabel sx={{ color: '#000' }}>{idx + 1}. {que}</FormLabel></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={0} label={0} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={1} label={1} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={2} label={2} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={3} label={3} /></Grid>
            </Grid></RadioGroup></FormControl>
    )

    const SubmitForm = async () => {
        try {
            const phqObj = { user_id: userId, response: ansArr, difficultyScore: ftrA }
            const resp = await phqAnsPost(phqObj);
            if (resp) {
                const resp2 = await phqAnsList();
                if (resp2) {
                    const resp2Tmp = resp2.result.find(item => item.user_id === userId);
                    if (resp2Tmp) {
                        setalertShow('success');
                        setansArr(resp2Tmp.response);
                        setftrA(resp2Tmp.difficultyScore);
                        setphqDone(true);
                        setTimeout(() => { setalertShow(null) }, 3000);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            setalertShow('error');
            setTimeout(() => { setalertShow(null) }, 3000);
        }
    }

    const handleResetPw = async () => {
        if ((resetObj.oldPassword.trim() && (resetObj.newPassword.trim()) && (resetObj.confirmPassword.trim()))) {
            try {
                let data = {
                    oldpassword: resetObj.oldPassword,
                    newPassword: resetObj.newPassword
                }
                let resp = await resetPassword(userId, data);
                setClickhereEnable(false)
                setOpenSnackbar(true);
                setResetfunc(false);
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    console.log("Email verification failed with status code 500:", error.response.data.message);
                    setResetObjError(prevState => ({
                        ...prevState,
                        resetbuttonerror: error.response.data.message
                    }));
                } else {
                    console.log("An unexpected error occurred:", error.message);
                    setResetObjError(prevState => ({
                        ...prevState,
                        resetbuttonerror: error.message
                    }));
                }
                console.log("An unexpected error occurred:", error);
                setClickhereEnable(false);
                setOpenSnackbarError(true);
                setResetfunc(false);
            }
        }
    }

    return (<ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {alertShow && <StyledAlert severity={alertShow}>{alertShow === 'success' ?
            'Your response has been collected' : 'Form submission failed'}</StyledAlert>}

        <Card>
            <Typography variant='h6'>My Details</Typography>

            <Grid container spacing={2}>
                <Detail det='Name ' detVal={usr.full_name} />
                <Detail det='Gender ' detVal={usr.gender} />
                <Detail det='Date of birth ' detVal={formatDate(usr.date_of_birth)} />
                <Detail det='Postal address ' detVal={usr.address ? usr.address : "-"} />
                <Detail det='Phone number ' detVal={usr.mobile_number} />
                <Detail det='Email ID ' detVal={usr.email_id} />
                {userRole === 'SAS_MEMBER' && <>
                    <Detail det='Whom did you lose to suicide? ' detVal={usr && usr.relationship === "Other" ? `${usr.other_relationship}` : usr.relationship} />
                    <Detail det='Date you lost him/her ' detVal={usr.date_you_lost_them ? formatDate(usr.date_you_lost_them) : "-"} />
                    <Detail det='Who supported you at that point of time? ' detVal={usr.supported_person} />
                    <Detail det='Did you take any professional help? ' detVal={usr.any_proffessional_help ? 'Yes' : 'No'} />
                    <Detail det='How did you hear about SNEHA-SAS? ' detVal={usr.how_you_know_SAS ? usr.how_you_know_SAS : "-"} />
                    <Detail det='Session preference' detVal={usr.support_via} />
                </>}

                <Typography variant='body1' sx={{ margin: '10px' }}>Do you want to reset your password?<Link style={linkStyle} onMouseEnter={(e) => { e.target.style.textDecoration = 'underline'; e.target.style.color = 'black'; }} onMouseLeave={(e) => { e.target.style.textDecoration = 'none'; e.target.style.color = '#b5651d'; }} onClick={() => { setClickhereEnable(true); setResetfunc(true); setResetObj(''); setResetObjError('') }}> Click here</Link></Typography>
                {resetfunc ?
                    <Box component={Grid} container spacing={2} style={{ marginLeft: "unset" }}>

                        <Grid item sm={4}>
                            <TextField required label="Old Password" type="password" value={resetObj.oldPassword} onChange={(e) => {
                                const old_Password = e.target.value;
                                setResetObj({
                                    ...resetObj,
                                    oldPassword: old_Password
                                });
                                // setResetObjError({
                                //     ...resetObjerror,
                                //     oldPassworderror: validatePassword(old_Password)
                                // });
                            }}
                                error={!!resetObjerror.oldPassworderror} helperText={resetObjerror.oldPassworderror} />
                        </Grid>

                        <Grid item sm={4}>
                            <TextField required label="New Password" type="password" value={resetObj.newPassword} onChange={(e) => {
                                const newPassword = e.target.value;
                                setResetObj({
                                    ...resetObj,
                                    newPassword: newPassword
                                });
                                setResetObjError({
                                    ...resetObjerror,
                                    newPassworderror: validatePassword(newPassword)
                                });
                            }}
                                error={!!resetObjerror.newPassworderror} helperText={resetObjerror.newPassworderror} />
                        </Grid>

                        <Grid item sm={4}>
                            <TextField required label="Confirm Password" type="password" value={resetObj.confirmPassword} onChange={(e) => {
                                const confirmPassword = e.target.value;
                                setResetObj({
                                    ...resetObj,
                                    confirmPassword: confirmPassword
                                });
                                setResetObjError({
                                    ...resetObjerror,
                                    confirmPassworderror: validatePasswordsMatch(resetObj.newPassword, confirmPassword)
                                });
                            }}
                                error={!!resetObjerror.confirmPassworderror} helperText={resetObjerror.confirmPassworderror} />
                        </Grid>



                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button sx={{ marginRight: 1, ...nBtn }} onClick={() => { setResetfunc(false); setClickhereEnable(false) }}>Cancel</Button>
                            <Button sx={pBtn} disabled={disableResetSubmitButton} onClick={handleResetPw}>Submit</Button>
                        </Grid>

                    </Box> : null
                }
            </Grid>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Your password has been updated
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbarError} autoHideDuration={6000} onClose={() => setOpenSnackbarError(false)} >
                <Alert
                    onClose={() => setOpenSnackbarError(false)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {resetObjerror.resetbuttonerror}
                </Alert>
            </Snackbar>
        </Card>

        <Card hidden={userRole !== 'SAS_MEMBER' || PerChk('PHQ9')}>
            <Typography variant='h6'>My PHQ9 {!phqDone && <span style={{ color: 'red', marginLeft: 10, fontSize: '60%' }}>Please fill this form</span>}</Typography>

            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Grid item xs={4}><Typography fontWeight='bold'>Over the last 2 weeks, how often have you been bothered by any of the following problems?</Typography></Grid>
                <Grid item xs={2}><Typography fontWeight='bold'>Not at all</Typography></Grid>
                <Grid item xs={2}><Typography fontWeight='bold'>Several days</Typography></Grid>
                <Grid item xs={2}><Typography fontWeight='bold'>More than half the days</Typography></Grid>
                <Grid item xs={2}><Typography fontWeight='bold'>Nearly every day</Typography></Grid>
                <Grid item xs={12}><Divider sx={{ border: '1px solid #b5651d' }} /></Grid>
            </Grid>

            {queGet.map((item, index) => (<Ques idx={index} que={item.question} id={item._id} />))}

            <Divider sx={{ border: '1px solid #b5651d' }} />

            <Grid container sx={{ my: 2 }}>{isMobile ?
                <Grid item xs={12}><Typography><b>Total score = {scrObj.total}</b></Typography></Grid> :
                <>
                    <Grid item xs={3}><Typography><b>Total score: {scrObj.total}</b></Typography></Grid>
                    <Grid item xs={1} sx={{ ml: 1 }}><Typography><b>=</b></Typography></Grid>
                    <Grid item xs={1}><Typography>0</Typography></Grid>
                    <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                    <Grid item xs={1}><Typography>{scrObj[1] * 1}</Typography></Grid>
                    <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                    <Grid item xs={1}><Typography>{scrObj[2] * 2}</Typography></Grid>
                    <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                    <Grid item xs={1}><Typography>{scrObj[3] * 3}</Typography></Grid>
                </>
            }</Grid>

            <Grid container sx={{ my: 2 }}>
                <Grid item xs={isMobile ? 12 : 10}><FormControl disabled={phqDone}>
                    <FormLabel><Typography sx={{ color: '#000' }}>
                        If you checked off any problems, how difficult have these problems made it for you to do your
                        work, take care of things at home, or get along with other people?
                    </Typography></FormLabel>
                    <RadioGroup value={ftrA} onChange={(e) => setftrA(e.target.value)}><Grid container spacing={2}
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="0" control={<Radio />} label="Not difficult at all" /></Grid>
                        <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="1" control={<Radio />} label="Somewhat difficult" /></Grid>
                        <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="2" control={<Radio />} label="Very difficult" /></Grid>
                        <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="3" control={<Radio />} label="Extremely difficult" /></Grid>
                    </Grid></RadioGroup>
                </FormControl></Grid>

                {!phqDone && <Grid item xs={isMobile ? 12 : 2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'right' }}>
                    <Button sx={pBtn} size='small' onClick={SubmitForm} disabled={ansArr.length < queGet.length || ftrA === null}>Save</Button></Grid>}
            </Grid>

            <footer className='text-center'><Typography sx={{ color: 'GrayText' }}>
                Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from
                Pfizer Inc. No permission required to reproduce, translate, display or distribute.
            </Typography></footer>
        </Card>
    </Box></ThemeProvider>)
}