import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, createTheme } from '@mui/material/styles';
import {
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination,
    TableRow, Paper, Card, FormControl, Snackbar, Alert, Dialog, DialogTitle, DialogContent, Button, Grid, useMediaQuery, TextField, DialogActions, Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { setTimeoutsecs, pBtn, PerChk, setAutoHideDurationTimeoutsecs, TPA } from '../../../common/Common';
import { HomeDetailslist, EditHome } from '../../../services/HomeService';
import DeleteIcon from '@mui/icons-material/Delete';

export const mainTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiTextField: { styleOverrides: { root: { marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
        MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
        MuiInputBase: { styleOverrides: { root: { backgroundColor: '#ffffff' } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
        MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } }
    }
})

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function RoletypeMgmt({ setRefresh }) {
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [hometypeList, setHometypeList] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [tableDialog, setTableDialog] = useState(false);
    const [edittableDialog, setEditTableDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [message, setMessage] = useState(false);
    const [severityColor, setSeverityColor] = useState('');
    const [fields, setFields] = useState([{ order: '', title: '', subTitle: '', description: '', subOrder: '' }]);
    const [fieldsError, setFieldsError] = useState([{ order: '', title: '', subTitle: '', description: '', subOrder: '' }]);

    const disableUpdateButton = fields.some(field =>
        !field.description || fieldsError.some(err => err.subOrder || err.subTitle)
    );
    const validateNumeric = (value) => {
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
            return 'Only numeric values are allowed';
        }
        return '';
    };

    const addFields = () => {
        setFields([...fields, { order: '', title: '', subTitle: '', description: '', subOrder: '' }]);
        setFieldsError([...fieldsError, { order: '', title: '', subTitle: '', description: '', subOrder: '' }]);
    };

    const deleteFields = (index) => {
        setFields(fields.filter((_, i) => i !== index));
        setFieldsError(fieldsError.filter((_, i) => i !== index));
    };

    const handleFieldChange = (index, field, value) => {
        const newFieldsError = fieldsError.map(error => ({
            order: '',
            title: '',
            subTitle: '',
            description: '',
            subOrder: '',
        }));
        setFieldsError(newFieldsError);

        const newFields = [...fields];
        newFields[index] = { ...newFields[index], [field]: value };
        setFields(newFields);

        // const newFieldsError = [...fieldsError];
        // newFieldsError[index] = { ...newFieldsError[index], [field]: '' };
        // setFieldsError(newFieldsError);

        if (field === 'subOrder') {
            const numericError = validateNumeric(value);
            const updatedFieldsError = [...newFieldsError];
            updatedFieldsError[index] = { ...updatedFieldsError[index], [field]: numericError };
            setFieldsError(updatedFieldsError);

            // Check for duplicate subOrder values
            const isDuplicate = newFields.some((f, idx) => idx !== index && f.subOrder.toString() === value);
            if (isDuplicate) {
                const duplicateError = 'Duplicate subOrder value';
                updatedFieldsError[index] = { ...updatedFieldsError[index], [field]: duplicateError };
                setFieldsError(updatedFieldsError);
                // Optionally, prevent further processing until duplicate is resolved
                return;
            }
        }
    };



    const handleUpdate = async () => {
        let hasErrors = false;

        const newFieldsError = fields.map(field => {
            let error = { subTitle: '', subOrder: '' };
            if (field.subOrder && !field.subTitle) {
                error.subTitle = 'Subtitle is required';
                hasErrors = true;
            }
            if (!field.subOrder && field.subTitle) {
                error.subOrder = 'Suborder is required';
                hasErrors = true;
            }
            return error;
        });

        setFieldsError(newFieldsError);

        if (hasErrors) {
            return;
        }
        setLoading(true);
        try {
            let data = {
                order: fields[0].order,
                content: fields.map(field => ({
                    sub_order: field.subOrder ? field.subOrder : "",
                    sub_title: field.subTitle ? field.subTitle : "",
                    description: field.description
                }))
            }
            let resp = await EditHome(selectedRow._id, data);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setSeverityColor("success")
                    setMessage("HomeDetails updated successfully")
                    setOpenSnackbar(true);
                    setEditTableDialog(false)
                    fetchHomeTypes();
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setMessage(error.response.data.message)
            } else {
                setMessage(error.response.data.message)
                console.log("An unexpected error occurred:", error.message);
            }
            console.log("An unexpected error occurred:", error);
            setSeverityColor("error")
            setOpenSnackbar(true);
            setTimeout(() => {
                setLoading(false);
            }, setTimeoutsecs);
        }
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleRowClick = async (row) => {
        setSelectedRow(row);
        setTableDialog(true);
    };

    const handleEditRowClick = async (row) => {
        setFieldsError(row.content.map(() => ({ order: '', title: '', subTitle: '', description: '', subOrder: '' })));
        setFields(row.content.map(item => ({
            title: row.title,
            order: row.order,
            subTitle: item.sub_title,
            subOrder: item.sub_order,
            description: item.description
        })));
        setEditTableDialog(true);
        setSelectedRow(row);
    };

    const fetchHomeTypes = async () => {
        try {
            let resp = await HomeDetailslist();
            setHometypeList(resp.result);
        } catch (error) {

            console.log(error)
        }
    };

    useEffect(() => {
        fetchHomeTypes();
    }, []);

    const filteredData = hometypeList.filter(user => {
        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });

        return matchesSearchQuery;
    });
    const nonMobileStyles = {
        flexGrow: 1,
        p: 3,
    };

    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}><Container component="main" >
        <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                <FormControl sx={{ width: '130px' }}>
                    <TextField
                        label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
            </Box >
        </Box>

        {filteredData.length > 0 ? <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}><Table>
                <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                    <TableRow sx={{ alignItems: 'center' }}>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Order</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Content</TableCell>
                        <TableCell sx={{ color: 'white', fontWeight: 'bold', width: "2%" }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredData
                    ).map((row) => (
                        <TableRow key={row._id} onClick={() => handleRowClick(row)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#fcf2e7',
                                },
                            }}>
                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                {row.order}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                {row.content[0].description}
                            </TableCell>
                            <TableCell><Tooltip title="Edit Homecontent" hidden={PerChk('EDIT_HOMECONTENT')}><EditIcon sx={{ color: '#b5651d' }} title="Edit"
                                onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }} /></Tooltip></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={filteredData.length}
                            page={page} rowsPerPage={rowsPerPage}
                            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                            onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                            ActionsComponent={TPA}
                        />
                    </TableRow>
                </TableFooter>
            </Table></TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}


        {selectedRow && <Dialog open={tableDialog} fullWidth maxWidth='lg' sx={{ '& .MuiDialog-paper': { height: '100%', maxHeight: '100%', margin: 0 } }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Grid container spacing={2}>
                    <Grid item sm={12} style={{ display: "flex", justifyContent: "end" }}><CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} /></Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>{selectedRow && (
                <Card>
                    <Typography variant='h6' sx={{ mb: 1 }}>{selectedRow.title}</Typography>
                    {selectedRow.content?.map((c, i) => <Typography key={i}>
                        {c.sub_title && <b>{c.sub_title}</b>}
                        <span style={{ display: 'block', whiteSpace: 'pre-line', marginBottom: '8px' }}>{c.description}</span>
                    </Typography>)}
                </Card>
            )}</DialogContent>
        </Dialog>}

        <Dialog open={edittableDialog} fullWidth maxWidth='lg' sx={{ '& .MuiDialog-paper': { height: '100%', maxHeight: '100%', margin: 0 } }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow !== null ? selectedRow.title : 'Edit'}</Typography>
                <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setEditTableDialog(false)} />
            </DialogTitle>

            <DialogContent>
                {selectedRow && <Card>
                    {fields.map((fieldPair, index) => (
                        <React.Fragment key={index}>
                            {index == 0 && (
                                <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                                        <FormControl sx={{ width: '60px' }}>
                                            <TextField
                                                disabled
                                                label="Order"
                                                value={fieldPair.order}
                                                onChange={(e) => handleFieldChange(index, 'Order', e.target.value)}
                                                size="small"
                                            />
                                        </FormControl>
                                        <Tooltip title="Add sub section">
                                            <Button color="primary" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={addFields}>
                                                Add sub section
                                            </Button>
                                        </Tooltip>
                                    </Box >
                                </Box>
                            )}
                            <Grid container direction={isMobile ? 'column' : 'row'} spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                                <Grid item xs={2}>
                                    <TextField
                                        label="Sub section order"
                                        value={fieldPair.subOrder}
                                        onChange={(e) => handleFieldChange(index, 'subOrder', e.target.value)}
                                        fullWidth
                                        error={Boolean(fieldsError[index]?.subOrder)}
                                        helperText={fieldsError[index]?.subOrder || ''} />
                                </Grid>
                                <Grid item xs={index > 0 ? 9.5 : 10}>
                                    <TextField
                                        label="Sub section title"
                                        value={fieldPair.subTitle}
                                        onChange={(e) =>
                                            handleFieldChange(index, 'subTitle', e.target.value)}
                                        fullWidth
                                        error={Boolean(fieldsError[index]?.subTitle)}
                                        helperText={fieldsError[index]?.subTitle} />
                                </Grid>
                                {index > 0 && (
                                    <Grid item xs={0.5}>
                                        <IconButton color="error" onClick={() => deleteFields(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        required multiline
                                        rows={4}
                                        label="Description"
                                        value={fieldPair.description}
                                        onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Card>}
            </DialogContent>
            <DialogActions>
                <Button type="submit" disabled={disableUpdateButton || loading}
                    variant="contained"
                    sx={pBtn} onClick={handleUpdate}>Save{loading ? <CircularProgress /> : null}</Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={setAutoHideDurationTimeoutsecs} onClose={() => setOpenSnackbar(false)}>
            <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={severityColor}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    </Container></Box >)
}