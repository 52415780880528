import axios from "axios";

export async function AddHome(data) {
    const configuration = {
        url: process.env.SERVER + 'home',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditHome(homeId, data) {
    console.log('homeapi', homeId, data);
    const configuration = {
        url: process.env.SERVER + `home/${homeId}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function HomeDetailslist() {

    const configuration = {
        url: process.env.SERVER + 'home/list',
        method: 'post',
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}