import React, { useState, useEffect } from 'react';
import { Toolbar, Typography, Tab, Tabs, useMediaQuery, Box } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import MemorialList from './MemorialList';
import MemorialApprovalList from './MemorialApprovalList';
import { mainTheme, tabThemeAdmin } from '../../TabsCore';
import { PerChk } from '../../../common/Common';


export default function MemorialManagementTab() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tabIndex, settabIndex] = useState('0')
    const isMemListHidden = PerChk('MEMORIAL_LIST');
    const isApprovalListHidden = PerChk('MEMORIAL_APPROVALLIST');

    useEffect(() => {
        if (isMemListHidden && !isApprovalListHidden) {
            settabIndex('1');
        } else if (isMemListHidden && isApprovalListHidden) {
            settabIndex(null);
        }
    }, [isMemListHidden, isApprovalListHidden]);

    if (isMemListHidden && isApprovalListHidden) {
        return null;
    }


    return (<ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', flexDirection: 'column' }}>

        <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right', bgcolor: '#1px solid grey', p: 2, height: '20px', top: 0, zIndex: 99 }}>
            <ThemeProvider theme={tabThemeAdmin}>
                <Box sx={{ width: '100%' }}>
                    <Tabs value={tabIndex} onChange={(e, ti) => settabIndex(ti)}>
                        {!isMemListHidden && <Tab value="0" label="Memorial List" />}
                        {!isApprovalListHidden && <Tab value="1" label="Approval" />}
                    </Tabs>
                </Box>
            </ThemeProvider>
        </Toolbar>

        <Box>
            {!isMemListHidden && tabIndex === '0' && <MemorialList />}
            {!isApprovalListHidden && tabIndex === '1' && <MemorialApprovalList />}
        </Box>
    </Box ></ThemeProvider >)
}	