import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import {
    Box, Container, Card, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, MenuItem,
    FormControl, Snackbar, Alert, Stepper, Step, StepLabel, FormControlLabel, RadioGroup, FormLabel, Radio,
    Select, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Toolbar, Tab, Tabs, useMediaQuery, TextField, Link, Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { userList, phqAnsList, phqQueGet, phqAnsPost } from '../../../services/UserService';
import { regList } from '../../../services/RegistrationService';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Edituser, Disableuser, Enableuser, AddAdmin, AddMem, EditMem } from '../../../services/UserService';
import { EditRegistration } from '../../../services/RegistrationService';
import { resetPassword, resetPasswordByAdmin } from '../../../services/AuthService';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDateStr, formatDate, setTimeoutsecs, pBtn, nBtn, TPA, PerChk } from '../../../common/Common';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import html2pdf from 'html2pdf.js';
import { mainTheme, tabThemeAdmin } from '../../TabsCore';

export const pdfTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { borderRadius: '15px', padding: '15px' } } },
        MuiContainer: { styleOverrides: { root: { borderRadius: '15px', padding: '15px', marginTop: "20px" } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
    }
})
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function CustomPaginationActionsTable() {
    const userRole = sessionStorage.getItem('uRole') || null;
    const [loading, setLoading] = React.useState(false);
    const [resloading, setResLoading] = React.useState(false);
    const [disableloading, setDisableLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [userData, setUserData] = React.useState([]);
    const [phqList, setPhqList] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [tableDialog, setTableDialog] = useState(false);
    const [edittableDialog, setEditTableDialog] = useState(false);
    const [disabletableDialog, setDisableTableDialog] = useState(false);
    const [enabletableDialog, setEnableTableDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [registrationData, setRegistrationData] = useState('');
    const [userregistrationData, setUserRegistrationData] = useState('');
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tabIndex, settabIndex] = useState('0');
    const [questionList, setQuestionList] = useState([]);
    const [phqAnsData, setPhqAnsData] = useState([]);
    const userId = sessionStorage.getItem('userId');
    const [memuserId, setMemUserId] = useState('');
    const anscode = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];
    const difficultanscode = ['Not difficult at all', 'Somewhat difficult', 'Very difficult', 'Extremely difficult'];
    const today = new Date().toISOString().split('T')[0];
    const gen = ['Male', 'Female', 'Transgender'];
    const meetingData = ['Online', 'Face-to-Face'];
    const rel = ['Husband', 'Wife', 'Brother', 'Sister', 'Father', 'Mother', 'Grandfather', 'Grandmother', 'Friend', 'Colleague', 'Other'];
    const [PDFView, setPDFView] = useState(false);
    const [disableDownload, setDisableDownload] = useState(false);
    const [formObj, setFormObj] = useState({
        userId: '',
        regId: '',
        name: '',
        sex: '',
        dob: '',
        relation: '',
        otherRelation: '',
        lossDate: '',
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: '',
        notes: ''
    })
    const [addAdmin, setAddAdmin] = useState({
        username: '',
        dob: '',
        phnNum: '',
        emailID: '',
        adrs: '',
        sex: '',
        pw: '',
        status: '',
        role: ''
    });
    const [addAdminError, setAddAdminError] = useState({
        username: '',
        dob: '',
        phnNum: '',
        emailID: '',
        adrs: '',
        sex: '',
        pw: '',
        status: '',
        role: ''
    });

    const [formObjerror, setFormObjError] = useState({
        name: '',
        sex: '',
        dob: new Date(),
        relation: '',
        otherRelation: '',
        lossDate: new Date(),
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: ''
    });
    const disableRegisterbutton = !formObj.emailID || !formObj.name || !formObj.sex || !formObj.dob || !formObj.relation ||
        !formObj.support || !formObj.phnNum || !formObj.meeting || formObjerror.phnNum;
    const disableuserRegisterbutton = !formObj.emailID || !formObj.name || !formObj.sex || !formObj.dob || !formObj.phnNum;
    const disableaddAdminbutton = !addAdmin.emailID || !addAdmin.username || !addAdmin.sex || !addAdmin.pw || !addAdmin.dob ||
        !addAdmin.phnNum || addAdminError.phnNum || addAdminError.emailID || addAdminError.pw;
    const [resetfunc, setResetfunc] = useState(false);
    const [clickhereEnable, setClickhereEnable] = useState(false);
    const [resetObj, setResetObj] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [resetObjerror, setResetObjError] = useState({
        oldPassworderror: '',
        newPassworderror: '',
        confirmPassworderror: '',
        resetbuttonerror: '',
    });
    const disableResetSubmitButton = !resetObj.newPassword || !resetObj.confirmPassword || resetObjerror.newPassworderror || resetObjerror.confirmPassworderror;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarError, setOpenSnackbarError] = useState(false);
    const linkStyle = {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: !clickhereEnable ? '#b5651d' : '#d3d3d3',
        opacity: !clickhereEnable ? 1 : 0.6,
        pointerEvents: !clickhereEnable ? 'auto' : 'none',
    };
    const [userUpdated, setUserUpdated] = useState(false);
    const [userUpdateFailed, setUserUpdateFailed] = useState('');
    const [phq9userUpdated, setPhq9UserUpdated] = useState(false);
    const [phq9userUpdateFailed, setPhq9UserUpdateFailed] = useState('');
    const [userDisable, setDisableUser] = useState(false);
    const [userDisableFailed, setUserDisableFailed] = useState('');
    const [addAdminAlert, setAddAdminAlert] = useState(false);
    const [addAdminAlertFailed, setAddAdminAlertFailed] = useState('');
    const [userEnable, setEnableUser] = useState(false);
    const [userEnableFailed, setUserEnableFailed] = useState('');
    const [addAdmindialog, setAddAdminDialog] = useState(false);
    const [addMemdialog, setAddMemDialog] = useState(false);
    const [addMemAlertFailed, setAddMemAlertFailed] = useState('');
    const [addMemAlert, setAddMemAlert] = useState(false);
    const [addMemphqAlertFailed, setAddMemPhqAlertFailed] = useState('');
    const [addMemphqAlert, setAddMemPhqAlert] = useState(false);
    const [addMem, setAddMem] = useState({
        userId: '',
        regId: '',
        name: '',
        sex: '',
        dob: '',
        relation: '',
        otherRelation: '',
        lossDate: '',
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: ''
    });
    const [addMemError, setAddMemError] = useState({
        name: '',
        sex: '',
        dob: new Date(),
        relation: '',
        otherRelation: '',
        lossDate: new Date(),
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: ''
    });
    const disableaddMembutton = !addMem.name || !addMem.sex || !addMem.dob || !addMem.relation || !addMem.support || !addMem.meeting || addMemError.emailID || addMemError.phnNum || (addMem.relation === "Other" && (!addMem.otherRelation || addMem.otherRelation.trim() === ""))

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Enter Member details', 'Enter PHQ9 response details'];
    const [queGet, setqueGet] = useState([]);
    const [ansArr, setansArr] = useState([]);
    const [phqDone, setphqDone] = useState(false);
    const [ftrA, setftrA] = useState(null);
    const [scrObj, setscrObj] = useState({ 1: 0, 2: 0, 3: 0, total: 0 });

    const Ques = ({ idx, que, id }) => (
        <FormControl disabled={phqDone}><RadioGroup value={ansArr.find(a => a.question_id === id)?.score} onChange={(e) => {
            const newScore = Number(e.target.value);
            setansArr(prevAs => {
                const existingEntry = prevAs.find(a => a.question_id === id);
                if (existingEntry) { existingEntry.score = newScore; return [...prevAs]; }
                else { return [...prevAs, { question_id: id, score: newScore }] }
            })
        }}><Grid container justify='center' spacing={isMobile ? 0 : 2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: isMobile ? 2 : 0 }}>
                <Grid item xs={isMobile ? 12 : 4}><FormLabel sx={{ color: '#000' }}>{idx + 1}. {que}</FormLabel></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={0} label={0} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={1} label={1} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={2} label={2} /></Grid>
                <Grid item xs={isMobile ? 3 : 2}><FormControlLabel control={<Radio />} value={3} label={3} /></Grid>
            </Grid></RadioGroup></FormControl>
    )

    const validatePassword = (passworddata) => {
        const minLength = 8; // Minimum password length
        const maxLength = 15; // Maximum password length

        if (passworddata.length < minLength) {
            return `Password must be at least ${minLength} characters long`;
        } else if (passworddata.length > maxLength) {
            return `Password must be less than ${maxLength + 1} characters long`;
        } else {
            return ''; // Password is valid
        }
    };
    const validatePasswordsMatch = (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            return "Passwords don't match";
        } else {
            return ''; // Passwords match
        }
    };

    const validateEmail = (email_id) => {
        const re = /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+.[a-zA-Z]{2,4}$/;
        return re.test(email_id);
    };

    const validatePhoneNumber = (phone_number) => {
        const re = /^\+?(\d{1,4})?[\s\-]?\(?\d{1,4}\)?[\s\-]?\d{3,7}([\s\-]?\d{3,7})?$/
;
        return re.test(phone_number);
    };

    const handleAddMem = async () => {
        setLoading(true);
        try {
            let data = {
                full_name: addMem.name.trim(),
                gender: addMem.sex,
                date_of_birth: new Date(addMem.dob),
                relationship: addMem.relation,
                date_you_lost_them: new Date(addMem.lossDate),
                supported_person: addMem.support.trim(),
                any_proffessional_help: addMem.profHelp,
                mobile_number: addMem.phnNum ? addMem.phnNum.trim() : "",
                email_id: addMem.emailID ? addMem.emailID.trim() : "",
                address: addMem.adrs ? addMem.adrs.trim() : "",
                how_you_know_SAS: addMem.ref ? addMem.ref.trim() : "",
                support_via: addMem.meeting,
                other_relationship: addMem.otherRelation ? addMem.otherRelation.trim() : "",
                is_Offline_member: true
            }
            let resp = await AddMem(data);
            setMemUserId(resp.userDetails._id);
            setAddMemAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setActiveStep(1);
                    setAddMemAlert(false);
                    fetchFileData();
                    fetchData();
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setAddMemAlertFailed(error.response.data.message);
                setAddMemAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setAddMemAlertFailed(error.message);
                setAddMemAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setAddMemAlert(false);
                fetchFileData();
                fetchData();
            }, setTimeoutsecs);
        }
    };

    const handleAddAdmin = async () => {
        setLoading(true);
        try {
            let data = {
                full_name: addAdmin.username.trim(),
                date_of_birth: new Date(addAdmin.dob),
                mobile_number: addAdmin.phnNum.trim(),
                email_id: addAdmin.emailID.trim(),
                address: addAdmin.adrs.trim(),
                gender: addAdmin.sex,
                password: addAdmin.pw.trim(),
                status: "Active",
                roletype_key: "SAS_ADMIN"
            }
            let resp = await AddAdmin(data);
            setAddAdminAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setAddAdminAlert(false);
                    fetchFileData();
                    setAddAdminDialog(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setAddAdminAlertFailed(error.response.data.message);
                setAddAdminAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setAddAdminAlertFailed(error.message);
                setAddAdminAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setAddAdminAlert(false);
                fetchFileData();
                // setAddAdminDialog(false);
            }, setTimeoutsecs);
        }
    };

    const handleResetPw = async () => {
        setResLoading(true);
        if (((resetObj.newPassword.trim()) && (resetObj.confirmPassword.trim()))) {
            try {
                let data = {
                    adminId: userId,
                    newPassword: resetObj.newPassword.trim()
                }
                let resp = await resetPasswordByAdmin(formObj.userId, data);
                if (resp) {
                    setTimeout(() => {
                        setResLoading(false);
                        setClickhereEnable(false)
                        setOpenSnackbar(true);
                        setResetfunc(false);
                    }, setTimeoutsecs);
                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    setResetObjError(prevState => ({
                        ...prevState,
                        resetbuttonerror: error.response.data.message
                    }));
                } else {
                    console.log("An unexpected error occurred:", error.message);
                    setResetObjError(prevState => ({
                        ...prevState,
                        resetbuttonerror: error.message
                    }));
                }
                console.log("An unexpected error occurred:", error);
                setTimeout(() => {
                    setResLoading(false);
                    setClickhereEnable(false);
                    setOpenSnackbarError(true);
                    setResetfunc(false);
                }, setTimeoutsecs);
            }
        }
    }


    const handleRowClick = async (row) => {
        settabIndex('0');
        setSelectedRow(row);
        setTableDialog(true);
        try {
            let resp = await regList();
            setRegistrationData(resp.result.filter(x => x.user_id == row._id)[0]);
        } catch (error) {
            console.log(error)
        }

        try {
            let resp = await phqAnsList();
            resp ? setPhqAnsData(resp?.result.filter(x => x.user_id === row._id)) : setPhqAnsData([]);
        } catch (error) {
            console.log(error)
        }
    };

    const handleEditRowClick = async (row) => {
        row.date_of_birth.toString()
        setEditTableDialog(true);
        setansArr([]); setqueGet([]); setphqDone(false); setftrA(''); setscrObj({ 1: 0, 2: 0, 3: 0, total: 0 })
        setTableDialog(false);
        setResetfunc(false);
        setClickhereEnable(false);
        setSelectedRow(row);
        setFormObjError('');
        let userRegdata = '';
        let Regdata = '';
        try {
            let resp = await userList();
            userRegdata = resp.result.filter(x => x._id == row._id)[0];
        } catch (error) {
            console.log(error)
        }
        try {
            let resp = await regList();
            Regdata = resp.result.filter(x => x.user_id == row._id)[0];
        } catch (error) {
            console.log(error)
        }
        setFormObj(prevState => ({
            ...prevState,
            userId: userRegdata._id,
            regId: Regdata !== undefined && (Regdata._id),
            name: userRegdata.full_name,
            sex: userRegdata.gender,
            dob: userRegdata.date_of_birth,
            relation: Regdata !== undefined && (Regdata.relationship),
            otherRelation: Regdata !== undefined && (Regdata.other_relationship),
            lossDate: Regdata !== undefined && Regdata.date_you_lost_them,
            support: Regdata !== undefined && (Regdata.supported_person),
            profHelp: Regdata !== undefined && (Regdata.any_proffessional_help),
            adrs: userRegdata.address,
            phnNum: userRegdata.mobile_number,
            ref: Regdata !== undefined && (Regdata.how_you_know_SAS),
            meeting: Regdata !== undefined && (Regdata.support_via),
            emailID: userRegdata.email_id,
            notes: userRegdata.hasOwnProperty('notes') ? userRegdata.notes : ""

        }));
    };

    const handleRegister = async () => {
        setLoading(true);

        try {
            if (selectedRow.is_Offline_member) {
                let data = {
                    full_name: formObj.name.trim(),
                    date_of_birth: new Date(formObj.dob),
                    gender: formObj.sex,
                    mobile_number: formObj.phnNum.trim(),
                    email_id: formObj.emailID.trim(),
                    address: formObj.adrs.trim(),
                    notes: formObj.notes.trim()
                }

                let resp = await EditMem(formObj.userId, data);
                setUserUpdated(true);
                setTimeout(() => {
                    if (resp) {
                        setLoading(false);
                        setEditTableDialog(false);
                        fetchFileData();
                        setUserUpdated(false);
                    }
                }, setTimeoutsecs);
            } else {
                let data = {
                    full_name: formObj.name,
                    date_of_birth: new Date(formObj.dob),
                    gender: formObj.sex,
                    // mobile_number: formObj.phnNum,
                    // email_id: formObj.emailID,
                    address: formObj.adrs,
                    notes: formObj.notes
                }

                let resp = await Edituser(formObj.userId, data);
                setUserUpdated(true);
                setTimeout(() => {
                    if (resp) {
                        setLoading(false);
                        setEditTableDialog(false);
                        fetchFileData();
                        setUserUpdated(false);
                    }
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setUserUpdateFailed(error.response.data.message);
                setUserUpdated(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setUserUpdateFailed(error.message);
                setUserUpdated(true);
            } setTimeout(() => {
                setLoading(false);
                // setEditTableDialog(false);
                fetchFileData();
                setUserUpdated(false);
            }, setTimeoutsecs);

        }
    }

    const handleDisableRowClick = async () => {
        setDisableLoading(true);
        try {
            let resp = await Disableuser(formObj.userId);
            setDisableUser(true);
            if (resp) {
                setTimeout(() => {
                    setDisableLoading(false);
                    setDisableTableDialog(false);
                    fetchFileData();
                    setEditTableDialog(false);
                    setDisableUser(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setUserDisableFailed(error.response.data.message);
                setDisableUser(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setUserDisableFailed(error.message);
                setDisableUser(true);
            } setTimeout(() => {
                setDisableLoading(false);
                setDisableTableDialog(false);
                fetchFileData();
                setEditTableDialog(false);
                setDisableUser(false);
            }, setTimeoutsecs);
        }
    };

    const handleEnableRowClick = async () => {
        setLoading(true);
        try {
            let resp = await Enableuser(formObj.userId);
            setEnableUser(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setEnableTableDialog(false);
                    fetchFileData();
                    setEditTableDialog(false);
                    setEnableUser(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setUserEnableFailed(error.response.data.message);
                setEnableUser(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setUserEnableFailed(error.message);
                setEnableUser(true);
            } setTimeout(() => {
                setLoading(false);
                setEnableTableDialog(false);
                fetchFileData();
                setEditTableDialog(false);
                setEnableUser(false);
            }, setTimeoutsecs);
        }
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeStatusFilter = (event) => {
        setStatusFilter(event.target.value);
        setPage(0); // Reset page when filter changes
    };

    const fetchData = async () => {
        const resp1 = await phqQueGet();
        const resp2 = await phqAnsList();
        if (resp1) { setqueGet(resp1.result) }
        if (resp2) {
            const resp2Tmp = resp2.result.find(item => item.user_id === userId);
            if (resp2Tmp) {
                setansArr(resp2Tmp.response); setftrA(resp2Tmp.difficultyScore);
                resp2Tmp.response.length > 0 ? setphqDone(true) : setphqDone(false);
            }
        }
    };

    const fetchFileData = async () => {
        try {
            let resp = await userList();
            const filteredList = resp.result;
            setUserData(filteredList.reverse());
        } catch (error) {
            console.log(error)
        }

        try {
            let resp = await phqAnsList();
            setPhqList(resp?.result);
        } catch (error) {
            console.log(error)
        }

        try {
            let resp = await phqQueGet();
            setQuestionList(resp.result);
        } catch (error) {
            console.log(error)
        }
    };

    const EditSubmitForm = async () => {
        setLoading(true);
        try {
            const phqObj = { user_id: selectedRow._id, response: ansArr, difficultyScore: ftrA }
            const resp = await phqAnsPost(phqObj);
            if (resp) {
                const resp2 = await phqAnsList();
                if (resp2) {
                    const resp2Tmp = resp2.result.find(item => item.user_id === selectedRow._id);
                    setPhq9UserUpdated(true);
                    if (resp2Tmp) {
                        setTimeout(() => {
                            setLoading(false);
                            setPhq9UserUpdated(false);
                            fetchFileData();
                            fetchData();
                            setansArr(resp2Tmp.response);
                            setftrA(resp2Tmp.difficultyScore);
                            setphqDone(true);
                            setEditTableDialog(false);
                        }, setTimeoutsecs);
                    }
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setPhq9UserUpdateFailed(error.response.data.message);
                setPhq9UserUpdated(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setPhq9UserUpdateFailed(error.message);
                setPhq9UserUpdated(true);
            } setTimeout(() => {
                setLoading(false);
                setPhq9UserUpdated(false);
                fetchFileData();
                fetchData();
            }, setTimeoutsecs);
        }
    }

    const SubmitForm = async () => {
        setLoading(true);
        try {
            const phqObj = { user_id: memuserId, response: ansArr, difficultyScore: ftrA }
            const resp = await phqAnsPost(phqObj);
            setAddMemPhqAlert(true);
            if (resp) {
                const resp2 = await phqAnsList();
                if (resp2) {
                    const resp2Tmp = resp2.result.find(item => item.user_id === memuserId);
                    if (resp2Tmp) {
                        setTimeout(() => {
                            setLoading(false);
                            setAddMemPhqAlert(false);
                            fetchFileData();
                            fetchData();
                            setansArr(resp2Tmp.response);
                            setftrA(resp2Tmp.difficultyScore);
                            setphqDone(true);
                            setAddMemDialog(false);
                        }, setTimeoutsecs);
                    }
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setAddMemPhqAlertFailed(error.response.data.message);
                setAddMemPhqAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setAddMemPhqAlertFailed(error.message);
                setAddMemPhqAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setAddMemPhqAlert(false);
                fetchFileData();
                fetchData();
            }, setTimeoutsecs);
        }
    }

    useEffect(() => {
        fetchFileData();
        fetchData();
        let scrUpdate = { 1: 0, 2: 0, 3: 0, total: 0 };
        scrUpdate.total = ansArr.reduce((total, obj) => total + obj.score, 0);
        ansArr.forEach(ans => { if (scrUpdate.hasOwnProperty(ans.score)) { scrUpdate[ans.score]++ } });
        setscrObj(scrUpdate);
    }, [ansArr]);

    const filteredData = userData.filter(user => {
        const Dob = formatDate(user.date_of_birth).toLowerCase();
        const searchQueryLower = searchQuery.toLowerCase();

        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });
        const matchesDOBDate = Dob.includes(searchQueryLower);

        const statusMatches = statusFilter === 'All' || user.status === statusFilter;

        return (matchesSearchQuery || matchesDOBDate) && statusMatches;
    });
    const nonMobileStyles = {
        flexGrow: 1,
        p: 3,
    };

    const downloadPdf = () => {
        setDisableDownload(true);
        const input = document.getElementById('detailsPdf');
        input.style.margin = '0';
        input.style.padding = '0';

        html2pdf(input, {
            margin: [0, 0, 0, 0], // Adjust margin as needed
            filename: `${selectedRow.full_name}.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 5 },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            // pagebreak: { mode: 'avoid-all', before: "#phqPdf" }
        });
        setDisableDownload(false);
        // setPDFView(false);
    };

    const generatePDF = async () => {
        setPDFView(true);
    };

    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container component="main" sx={{ width: '80vw' }}>

            <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                    {userData.length > 0 && <>
                        <FormControl sx={{ minWidth: '125px' }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                value={statusFilter}
                                onChange={handleChangeStatusFilter}
                                label="Status"
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                                <MenuItem value="Disabled">Disabled</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '130px' }}>
                            <TextField
                                label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl> </>}
                    <Tooltip hidden={PerChk('ADD_ADMIN')} title="Add Admin">
                        <Button variant="outlined" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={() => { setAddAdminDialog(true); setAddAdmin(''); setAddAdminError('') }} startIcon={<PersonAddIcon />}>
                            Add Admin
                        </Button>
                    </Tooltip>

                    <Tooltip hidden={PerChk('ADD_OFFLINE_MEMBER')} title="Add Member">
                        <Button variant="outlined" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={() => { setAddMemDialog(true); setAddMem(''); setAddMemError(''); setActiveStep(0); setansArr([]); setqueGet([]); setphqDone(false); setftrA(''); setscrObj({ 1: 0, 2: 0, 3: 0, total: 0 }) }} startIcon={<PersonAddIcon />}>
                            Add Member
                        </Button>
                    </Tooltip>
                </Box >

            </Box>
            <Dialog fullWidth maxWidth='lg' open={addMemdialog}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Add Member</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setAddMemDialog(false)} />
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                            {activeStep === 0 && <>
                                <Grid item xs={6}><TextField required label='Name' value={addMem.name}
                                    onChange={(e) => setAddMem({ ...addMem, name: e.target.value })} /></Grid>


                                <Grid item xs={6}><TextField select required label="Gender" value={addMem.sex}
                                    onChange={(e) => setAddMem({ ...addMem, sex: e.target.value })}>
                                    {gen.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required type="date" label="Date of Birth" value={formatDateStr(addMem.dob)} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setAddMem({ ...addMem, dob: e.target.value })} inputProps={{ max: today }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField select required label="Who did you lose to suicide?" value={addMem.relation} onChange={(e) => setAddMem({ ...addMem, relation: e.target.value })}>
                                        {rel.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                    </TextField>
                                </Grid>

                                {addMem.relation === 'Other' && (
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                                        <SubdirectoryArrowRightIcon />
                                        <TextField label='Specify other relationship' value={addMem.otherRelation} onChange={(e) => setAddMem({ ...addMem, otherRelation: e.target.value })} />
                                    </Grid>
                                )}

                                <Grid item xs={6}>
                                    <TextField type="date" label="Date you lost him/her" value={formatDateStr(addMem.lossDate)} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setAddMem({ ...addMem, lossDate: e.target.value })} inputProps={{ max: today }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField select required label='Did you take any professional help?' value={addMem.profHelp} onChange={(e) => setAddMem({ ...addMem, profHelp: e.target.value })}>
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required label="Who supported you at that point of time?" value={addMem.support} onChange={(e) => setAddMem({ ...addMem, support: e.target.value })} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField label="Postal address" value={addMem.adrs} onChange={(e) => setAddMem({ ...addMem, adrs: e.target.value })} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField label="Phone number" value={addMem.phnNum} onChange={(e) => {
                                        const phoneNum = e.target.value;
                                        setAddMem({
                                            ...addMem,
                                            phnNum: phoneNum
                                        });
                                        setAddMemError({
                                            ...addMemError,
                                            phnNum: validatePhoneNumber(phoneNum) ? '' : 'Please enter a valid Mobile Number'
                                        });
                                    }}
                                        error={!!addMemError.phnNum} helperText={addMemError.phnNum} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField label="Email Address" value={addMem.emailID} onChange={(e) => {
                                        const emailValue = e.target.value;
                                        setAddMem({
                                            ...addMem,
                                            emailID: emailValue
                                        });
                                        setAddMemError({
                                            ...addMemError,
                                            emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                                        });
                                    }}
                                        error={!!addMemError.emailID} helperText={addMemError.emailID} />
                                </Grid>

                                <Grid item xs={6}> <TextField select required label="Session preference" value={addMem.meeting}
                                    onChange={(e) => setAddMem({ ...addMem, meeting: e.target.value })}>
                                    {meetingData.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField label="How did you hear about SNEHA-SAS?" value={addMem.ref} onChange={(e) => setAddMem({ ...addMem, ref: e.target.value })} />
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                                    <Button type="submit" disabled={disableaddMembutton || loading}
                                        variant="contained"
                                        sx={pBtn} onClick={handleAddMem}>Save{loading ? <CircularProgress /> : null}</Button>
                                </Grid>
                            </>}
                            {activeStep === 1 &&
                                <>
                                    <Card>
                                        <Typography variant='h6'>My PHQ9 {!phqDone && <span style={{ color: 'red', marginLeft: 10, fontSize: '60%' }}>Please fill this form</span>}</Typography>

                                        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                            <Grid item xs={4}><Typography fontWeight='bold'>Over the last 2 weeks, how often have you been bothered by any of the following problems?</Typography></Grid>
                                            <Grid item xs={2}><Typography fontWeight='bold'>Not at all</Typography></Grid>
                                            <Grid item xs={2}><Typography fontWeight='bold'>Several days</Typography></Grid>
                                            <Grid item xs={2}><Typography fontWeight='bold'>More than half the days</Typography></Grid>
                                            <Grid item xs={2}><Typography fontWeight='bold'>Nearly every day</Typography></Grid>
                                            <Grid item xs={12}><Divider sx={{ border: '1px solid #b5651d' }} /></Grid>
                                        </Grid>

                                        {queGet.map((item, index) => (<Ques idx={index} que={item.question} id={item._id} />))}

                                        <Divider sx={{ border: '1px solid #b5651d' }} />

                                        <Grid container sx={{ my: 2 }}>{isMobile ?
                                            <Grid item xs={12}><Typography><b>Total score = {scrObj.total}</b></Typography></Grid> :
                                            <>
                                                <Grid item xs={3}><Typography><b>Total score: {scrObj.total}</b></Typography></Grid>
                                                <Grid item xs={1} sx={{ ml: 1 }}><Typography><b>=</b></Typography></Grid>
                                                <Grid item xs={1}><Typography>0</Typography></Grid>
                                                <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                <Grid item xs={1}><Typography>{scrObj[1] * 1}</Typography></Grid>
                                                <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                <Grid item xs={1}><Typography>{scrObj[2] * 2}</Typography></Grid>
                                                <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                <Grid item xs={1}><Typography>{scrObj[3] * 3}</Typography></Grid>
                                            </>
                                        }</Grid>

                                        <Grid container sx={{ my: 2 }}>
                                            <Grid item xs={isMobile ? 12 : 10}><FormControl disabled={phqDone}>
                                                <FormLabel><Typography sx={{ color: '#000' }}>
                                                    If you checked off any problems, how difficult have these problems made it for you to do your
                                                    work, take care of things at home, or get along with other people?
                                                </Typography></FormLabel>
                                                <RadioGroup value={ftrA} onChange={(e) => setftrA(e.target.value)}><Grid container spacing={2}
                                                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="0" control={<Radio />} label="Not difficult at all" /></Grid>
                                                    <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="1" control={<Radio />} label="Somewhat difficult" /></Grid>
                                                    <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="2" control={<Radio />} label="Very difficult" /></Grid>
                                                    <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="3" control={<Radio />} label="Extremely difficult" /></Grid>
                                                </Grid></RadioGroup>
                                            </FormControl></Grid>

                                            {!phqDone && <Grid item xs={isMobile ? 12 : 2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'right' }}>
                                                <Button sx={pBtn} size='small' onClick={SubmitForm} disabled={ansArr.length < queGet.length || ftrA === null}>Save{loading ? <CircularProgress /> : null}</Button></Grid>}
                                        </Grid>

                                        <footer className='text-center'><Typography sx={{ color: 'GrayText' }}>
                                            Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from
                                            Pfizer Inc. No permission required to reproduce, translate, display or distribute.
                                        </Typography></footer>
                                    </Card>
                                </>}

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center" }}>
                                <Stepper activeStep={activeStep} alternativeLabel component='footer'>
                                    {steps.map((label) => (<Step key={label}><StepLabel>{label}</StepLabel></Step>))}
                                </Stepper>
                            </Grid>
                        </Box>
                    </Container>
                </DialogContent>
                {addMemAlertFailed ? <Snackbar
                    open={addMemAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {addMemAlertFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={addMemAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Member created successfully
                    </Alert>
                </Snackbar>}
                {addMemphqAlertFailed ? <Snackbar
                    open={addMemphqAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {addMemphqAlertFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={addMemphqAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        PHQ9 created successfully
                    </Alert>
                </Snackbar>}


            </Dialog>
            <Dialog fullWidth maxWidth='md' open={addAdmindialog}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Add Admin</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setAddAdminDialog(false)} />
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                            <Grid item sm={6}> <TextField required label='Name' value={addAdmin.username}
                                onChange={(e) => setAddAdmin({ ...addAdmin, username: e.target.value })} /></Grid>
                            <Grid item sm={6}> <TextField select required label="Gender" value={addAdmin.sex}
                                onChange={(e) => setAddAdmin({ ...addAdmin, sex: e.target.value })}>
                                {gen.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                            </Grid>

                            <Grid item sm={6}>
                                <TextField required type="date" label="Date of Birth" value={formatDateStr(addAdmin.dob)} InputLabelProps={{ shrink: true }} onChange={(e) => setAddAdmin({ ...addAdmin, dob: e.target.value })} />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField label="Postal address" value={addAdmin.adrs} onChange={(e) => setAddAdmin({ ...addAdmin, adrs: e.target.value })} />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField required label="Phone number" value={addAdmin.phnNum} onChange={(e) => {
                                    const phoneNum = e.target.value;
                                    setAddAdmin({
                                        ...addAdmin,
                                        phnNum: phoneNum
                                    });
                                    setAddAdminError({
                                        ...addAdminError,
                                        phnNum: validatePhoneNumber(phoneNum) ? '' : 'Please enter a valid Mobile Number'
                                    });
                                }}
                                    error={!!addAdminError.phnNum} helperText={addAdminError.phnNum} />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField required label="Email Address" value={addAdmin.emailID} onChange={(e) => {
                                    const emailValue = e.target.value;
                                    setAddAdmin({
                                        ...addAdmin,
                                        emailID: emailValue
                                    });
                                    setAddAdminError({
                                        ...addAdminError,
                                        emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                                    });
                                }}
                                    error={!!addAdminError.emailID} helperText={addAdminError.emailID} />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField required label="Password" type="password" value={addAdmin.pw} onChange={(e) => {
                                    const newPassword = e.target.value;
                                    setAddAdmin({
                                        ...addAdmin,
                                        pw: newPassword
                                    });
                                    setAddAdminError({
                                        ...addAdminError,
                                        pw: validatePassword(newPassword)
                                    });
                                }}
                                    error={!!addAdminError.pw} helperText={addAdminError.pw} />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField label="Role" disabled value="SAS_Admin" />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Button type="submit" disabled={disableaddAdminbutton || loading}
                                    variant="contained"
                                    sx={pBtn} onClick={handleAddAdmin}>Save{loading ? <CircularProgress /> : null}</Button>
                            </Grid>
                        </Box>
                    </Container>
                </DialogContent>
                {addAdminAlertFailed ? <Snackbar
                    open={addAdminAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {addAdminAlertFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={addAdminAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        New admin created
                    </Alert>
                </Snackbar>}

            </Dialog>
            {filteredData.length > 0 ?
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                                <TableRow sx={{ alignItems: 'center' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email Id</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '11%' }}>DOB</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Address</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Role</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Offline Members</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PHQ</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Notes</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredData
                                ).map((row) => (
                                    <TableRow key={row.email_id} onClick={() => handleRowClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#fcf2e7',
                                            },
                                        }}>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.full_name}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                            {row.email_id.endsWith("@sas.com") ? '-' : row.email_id}
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            {row.mobile_number === "0000000000" ? '-' : row.mobile_number}
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            {formatDate(row.date_of_birth)}
                                        </TableCell>
                                        {row.address === "" || row.address === undefined ?
                                            <TableCell sx={{ textTransform: 'none', whiteSpace: 'pre-line' }} component="th" scope="row" >
                                                -</TableCell> : <TableCell sx={{ textTransform: 'none', whiteSpace: 'pre-line' }} component="th" scope="row" >
                                                {row.address}
                                            </TableCell>}

                                        {row.roletype_key === undefined ?
                                            <TableCell component="th" scope="row" >
                                                -
                                            </TableCell> :
                                            <TableCell component="th" scope="row" >
                                                {row.roletype_key}
                                            </TableCell>}

                                        <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row" >
                                            {row.is_Offline_member ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none', whiteSpace: 'pre-line', textAlign: 'center' }} component="th" scope="row" >
                                            {phqList.filter(x => x.user_id === row._id).length > 0 ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textTransform: 'none',
                                                textAlign: 'center',
                                                color:
                                                    row.status === 'Active'
                                                        ? 'green'
                                                        : row.status === 'Rejected'
                                                            ? 'red'
                                                            : row.status === 'Pending' ? 'darkblue'
                                                                : '#d3d3d3',
                                            }}
                                            component="th"
                                            scope="row"
                                        >
                                            {statusFilter === 'All' || row.status === statusFilter ? row.status : ''}
                                        </TableCell>
                                        {row.notes === undefined ?
                                            <TableCell component="th" scope="row" >
                                                -
                                            </TableCell> :
                                            <TableCell component="th" scope="row" >
                                                {row.notes}
                                            </TableCell>}
                                        {row.status === 'Active' ?
                                            <TableCell>
                                                <Tooltip hidden={PerChk('EDIT_USER')} title="Edit">
                                                    <EditIcon sx={{ color: '#b5651d' }} onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }}></EditIcon>
                                                </Tooltip>
                                            </TableCell>
                                            : row.status === 'Disabled' ?
                                                <TableCell>
                                                    <Tooltip hidden={(PerChk('EDIT_USER') || PerChk('ENABLE_USER'))} title="Edit">
                                                        <EditIcon sx={{ color: '#b5651d' }} onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }}></EditIcon>
                                                    </Tooltip>
                                                </TableCell>
                                                :
                                                <TableCell>
                                                    <Tooltip hidden={PerChk('EDIT_USER')} title="Edit">
                                                        <EditIcon sx={{ color: '#d3d3d3', pointerEvents: 'none', }} onClick={(e) => { e.stopPropagation(); setTableDialog(false) }}></EditIcon>
                                                    </Tooltip>
                                                </TableCell>}
                                    </TableRow>
                                ))}

                            </TableBody>
                            <TableFooter><TableRow><TablePagination count={userData.length}
                                page={page} rowsPerPage={rowsPerPage}
                                sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                ActionsComponent={TPA} /></TableRow></TableFooter>
                        </Table>
                    </TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}
            {selectedRow && (selectedRow.roletype_key === undefined || selectedRow.roletype_key === 'SAS_MEMBER' ?
                <>
                    <Dialog open={tableDialog} fullWidth
                        maxWidth='lg'
                        sx={{
                            '& .MuiDialog-paper': {
                                height: '100%',
                                maxHeight: '100%',
                                margin: 0,
                            },
                        }}
                    >
                        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                            <Grid container spacing={2} direction="row" style={{ alignItems: "center", display: "flex" }}>
                                <Grid item sm={9} style={{ display: "flex", justifyContent: "start" }}>
                                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Tablelist'}</Typography>
                                </Grid>
                                <Grid item sm={2} style={{ display: "flex", justifyContent: "end" }}>
                                    <Button type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, textTransform: 'none', backgroundColor: 'brown', '&:hover': { color: '#000', backgroundColor: '#c9762b' } }} onClick={generatePDF}>View PDF</Button>
                                </Grid>
                                <Grid item sm={1} style={{ display: "flex", justifyContent: "end" }}><CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} /></Grid>
                            </Grid>
                        </DialogTitle>
                        {/* <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                            <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Tablelist'}</Typography>
                            <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} />
                        </DialogTitle> */}
                        <DialogContent>
                            <ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', flexDirection: 'column' }}>

                                <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right', bgcolor: '#1px solid grey', p: 2, height: '20px', top: 0 }}>
                                    <ThemeProvider theme={tabThemeAdmin}>
                                        <Box sx={{ width: '100%' }}>
                                            <Tabs value={tabIndex} onChange={(e, ti) => settabIndex(ti)} >
                                                <Tab value="0" label="Details" />
                                                <Tab value="1" label="PHQ9 response" />
                                            </Tabs>
                                        </Box>
                                    </ThemeProvider>
                                </Toolbar>

                                <Box>
                                    {tabIndex === '0' && selectedRow && registrationData && (
                                        <Box component="main" sx={!isMobile ? nonMobileStyles : {}}> <Container component="main" maxWidth='100%' id='html2pdf'>
                                            <Grid container spacing={2}>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Name</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{selectedRow.full_name}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Phone number</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{selectedRow.mobile_number === "0000000000" ? '-' : selectedRow.mobile_number}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Email ID</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all', flowWrap: 'break-word', }}>{selectedRow.email_id.endsWith("@sas.com") ? '-' : selectedRow.email_id}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{formatDate(selectedRow.date_of_birth)}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Status</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6} sx={{
                                                    textTransform: 'none',
                                                    color:
                                                        selectedRow.status === 'Active'
                                                            ? 'green'
                                                            : selectedRow.status === 'Rejected'
                                                                ? 'red'
                                                                : 'darkblue',
                                                }}>
                                                    <Typography>{selectedRow.status}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Remarks</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {selectedRow.remarks === "" || selectedRow.remarks === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.remarks}</Typography>}

                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Gender</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{selectedRow.gender}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Postal address</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {selectedRow.address === "" || selectedRow.address === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.address}</Typography>}

                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Role</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {selectedRow.roletype_key === "" || selectedRow.roletype_key === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.roletype_key}</Typography>}
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who did you lose to suicide</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{registrationData && registrationData.relationship === "Other" ? `${registrationData.other_relationship}` : registrationData.relationship}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date you lost him/her</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{registrationData && registrationData.date_you_lost_them ? formatDate(registrationData.date_you_lost_them) : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who supported you at that point of time</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{registrationData && registrationData.supported_person ? registrationData.supported_person : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Did you take any professional help</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {/* {registrationData ? (registrationData.any_proffessional_help ? <Typography>Yes</Typography> : <Typography>No</Typography>) : "-"} */}
                                                    <Typography>{registrationData && registrationData.any_proffessional_help ? "Yes" : "No"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>How did you hear about SNEHA-SAS</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{registrationData && registrationData.how_you_know_SAS ? registrationData.how_you_know_SAS : "-"}</Typography>

                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Session preference</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{registrationData && registrationData.support_via ? registrationData.support_via : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Notes</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{selectedRow && selectedRow.notes ? selectedRow.notes : "-"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Container></Box>
                                    )}
                                    {tabIndex === '1' && <Box component="main" sx={!isMobile ? nonMobileStyles : {}}><Container component="main" maxWidth='100%'>
                                        {phqAnsData.length > 0 ? <TableContainer component={Paper}>
                                            <Table sx={{ width: '100%' }} aria-label="custom pagination table">
                                                <TableHead sx={{ bgcolor: '#a5644e' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Question</TableCell>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PHQ Response</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {questionList.map((questionrow) => (
                                                        <TableRow>
                                                            <TableCell sx={{ textTransform: 'none', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }} component="th" scope="row">
                                                                {questionrow.question}
                                                            </TableCell>
                                                            <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                                                {anscode[phqAnsData[0].response.filter(x => x.question_id == questionrow._id)[0].score]}<br />
                                                                Score - {phqAnsData[0].response.filter(x => x.question_id == questionrow._id)[0].score}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow>

                                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">

                                                        </TableCell>
                                                        <TableCell sx={{ textTransform: 'none', fontWeight: "700" }} component="th" scope="row" >
                                                            Total Score - {phqAnsData[0].response.reduce((sum, item) => sum + item.score, 0)}
                                                        </TableCell>
                                                        {/* <TableCell colSpan={1}>
                                                </TableCell>
                                                <TableCell colSpan={2} align="right" style={{ fontWeight: "700" }}>
                                                    Total Score - {phqAnsData[0].response.reduce((sum, item) => sum + item.score, 0)}
                                                </TableCell> */}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} sx={{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                                            {phqAnsData.length > 0 ?
                                                                <Typography sx={{ marginTop: '15px' }} variant='body1'>If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people - <span style={{ fontWeight: '700' }}>{difficultanscode[phqAnsData[0].difficultyScore]}</span></Typography> : null}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                {/* <TableFooter> */}

                                                {/* </TableFooter> */}

                                            </Table>
                                        </TableContainer> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No response collected for the PHQ9 form</Typography>}

                                    </Container>
                                    </Box>}
                                </Box>
                            </Box ></ThemeProvider >
                        </DialogContent>

                    </Dialog>
                </> : <>
                    <Dialog open={tableDialog} fullWidth
                        maxWidth='lg'
                        sx={{
                            '& .MuiDialog-paper': {
                                height: '100%',
                                maxHeight: '100%',
                                margin: 0,
                            },
                        }}
                    >
                        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                            <Grid container spacing={2} style={{ alignItems: "center", display: "flex" }}>
                                <Grid item sm={9} style={{ display: "flex", justifyContent: "start" }}>
                                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Tablelist'}</Typography>
                                </Grid>
                                <Grid item sm={2} style={{ display: "flex", justifyContent: "end" }}>
                                    <Button type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, textTransform: 'none', backgroundColor: 'brown', '&:hover': { backgroundColor: 'maroon' } }} onClick={generatePDF}>View PDF</Button>
                                </Grid>
                                <Grid item sm={1} style={{ display: "flex", justifyContent: "end" }}><CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} /></Grid>
                            </Grid>
                        </DialogTitle>
                        {/* <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                            <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Tablelist'}</Typography>
                            <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setTableDialog(false)} />
                        </DialogTitle> */}
                        <DialogContent>
                            {selectedRow && (
                                <Box component="main" sx={!isMobile ? nonMobileStyles : {}}> <Container component="main" maxWidth='100%'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Name</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{selectedRow.full_name}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Phone number</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{selectedRow.mobile_number}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Email ID</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all', flowWrap: 'break-word', }}>{selectedRow.email_id}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{formatDate(selectedRow.date_of_birth)}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Status</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{
                                            textTransform: 'none',
                                            color:
                                                selectedRow.status === 'Active'
                                                    ? 'green'
                                                    : selectedRow.status === 'Rejected'
                                                        ? 'red'
                                                        : 'darkblue',
                                        }}>
                                            <Typography>{selectedRow.status}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Gender</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{selectedRow.gender}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Postal address</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {selectedRow.address === "" || selectedRow.address === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.address}</Typography>}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Role</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {selectedRow.roletype_key === "" || selectedRow.roletype_key === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.roletype_key}</Typography>}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Notes</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{selectedRow && selectedRow.notes ? selectedRow.notes : "-"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Container></Box>
                            )}
                        </DialogContent>

                    </Dialog>
                </>
            )}
            <Dialog open={edittableDialog} fullWidth
                maxWidth='lg'
                sx={{
                    '& .MuiDialog-paper': {
                        height: '100%',
                        maxHeight: '100%',
                        margin: 0,
                    },
                }}
            >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Edit'}</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setEditTableDialog(false)} />
                </DialogTitle>
                <DialogContent>
                    {selectedRow && <>
                        {selectedRow.is_Offline_member && !(phqList.filter(x => x.user_id === selectedRow._id).length > 0) ?
                            <>
                                <ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', flexDirection: 'column' }}>

                                    <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right', bgcolor: '#1px solid grey', p: 2, height: '20px', top: 0 }}>
                                        <ThemeProvider theme={tabThemeAdmin}>
                                            <Box sx={{ width: '100%' }}>
                                                <Tabs value={tabIndex} onChange={(e, ti) => settabIndex(ti)} >
                                                    <Tab value="0" label="Details" />
                                                    <Tab value="1" label="PHQ9 response" />
                                                </Tabs>
                                            </Box>
                                        </ThemeProvider>
                                    </Toolbar>

                                    <Box>
                                        {tabIndex === '0' &&
                                            <Box component="main" sx={!isMobile ? nonMobileStyles : {}}>
                                                <Container>
                                                    <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                                                        {selectedRow && (selectedRow.status === 'Active' ?
                                                            <>
                                                                <Grid item sm={6}> <TextField required label='Name' value={formObj.name}
                                                                    onChange={(e) => setFormObj({ ...formObj, name: e.target.value })} /></Grid>

                                                                <Grid item sm={6}> <TextField select required label="Gender" value={formObj.sex}
                                                                    onChange={(e) => setFormObj({ ...formObj, sex: e.target.value })}>
                                                                    {gen.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                                                </Grid>

                                                                <Grid item sm={6}>
                                                                    <TextField required type="date" label="Date of Birth" value={formatDateStr(formObj.dob)} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, dob: e.target.value })} />
                                                                </Grid>

                                                                <Grid item sm={6}>
                                                                    <TextField label="Postal address" value={formObj.adrs} onChange={(e) => setFormObj({ ...formObj, adrs: e.target.value })} />
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <TextField label="Phone number" value={formObj.phnNum === "0000000000" ? '' : formObj.phnNum} onChange={(e) => {
                                                                        const phoneNum = e.target.value;
                                                                        setFormObj({
                                                                            ...formObj,
                                                                            phnNum: phoneNum
                                                                        });
                                                                        setFormObjError({
                                                                            ...formObjerror,
                                                                            phnNum: validatePhoneNumber(phoneNum) ? '' : 'Please enter a valid Mobile Number'
                                                                        });
                                                                    }}
                                                                        error={!!formObjerror.phnNum} helperText={formObjerror.phnNum} />
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <TextField label="Email Address" value={formObj.emailID.endsWith("@sas.com") ? '' : formObj.emailID} onChange={(e) => {
                                                                        const emailValue = e.target.value;
                                                                        setFormObj({
                                                                            ...formObj,
                                                                            emailID: emailValue
                                                                        });
                                                                        setFormObjError({
                                                                            ...formObjerror,
                                                                            emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                                                                        });
                                                                    }}
                                                                        error={!!formObjerror.emailID} helperText={formObjerror.emailID} />
                                                                </Grid>
                                                                <Grid item sm={6}>
                                                                    <TextField label="Notes" value={formObj.notes} onChange={(e) => setFormObj({ ...formObj, notes: e.target.value })} />
                                                                </Grid>
                                                                {selectedRow.roletype_key === 'SAS_MEMBER' && (
                                                                    <>
                                                                        <Grid item sm={6}>
                                                                            <TextField disabled select required label="Who did you lose to suicide?" value={formObj.relation} onChange={(e) => setFormObj({ ...formObj, relation: e.target.value })}>
                                                                                {rel.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                                                            </TextField>
                                                                        </Grid>

                                                                        {formObj.relation === 'Other' && (
                                                                            <Grid item sm={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                                                                                <SubdirectoryArrowRightIcon />
                                                                                <TextField disabled label='Specify other relationship' value={formObj.otherRelation} onChange={(e) => setFormObj({ ...formObj, otherRelation: e.target.value })} />
                                                                            </Grid>
                                                                        )}

                                                                        <Grid item sm={6}>
                                                                            <TextField disabled type="date" label="Date you lost him/her" aria-readonly value={formatDateStr(formObj.lossDate)} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, lossDate: e.target.value })} inputProps={{ max: today }} />
                                                                        </Grid>

                                                                        <Grid item sm={6}>
                                                                            <TextField disabled required label="Who supported you at that point of time?" value={formObj.support} onChange={(e) => setFormObj({ ...formObj, support: e.target.value })} />
                                                                        </Grid>

                                                                        <Grid item sm={6}>
                                                                            <TextField disabled select required label='Did you take any professional help?' value={formObj.profHelp} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, profHelp: e.target.value })}>
                                                                                <MenuItem value={true}>Yes</MenuItem>
                                                                                <MenuItem value={false}>No</MenuItem>
                                                                            </TextField>
                                                                        </Grid>

                                                                        <Grid item sm={6}>
                                                                            <TextField disabled label="How did you hear about SNEHA-SAS?" value={formObj.ref} onChange={(e) => setFormObj({ ...formObj, ref: e.target.value })} />
                                                                        </Grid>

                                                                        <Grid item sm={6}> <TextField disabled select required label="Meeting" value={formObj.meeting}
                                                                            onChange={(e) => setFormObj({ ...formObj, meeting: e.target.value })}>
                                                                            {meetingData.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                                                        </Grid>
                                                                    </>
                                                                )}

                                                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button sx={pBtn} disabled={selectedRow.roletype_key === undefined || selectedRow.roletype_key === 'SAS_MEMBER' ?
                                                                        disableRegisterbutton || loading : disableuserRegisterbutton || loading} onClick={handleRegister}>Save{loading ? <CircularProgress /> : null}</Button>
                                                                </Grid>
                                                                <Grid hidden={PerChk('DISABLE_USER')} item xs={12}>
                                                                    <Divider />
                                                                </Grid>
                                                                <Grid hidden={PerChk('DISABLE_USER')} item xs={12}>
                                                                    <Typography variant='h6'>Disable User</Typography>
                                                                    <Typography variant='body1'>Click Yes to disable the user.</Typography>
                                                                </Grid>
                                                                <Grid hidden={PerChk('DISABLE_USER')} item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button sx={nBtn} onClick={() => setDisableTableDialog(true)}>Yes</Button>
                                                                </Grid>
                                                                <Dialog open={disabletableDialog} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                                                                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                                                                        <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                                                                        <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setDisableTableDialog(false)} />
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <Typography variant='body1'>Are you sure you want to disable {formObj.name}?</Typography>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button type="submit" disabled={disableloading}
                                                                            variant="contained"
                                                                            sx={nBtn} onClick={handleDisableRowClick}>Disable{disableloading ? <CircularProgress /> : null}</Button>
                                                                    </DialogActions>
                                                                    {userDisableFailed ? <Snackbar
                                                                        open={userDisable}
                                                                        autoHideDuration={5000}
                                                                    >
                                                                        <Alert
                                                                            severity="error"
                                                                            variant="filled"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            {userDisableFailed}
                                                                        </Alert>
                                                                    </Snackbar> : <Snackbar
                                                                        open={userDisable}
                                                                        autoHideDuration={5000}
                                                                    >
                                                                        <Alert
                                                                            severity="error"
                                                                            variant="filled"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            User disabled
                                                                        </Alert>
                                                                    </Snackbar>}
                                                                </Dialog>
                                                                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} >
                                                                    <Alert
                                                                        onClose={() => setOpenSnackbar(false)}
                                                                        severity="success"
                                                                        variant="filled"
                                                                        sx={{ width: '100%' }}
                                                                    >
                                                                        Password changed for this user.
                                                                    </Alert>
                                                                </Snackbar>

                                                                <Snackbar open={openSnackbarError} autoHideDuration={6000} onClose={() => setOpenSnackbarError(false)} >
                                                                    <Alert
                                                                        onClose={() => setOpenSnackbarError(false)}
                                                                        severity="error"
                                                                        variant="filled"
                                                                        sx={{ width: '100%' }}
                                                                    >
                                                                        {resetObjerror.resetbuttonerror}
                                                                    </Alert>
                                                                </Snackbar>
                                                                {userUpdateFailed ? <Snackbar
                                                                    open={userUpdated}
                                                                    autoHideDuration={5000}
                                                                >
                                                                    <Alert
                                                                        severity="error"
                                                                        variant="filled"
                                                                        sx={{ width: '100%' }}
                                                                    >
                                                                        {userUpdateFailed}
                                                                    </Alert>
                                                                </Snackbar> : <Snackbar
                                                                    open={userUpdated}
                                                                    autoHideDuration={5000}
                                                                >
                                                                    <Alert
                                                                        severity="success"
                                                                        variant="filled"
                                                                        sx={{ width: '100%' }}
                                                                    >
                                                                        User data updated
                                                                    </Alert>
                                                                </Snackbar>}

                                                            </> : <> <Grid hidden={PerChk('ENABLE_USER')} item xs={12}>
                                                                <Typography variant='h6'>Enable User</Typography>
                                                                <Typography variant='body1'>Click Yes to enable the user.</Typography>
                                                            </Grid>
                                                                <Grid hidden={PerChk('ENABLE_USER')} item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button sx={pBtn} onClick={() => setEnableTableDialog(true)}>Yes</Button>
                                                                </Grid>
                                                                <Dialog open={enabletableDialog} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                                                                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                                                                        <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                                                                        <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setEnableTableDialog(false)} />
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <Typography variant='body1'>Are you sure you want to enable {formObj.name}?</Typography>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button type="submit" disabled={loading}
                                                                            variant="contained"
                                                                            sx={pBtn} onClick={handleEnableRowClick}>Enable{loading ? <CircularProgress /> : null}</Button>
                                                                    </DialogActions>
                                                                    {userEnableFailed ? <Snackbar
                                                                        open={userEnable}
                                                                        autoHideDuration={5000}
                                                                    >
                                                                        <Alert
                                                                            severity="error"
                                                                            variant="filled"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            {userEnableFailed}
                                                                        </Alert>
                                                                    </Snackbar> : <Snackbar
                                                                        open={userEnable}
                                                                        autoHideDuration={5000}
                                                                    >
                                                                        <Alert
                                                                            severity="success"
                                                                            variant="filled"
                                                                            sx={{ width: '100%' }}
                                                                        >
                                                                            User enabled
                                                                        </Alert>
                                                                    </Snackbar>}

                                                                </Dialog>
                                                            </>)}
                                                    </Box>
                                                </Container>
                                            </Box>
                                        }
                                        {tabIndex === '1' && <Box component="main" sx={!isMobile ? nonMobileStyles : {}}>
                                            <Container>
                                                <Typography variant='h6'>My PHQ9 {!phqDone && <span style={{ color: 'red', marginLeft: 10, fontSize: '60%' }}>Please fill this form</span>}</Typography>

                                                <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                                    <Grid item xs={4}><Typography fontWeight='bold'>Over the last 2 weeks, how often have you been bothered by any of the following problems?</Typography></Grid>
                                                    <Grid item xs={2}><Typography fontWeight='bold'>Not at all</Typography></Grid>
                                                    <Grid item xs={2}><Typography fontWeight='bold'>Several days</Typography></Grid>
                                                    <Grid item xs={2}><Typography fontWeight='bold'>More than half the days</Typography></Grid>
                                                    <Grid item xs={2}><Typography fontWeight='bold'>Nearly every day</Typography></Grid>
                                                    <Grid item xs={12}><Divider sx={{ border: '1px solid #b5651d' }} /></Grid>
                                                </Grid>

                                                {queGet.map((item, index) => (<Ques idx={index} que={item.question} id={item._id} />))}

                                                <Divider sx={{ border: '1px solid #b5651d' }} />

                                                <Grid container sx={{ my: 2 }}>{isMobile ?
                                                    <Grid item xs={12}><Typography><b>Total score = {scrObj.total}</b></Typography></Grid> :
                                                    <>
                                                        <Grid item xs={3}><Typography><b>Total score: {scrObj.total}</b></Typography></Grid>
                                                        <Grid item xs={1} sx={{ ml: 1 }}><Typography><b>=</b></Typography></Grid>
                                                        <Grid item xs={1}><Typography>0</Typography></Grid>
                                                        <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                        <Grid item xs={1}><Typography>{scrObj[1] * 1}</Typography></Grid>
                                                        <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                        <Grid item xs={1}><Typography>{scrObj[2] * 2}</Typography></Grid>
                                                        <Grid item xs={1}><Typography><b>+</b></Typography></Grid>
                                                        <Grid item xs={1}><Typography>{scrObj[3] * 3}</Typography></Grid>
                                                    </>
                                                }</Grid>

                                                <Grid container sx={{ my: 2 }}>
                                                    <Grid item xs={isMobile ? 12 : 10}><FormControl disabled={phqDone}>
                                                        <FormLabel><Typography sx={{ color: '#000' }}>
                                                            If you checked off any problems, how difficult have these problems made it for you to do your
                                                            work, take care of things at home, or get along with other people?
                                                        </Typography></FormLabel>
                                                        <RadioGroup value={ftrA} onChange={(e) => setftrA(e.target.value)}><Grid container spacing={2}
                                                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="0" control={<Radio />} label="Not difficult at all" /></Grid>
                                                            <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="1" control={<Radio />} label="Somewhat difficult" /></Grid>
                                                            <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="2" control={<Radio />} label="Very difficult" /></Grid>
                                                            <Grid item xs={isMobile ? 12 : 3}><FormControlLabel value="3" control={<Radio />} label="Extremely difficult" /></Grid>
                                                        </Grid></RadioGroup>
                                                    </FormControl></Grid>

                                                    {!phqDone && <Grid item xs={isMobile ? 12 : 2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'right' }}>
                                                        <Button sx={pBtn} size='small' onClick={EditSubmitForm} disabled={ansArr.length < queGet.length || ftrA === null}>Save{loading ? <CircularProgress /> : null}</Button></Grid>}
                                                </Grid>

                                                <footer className='text-center'><Typography sx={{ color: 'GrayText' }}>
                                                    Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant from
                                                    Pfizer Inc. No permission required to reproduce, translate, display or distribute.
                                                </Typography></footer>
                                                {phq9userUpdateFailed ? <Snackbar
                                                    open={phq9userUpdated}
                                                    autoHideDuration={5000}
                                                >
                                                    <Alert
                                                        severity="error"
                                                        variant="filled"
                                                        sx={{ width: '100%' }}
                                                    >
                                                        {phq9userUpdateFailed}
                                                    </Alert>
                                                </Snackbar> : <Snackbar
                                                    open={phq9userUpdated}
                                                    autoHideDuration={5000}
                                                >
                                                    <Alert
                                                        severity="success"
                                                        variant="filled"
                                                        sx={{ width: '100%' }}
                                                    >
                                                        PHQ9 data created successfully
                                                    </Alert>
                                                </Snackbar>}
                                            </Container>
                                        </Box>}
                                    </Box>
                                </Box ></ThemeProvider >
                            </>
                            : <>
                                <ThemeProvider theme={mainTheme}>
                                    <Container>
                                        <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }}>
                                            {selectedRow && (selectedRow.status === 'Active' ?
                                                <>
                                                    <Grid item sm={6}> <TextField required label='Name' value={formObj.name}
                                                        onChange={(e) => setFormObj({ ...formObj, name: e.target.value })} /></Grid>

                                                    <Grid item sm={6}> <TextField select required label="Gender" value={formObj.sex}
                                                        onChange={(e) => setFormObj({ ...formObj, sex: e.target.value })}>
                                                        {gen.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                                    </Grid>

                                                    <Grid item sm={6}>
                                                        <TextField required type="date" label="Date of Birth" value={formatDateStr(formObj.dob)} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, dob: e.target.value })} />
                                                    </Grid>

                                                    <Grid item sm={6}>
                                                        <TextField label="Postal address" value={formObj.adrs} onChange={(e) => setFormObj({ ...formObj, adrs: e.target.value })} />
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <TextField label="Notes" value={formObj.notes} onChange={(e) => setFormObj({ ...formObj, notes: e.target.value })} />
                                                    </Grid>
                                                    {selectedRow.is_Offline_member ?
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField label="Phone number" value={formObj.phnNum === "0000000000" ? '' : formObj.phnNum} onChange={(e) => {
                                                                    const phoneNum = e.target.value;
                                                                    setFormObj({
                                                                        ...formObj,
                                                                        phnNum: phoneNum
                                                                    });
                                                                    setFormObjError({
                                                                        ...formObjerror,
                                                                        phnNum: validatePhoneNumber(phoneNum) ? '' : 'Please enter a valid Mobile Number'
                                                                    });
                                                                }}
                                                                    error={!!formObjerror.phnNum} helperText={formObjerror.phnNum} />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <TextField label="Email Address" value={formObj.emailID.endsWith("@sas.com") ? '' : formObj.emailID} onChange={(e) => {
                                                                    const emailValue = e.target.value;
                                                                    setFormObj({
                                                                        ...formObj,
                                                                        emailID: emailValue
                                                                    });
                                                                    setFormObjError({
                                                                        ...formObjerror,
                                                                        emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                                                                    });
                                                                }}
                                                                    error={!!formObjerror.emailID} helperText={formObjerror.emailID} />
                                                            </Grid>
                                                        </> :
                                                        <>
                                                            <Grid item sm={6}>
                                                                <TextField required label="Phone number" value={formObj.phnNum} disabled />
                                                            </Grid>

                                                            <Grid item sm={6}>
                                                                <TextField required label="Email ID" value={formObj.emailID} disabled />
                                                            </Grid></>
                                                    }
                                                    {selectedRow.roletype_key === 'SAS_MEMBER' && (
                                                        <>
                                                            <Grid item sm={6}>
                                                                <TextField disabled select required label="Who did you lose to suicide?" value={formObj.relation} onChange={(e) => setFormObj({ ...formObj, relation: e.target.value })}>
                                                                    {rel.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                                                                </TextField>
                                                            </Grid>

                                                            {formObj.relation === 'Other' && (
                                                                <Grid item sm={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                                                                    <SubdirectoryArrowRightIcon />
                                                                    <TextField disabled label='Specify other relationship' value={formObj.otherRelation} onChange={(e) => setFormObj({ ...formObj, otherRelation: e.target.value })} />
                                                                </Grid>
                                                            )}

                                                            <Grid item sm={6}>
                                                                <TextField disabled type="date" label="Date you lost him/her" aria-readonly value={formatDateStr(formObj.lossDate)} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, lossDate: e.target.value })} inputProps={{ max: today }} />
                                                            </Grid>

                                                            <Grid item sm={6}>
                                                                <TextField disabled required label="Who supported you at that point of time?" value={formObj.support} onChange={(e) => setFormObj({ ...formObj, support: e.target.value })} />
                                                            </Grid>

                                                            <Grid item sm={6}>
                                                                <TextField disabled select required label='Did you take any professional help?' value={formObj.profHelp} InputLabelProps={{ shrink: true }} onChange={(e) => setFormObj({ ...formObj, profHelp: e.target.value })}>
                                                                    <MenuItem value={true}>Yes</MenuItem>
                                                                    <MenuItem value={false}>No</MenuItem>
                                                                </TextField>
                                                            </Grid>

                                                            <Grid item sm={6}>
                                                                <TextField disabled label="How did you hear about SNEHA-SAS?" value={formObj.ref} onChange={(e) => setFormObj({ ...formObj, ref: e.target.value })} />
                                                            </Grid>

                                                            <Grid item sm={6}> <TextField disabled select required label="Meeting" value={formObj.meeting}
                                                                onChange={(e) => setFormObj({ ...formObj, meeting: e.target.value })}>
                                                                {meetingData.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button sx={pBtn} disabled={selectedRow.roletype_key === undefined || selectedRow.roletype_key === 'SAS_MEMBER' ?
                                                            disableRegisterbutton || loading : disableuserRegisterbutton || loading} onClick={handleRegister}>Save{loading ? <CircularProgress /> : null}</Button>
                                                    </Grid>
                                                    {!selectedRow.is_Offline_member && <>
                                                        <Grid hidden={PerChk('RESET_PASSWORD')} item xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                        <Grid hidden={PerChk('RESET_PASSWORD')} item xs={12}>
                                                            <Typography variant='h6'>Reset Password</Typography>
                                                            <Typography variant='body1' sx={{ margin: '10px' }}>Do you want to reset this user password?<Link style={linkStyle} onMouseEnter={(e) => { e.target.style.textDecoration = 'underline'; e.target.style.color = 'black'; }} onMouseLeave={(e) => { e.target.style.textDecoration = 'none'; e.target.style.color = '#b5651d'; }} onClick={() => { setClickhereEnable(true); setResetfunc(true); setResetObj(''); setResetObjError('') }}> Click here</Link></Typography>
                                                            {resetfunc ?
                                                                <Box component={Grid} container spacing={2}>

                                                                    <Grid item sm={6}>
                                                                        <TextField required label="New Password" type="password" value={resetObj.newPassword} onChange={(e) => {
                                                                            const newPassword = e.target.value;
                                                                            setResetObj({
                                                                                ...resetObj,
                                                                                newPassword: newPassword
                                                                            });
                                                                            setResetObjError({
                                                                                ...resetObjerror,
                                                                                newPassworderror: validatePassword(newPassword)
                                                                            });
                                                                        }}
                                                                            error={!!resetObjerror.newPassworderror} helperText={resetObjerror.newPassworderror} />
                                                                    </Grid>

                                                                    <Grid item sm={6}>
                                                                        <TextField required label="Confirm Password" type="password" value={resetObj.confirmPassword} onChange={(e) => {
                                                                            const confirmPassword = e.target.value;
                                                                            setResetObj({
                                                                                ...resetObj,
                                                                                confirmPassword: confirmPassword
                                                                            });
                                                                            setResetObjError({
                                                                                ...resetObjerror,
                                                                                confirmPassworderror: validatePasswordsMatch(resetObj.newPassword, confirmPassword)
                                                                            });
                                                                        }}
                                                                            error={!!resetObjerror.confirmPassworderror} helperText={resetObjerror.confirmPassworderror} />
                                                                    </Grid>



                                                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                        <Button sx={{ marginRight: 1, ...nBtn }} disabled={resloading} onClick={() => { setResetfunc(false); setClickhereEnable(false) }}>Cancel</Button>
                                                                        <Button sx={pBtn} disabled={disableResetSubmitButton || resloading} onClick={handleResetPw}>Submit{resloading ? <CircularProgress /> : null}</Button>
                                                                    </Grid>

                                                                </Box> : null
                                                            }</Grid>
                                                    </>}
                                                    <Grid hidden={PerChk('DISABLE_USER')} item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid hidden={PerChk('DISABLE_USER')} item xs={12}>
                                                        <Typography variant='h6'>Disable User</Typography>
                                                        <Typography variant='body1'>Click Yes to disable the user.</Typography>
                                                    </Grid>
                                                    <Grid hidden={PerChk('DISABLE_USER')} item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button sx={nBtn} onClick={() => setDisableTableDialog(true)}>Yes</Button>
                                                    </Grid>
                                                    <Dialog open={disabletableDialog} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                                                        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                                                            <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                                                            <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setDisableTableDialog(false)} />
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <Typography variant='body1'>Are you sure you want to disable {formObj.name}?</Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button type="submit" disabled={disableloading}
                                                                variant="contained"
                                                                sx={nBtn} onClick={handleDisableRowClick}>Disable{disableloading ? <CircularProgress /> : null}</Button>
                                                        </DialogActions>
                                                        {userDisableFailed ? <Snackbar
                                                            open={userDisable}
                                                            autoHideDuration={5000}
                                                        >
                                                            <Alert
                                                                severity="error"
                                                                variant="filled"
                                                                sx={{ width: '100%' }}
                                                            >
                                                                {userDisableFailed}
                                                            </Alert>
                                                        </Snackbar> : <Snackbar
                                                            open={userDisable}
                                                            autoHideDuration={5000}
                                                        >
                                                            <Alert
                                                                severity="error"
                                                                variant="filled"
                                                                sx={{ width: '100%' }}
                                                            >
                                                                User disabled
                                                            </Alert>
                                                        </Snackbar>}
                                                    </Dialog>
                                                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} >
                                                        <Alert
                                                            onClose={() => setOpenSnackbar(false)}
                                                            severity="success"
                                                            variant="filled"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            Password changed for this user.
                                                        </Alert>
                                                    </Snackbar>

                                                    <Snackbar open={openSnackbarError} autoHideDuration={6000} onClose={() => setOpenSnackbarError(false)} >
                                                        <Alert
                                                            onClose={() => setOpenSnackbarError(false)}
                                                            severity="error"
                                                            variant="filled"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            {resetObjerror.resetbuttonerror}
                                                        </Alert>
                                                    </Snackbar>
                                                    {userUpdateFailed ? <Snackbar
                                                        open={userUpdated}
                                                        autoHideDuration={5000}
                                                    >
                                                        <Alert
                                                            severity="error"
                                                            variant="filled"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            {userUpdateFailed}
                                                        </Alert>
                                                    </Snackbar> : <Snackbar
                                                        open={userUpdated}
                                                        autoHideDuration={5000}
                                                    >
                                                        <Alert
                                                            severity="success"
                                                            variant="filled"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            User data updated
                                                        </Alert>
                                                    </Snackbar>}

                                                </> : <> <Grid hidden={PerChk('ENABLE_USER')} item xs={12}>
                                                    <Typography variant='h6'>Enable User</Typography>
                                                    <Typography variant='body1'>Click Yes to enable the user.</Typography>
                                                </Grid>
                                                    <Grid hidden={PerChk('ENABLE_USER')} item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button sx={pBtn} onClick={() => setEnableTableDialog(true)}>Yes</Button>
                                                    </Grid>
                                                    <Dialog open={enabletableDialog} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                                                        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                                                            <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                                                            <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setEnableTableDialog(false)} />
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <Typography variant='body1'>Are you sure you want to enable {formObj.name}?</Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button type="submit" disabled={loading}
                                                                variant="contained"
                                                                sx={pBtn} onClick={handleEnableRowClick}>Enable{loading ? <CircularProgress /> : null}</Button>
                                                        </DialogActions>
                                                        {userEnableFailed ? <Snackbar
                                                            open={userEnable}
                                                            autoHideDuration={5000}
                                                        >
                                                            <Alert
                                                                severity="error"
                                                                variant="filled"
                                                                sx={{ width: '100%' }}
                                                            >
                                                                {userEnableFailed}
                                                            </Alert>
                                                        </Snackbar> : <Snackbar
                                                            open={userEnable}
                                                            autoHideDuration={5000}
                                                        >
                                                            <Alert
                                                                severity="success"
                                                                variant="filled"
                                                                sx={{ width: '100%' }}
                                                            >
                                                                User enabled
                                                            </Alert>
                                                        </Snackbar>}

                                                    </Dialog>
                                                </>)}
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </>}
                    </>}
                </DialogContent>
            </Dialog>

            {/* PDF View */}
            {PDFView &&
                <Dialog open={PDFView} fullWidth
                    maxWidth='lg'
                    sx={{
                        '& .MuiDialog-paper': {
                            height: '100%',
                            maxHeight: '100%',
                            margin: 0,
                        },
                    }}
                >

                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                        <Grid container spacing={2} direction="row" style={{ alignItems: "center", display: "flex" }}>
                            <Grid item sm={11} style={{ display: "flex", justifyContent: "end" }}>
                                <Button type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, textTransform: 'none', backgroundColor: 'brown', '&:hover': { color: '#000', backgroundColor: '#c9762b' } }} onClick={downloadPdf} disabled={disableDownload}>Download PDF</Button>
                            </Grid>
                            <Grid item sm={1} style={{ display: "flex", justifyContent: "end" }}><CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => setPDFView(false)} /></Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent id='detailsPdf'>
                        <ThemeProvider theme={pdfTheme}><Box sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                            {/* <Box> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "left" }}>
                                    <img src='images/SnehaP.png' alt='Sneha SAS' style={{ width: '40%' }} />

                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow ? selectedRow.full_name : 'Tablelist'}</Typography>
                                </Grid>
                            </Grid>
                            {selectedRow && (
                                <Box component="main" id='html2pdf'>
                                    <Container component="main" maxWidth='100%' className="html2pdf__page-break">
                                        <Grid container spacing={2}>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Name</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{selectedRow.full_name}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Phone number</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{selectedRow.mobile_number === "0000000000" ? '-' : selectedRow.mobile_number}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Email ID</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all', flowWrap: 'break-word', }}>{selectedRow.email_id.endsWith("@sas.com") ? '-' : selectedRow.email_id}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{formatDate(selectedRow.date_of_birth)}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Status</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{
                                                textTransform: 'none',
                                                color:
                                                    selectedRow.status === 'Active'
                                                        ? 'green'
                                                        : selectedRow.status === 'Rejected'
                                                            ? 'red'
                                                            : 'darkblue',
                                            }}>
                                                <Typography>{selectedRow.status}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Remarks</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {selectedRow.remarks === "" || selectedRow.remarks === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.remarks}</Typography>}

                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Gender</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{selectedRow.gender}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Postal address</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {selectedRow.address === "" || selectedRow.address === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.address}</Typography>}

                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Role</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {selectedRow.roletype_key === "" || selectedRow.roletype_key === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.roletype_key}</Typography>}
                                            </Grid>

                                            {registrationData && <><Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who did you lose to suicide</Typography>
                                            </Grid>
                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{registrationData && registrationData.relationship === "Other" ? `${registrationData.other_relationship}` : registrationData.relationship}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date you lost him/her</Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{registrationData && registrationData.date_you_lost_them ? formatDate(registrationData.date_you_lost_them) : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who supported you at that point of time</Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{registrationData && registrationData.supported_person ? registrationData.supported_person : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Did you take any professional help</Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{registrationData && registrationData.any_proffessional_help ? "Yes" : "No"}</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>How did you hear about SNEHA-SAS</Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{registrationData && registrationData.how_you_know_SAS ? registrationData.how_you_know_SAS : "-"}</Typography>

                                                </Grid></>}
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Session preference</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{registrationData && registrationData.support_via ? registrationData.support_via : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Notes</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {selectedRow.notes === "" || selectedRow.notes === undefined ? <Typography>-</Typography> : <Typography>{selectedRow.notes}</Typography>}

                                            </Grid>
                                        </Grid>
                                    </Container></Box>

                            )}
                            {registrationData && phqAnsData.length > 0 && <Box component="main" id='phqPdf'>
                                <Container component="main" maxWidth='100%' className="html2pdf__page-break">
                                    {phqAnsData.length > 0 && <TableContainer >
                                        <Table sx={{ width: '100%' }} aria-label="custom pagination table">
                                            <TableHead sx={{ bgcolor: '#a5644e' }}>
                                                <TableRow>
                                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Question</TableCell>
                                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PHQ Response</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {questionList.map((questionrow) => (
                                                    <TableRow>
                                                        <TableCell sx={{ textTransform: 'none', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }} component="th" scope="row">
                                                            {questionrow.question}
                                                        </TableCell>
                                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                                            {anscode[phqAnsData[0].response.filter(x => x.question_id == questionrow._id)[0].score]}<br />
                                                            Score - {phqAnsData[0].response.filter(x => x.question_id == questionrow._id)[0].score}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">

                                                    </TableCell>
                                                    <TableCell sx={{ textTransform: 'none', fontWeight: "700" }} component="th" scope="row" >
                                                        Total Score - {phqAnsData[0].response.reduce((sum, item) => sum + item.score, 0)}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2} sx={{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                                        {phqAnsData.length > 0 ?
                                                            <Typography sx={{ marginTop: '15px' }} variant='body1'>If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people - <span style={{ fontWeight: '700' }}>{difficultanscode[phqAnsData[0].difficultyScore]}</span></Typography> : null}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>}
                                </Container>
                            </Box>}
                        </Box ></ThemeProvider >
                    </DialogContent>
                </Dialog>
            }
        </Container>
    </Box>
    );
}





