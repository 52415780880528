import React, { useState, useEffect } from 'react';
import { Typography, Card, Grid, useMediaQuery, Dialog, DialogTitle, DialogContent, TablePagination, FormControl, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { usersList } from '../services/UserService';
import { ListMedia } from '../services/MediaService';
import { useTheme } from '@mui/material/styles';
import { txtElp, trClose, FormatDT, TPA, modCard } from '../common/Common';

export default function Media() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [mediaModal, setmediaModal] = useState(false);
  const [mediaObj, setmediaObj] = useState({});
  const [mediaList, setmediaList] = useState([]);
  const [dcSumm, setdcSumm] = useState('');
  const [uList, setuList] = useState([]);
  const [searchFilter, setsearchFilter] = useState('');
  const [page, setpage] = useState(0);
  const rowsPerPage = 3 * (isMobile ? 1 : 3);
  const sfTLC = searchFilter?.toLowerCase();
  const mediaListF = mediaList.filter((m) => {
    const formattedDateString = FormatDT(m.created_at);
    const createdByUser = uList.find((u) => u._id === m?.media_createdby)?.full_name?.toLowerCase();
    return (
      (m.media_title?.toLowerCase().includes(sfTLC) ||
        formattedDateString?.toLowerCase().includes(sfTLC) ||
        (createdByUser && createdByUser.includes(sfTLC)))
    );
  }); const mediaListP = mediaListF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    const FetchMedia = async () => {
      try {
        const resp1 = await ListMedia(); if (resp1) {
          setmediaList(resp1.result.filter(m => m.archive === false && m.media_status === 'Published').reverse());
        }
        const resp2 = await usersList(); if (resp2) {
          setuList(resp2.result.filter(user => user.status === 'Active'));
        }
      } catch (error) {
        console.error(error);
      }
    }; FetchMedia();
  }, [])

  return (<Card sx={{ minHeight: '75vh' }}>
    <Grid container spacing={2}>
      {mediaList.length > 0 && <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <FormControl sx={{ width: '150px' }}><TextField value={searchFilter} size='small'
          onChange={(e) => setsearchFilter(e.target.value)} label='Search' /></FormControl>

        <TablePagination count={mediaListF.length} page={page} rowsPerPage={rowsPerPage} ActionsComponent={TPA} rowsPerPageOptions={[]}
          sx={{ border: 'none', '.MuiTablePagination-displayedRows': { display: 'none' } }} onPageChange={(e, p) => setpage(p)} />
      </Grid>}

      {mediaListP.length > 0 ?
        mediaListP.map((m, i) => (<Grid item xs={isMobile ? 12 : 4} key={i}><Card onClick={() => { setmediaObj(m); setdcSumm(decodeURIComponent(atob(m.media_summary))); setmediaModal(true); }} sx={modCard}>
          <Typography variant='h6' sx={txtElp}>{m.media_title}</Typography>
          <Typography color="text.secondary">Posted by <b>{uList.find((u) => u._id === m?.media_createdby)?.full_name}</b></Typography>
          <Typography color="text.secondary">Posted on <b>{FormatDT(m.created_at)}</b></Typography>
        </Card></Grid>))
        :
        <Grid item xs={12}><Typography variant='h6' sx={{ textAlign: 'center' }}>
          {searchFilter ? 'No results for your search' : 'No media to display'}</Typography></Grid>}
    </Grid>

    <Dialog fullWidth open={mediaModal}>
      <Close sx={trClose} onClick={() => { setmediaObj({}); setmediaModal(false); }} />

      <DialogTitle sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', justifyContent: 'space-between', gap: '4px', mt: '24px', mb: '4px', borderBottom: '1px solid #b5651d' }}>
        <Typography variant='h6' sx={{ mb: 0, overflowWrap: 'break-word', width: '70%' }}>{mediaObj?.media_title}</Typography>
        <Typography sx={{ color: 'GrayText', overflowWrap: 'break-word'}}>{uList.find((u) => u._id === mediaObj?.media_createdby)?.full_name} | {FormatDT(mediaObj?.created_at)}</Typography>
      </DialogTitle>

      <DialogContent><Card><Typography dangerouslySetInnerHTML={{ __html: dcSumm }} /></Card></DialogContent>
    </Dialog>
  </Card>)
}