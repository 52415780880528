import axios from "axios";

export async function addRegistration(data) {
    const configuration = {
        url: process.env.SERVER + 'registration/add',
        method: 'post',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function registrationVerification(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/registrationVerification',
        method: 'put',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function regList() {
    const configuration = {
        url: process.env.SERVER + 'registration/list',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'get'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditRegistration(Id, data) {
    const configuration = {
        url: process.env.SERVER + `registration/${Id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}