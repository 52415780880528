import * as React from 'react';
import { Typography, Box, Grid, List, ListItem, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';

export default function Survivors() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (<Card><Grid container spacing={2}>
        <Grid item xs={12}><Typography variant='h6'>About Survivors</Typography></Grid>

        <Grid item xs={isMobile ? 12 : 8}>
            <Typography sx={{ mb: '30px' }}>
                One strong definition of a Survivor is given as -<span style={{ color: '#b5651d' }}>"...someone who experiences a high level of self-perceived psychological, physical, and/or social distress for a considerable length of time after exposure to the suicide of another person”</span>  (Jordan & McIntosh, 2011a, p.7).
            </Typography>
            <Typography sx={{ mb: '30px' }}>
                This definition is apt, since the loss need not be that of a relation, friend or even someone known to the Survivor. More important is how the loss of a person is perceived by the Survivor to have affected him/herself, even after the passage of considerable time.
            </Typography>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ mb: '30px' }}>
                    Following are several aspects of death by suicide that influence the experience of Survivors.
                    <List>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Stigma associated with suicide</ListItem>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />General thought that suicide can be prevented leading up to various emotions against the deceased and the Survivor themselves.</ListItem>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Trying to analyse the mental anguish of the deceased leading up to suicide</ListItem>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Trauma resulting from being a direct witness to the suicide</ListItem>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Shocking nature of suicide and disbelief associated with it</ListItem>
                        <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Emotions such as shame, blame, anger, guilt and fear</ListItem>
                    </List>
                </Typography>
            </Grid>
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
            <Card sx={{ bgcolor: 'white', m: 0, p: 0 }}>
                <CardMedia
                    component="img"
                    height="200"
                    image='images/Refer (1).jpg'
                    alt="Refer (1)"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold' }} component="div">
                        What can help Survivors?
                    </Typography>
                    <Typography variant="body2" sx={{ mb: '20px' }}>
                        As Survivors are trying to deal with the loss close to them, there are some measures which can help them in this regard:
                        <List>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Caring help from first responders</ListItem>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Sharing useful information</ListItem>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Practical help from communities and social networks</ListItem>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Trauma resulting from being a direct witness to the suicide</ListItem>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Help from mental health professionals</ListItem>
                            <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Support from peers and family members</ListItem>
                        </List>
                    </Typography>
                </CardContent>
            </Card>
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}><Card sx={{ bgcolor: 'white', m: 0, p: 0 }}>
            <CardMedia
                component="img"
                height="200"
                image='images/Refer (2).jpg'
                alt="Refer (2)"
            />
            <CardContent><Grid container spacing={2}>
                <Grid item xs={12}><Typography variant="body2">Most survivors go through strong emotions after losing a loved one. These emotions could be:</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Anger</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Anxiety</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Crying spells</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Fatigue</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"> <CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Lack of energy</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Guilt</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"> <CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Loneliness</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Pain</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Sadness</Typography></Grid>
                <Grid item xs={12}><Typography variant="body2"> <CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Trouble sleeping</Typography></Grid>
            </Grid></CardContent>
        </Card></Grid>

        <Grid item xs={isMobile ? 12 : 8}>
            <Typography sx={{ fontWeight: 'bold', mb: '20px' }}>Signs of a Survivor</Typography>
            <Typography sx={{ mb: '20px' }}>
                Survivors live with many emotions and feelings after losing someone to suicide. When the incident is fresh, the shock and pain of losing someone is deep seated in the mind of the Survivor. They spend their time thinking about the deceased or talking about them and trying to cope with the loss.
            </Typography>
            <Typography sx={{ mb: '20px' }}>Grief doesn't just stop as time passes. Things that remind us of them can bring back the sadness, even years later. Sometimes, these feelings of sadness might come back suddenly, for example, on the anniversary of their death or during special occasions.</Typography>
            <Typography sx={{ mb: '20px' }}>
                Reminders of our loved ones can be anywhere. Some reminders, like visiting their grave or their birthday, are expected. But other things, like hearing their favourite song or smelling their perfume, can catch us off guard and make us sad.
            </Typography>
            <Typography >
                When grief comes back, it can last for a few minutes or a few days. During this time, we might feel the same emotions we felt when we first lost our loved one, like sadness, anger, or confusion.
            </Typography>
            {/* <Typography sx={{ mb: '30px' }}>
                            Most survivors go through strong emotions after losing a loved one. These emotions could be:
 */}


            {/* <List>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Anger.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Anxiety.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Crying spells.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Fatigue.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Lack of energy. </ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Guilt.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Loneliness.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Pain.</ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Sadness. </ListItem>
                                <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Trouble sleeping.</ListItem>
                            </List> */}
            {/* </Typography> */}
            {/* <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item>
                                <DemoPaper square={false}>😡 Anger</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😰 Anxiety</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😢 Crying spells</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😴 Fatigue</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>💤 Lack of energy</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😔 Guilt</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😔 Loneliness</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😖 Pain</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😢 Sadness</DemoPaper>
                            </Grid>
                            <Grid item>
                                <DemoPaper square={false}>😴 Trouble sleeping</DemoPaper>
                            </Grid>
                        </Grid> */}
        </Grid>

        <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', mb: '20px' }}>
                Living with grief
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Losing someone you really care about hurts a lot. It can make you feel sad and overwhelmed with emotions. Sometimes it seems like the sadness will never go away. These feelings are normal when someone you love passes away. But there are ways to deal with the pain that can make it a bit easier to handle:
                <List>
                    <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Remember that everyone grieves differently - How you deal with sadness after losing someone depends on lots of things, like how you usually handle tough situations, what you've been through before, and what you believe</ListItem>
                    <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Feeling better takes time. You can't rush or push yourself to get over it quickly—and there's no set schedule for feeling sad. It's important to give yourself time and be patient. </ListItem>
                    <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Take care of yourself and don't hesitate to ask for support. Taking care of your body and your feelings can make it easier to handle this tough situation.</ListItem>
                    <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />It might take a while to feel better after losing someone you care about, but studies show it can also help you find new meaning and purpose in life.</ListItem>
                </List>
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                What you can do to help yourself:
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Take care of your physical health – Try to maintain a healthy lifestyle. Grieving could take a toll on your and staying healthy will help you handle this. Eat healthy, good food, exercise regularly, get plenty of fresh air and try to sleep well.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Manage stress – Do not hesitate to as for help especially to handle work pressure or chores at home. People are ready to help and only need to be asked.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Don't pressure yourself to feel a certain way either. Just let your feelings be what they are - Let yourself feel whatever you feel without embarrassment or judgement. It’s okay to be angry, to cry, not cry, or even laugh.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Take support – Lean on friends and family members to help you get through this difficult time. Talk to those closest to you, or consider joining a support group.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Draw comfort from your faith – Sometimes, spiritual activities may offer a sense of peace. Try something meaningful to you such as praying, meditating, or going to a place of worship.                            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Talk about the death of your loved one – Don’t keep your memories bottled up. Openly talk about your loved one and share your memories. This could help you remember them and slowly accept what happened.                             </Typography>
            <Typography sx={{ mb: '20px' }}>
                Celebrate the lives of your loved one – Consider doing an activity that meant a lot to your loved one. This could be donating your time or money to a social cause, creating a scrap book of your memorable times, visiting favourite haunts or more.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Accept and manage difficult feelings – When you feel strong emotions rising, stay calm. Take a moment to acknowledge what is happening. Breathe, try to sit calmly and express your feelings to someone close to you. Identify an activity that could help you in such times – listening to music, watching a show, taking a walk, dancing, painting etc.                             </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', mb: '20px' }}>
                Living with reawakened grief
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                There is no time frame for someone to be affected by the loss of a loved one to suicide. Even years after the event, a Survivor can continue to feel sad when remembering the loss. There are some ways by which a Survivor can cope during such times:
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Be Ready – Understanding that grief reactions can affect you anytime will help you be prepared for such times. It also helps to know that these are temporary events and you will be able to continue your normal life soon.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Plan a distraction – Some events or special days are often trigger points for many Survivors. Planning a family gathering or an outing with friends can help mitigate feelings of loss or sadness during such times.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Talk about your relationship – Don’t hold back on your memories of your loved one. Talk about the good and bad times, look at photos or even write a letter to them in your journal.                            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Do not isolate yourself – It is good to talk to or visit people who knew your loved one. Swapping stores with those who will encourage you to talk will help you understand your feelings and handle them too. Support groups are also helpful.
            </Typography>
            <Typography sx={{ mb: '20px' }}>
                Acknowledge your feelings – You could be feeling sad, angry at your loved one, lonely and so much more. Allow yourself to feel a range of emotions, including joy and happiness.                            </Typography>
        </Grid>
    </Grid></Card>)
}
