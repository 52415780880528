import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import {
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, MenuItem,
    FormControl, Snackbar, Alert,
    Select, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Toolbar, Tab, Tabs, useMediaQuery, TextField, Link, Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { regList } from '../../../services/RegistrationService';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { userList, verifyUser } from '../../../services/UserService';
import { formatDate, setTimeoutsecs, pBtn, nBtn, PerChk, TPA } from '../../../common/Common';
import CircularProgress from '@mui/material/CircularProgress';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function CustomPaginationActionsTable() {
    const userId = sessionStorage.getItem('userId');
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [userData, setUserData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [registrationData, setRegistrationData] = useState('');
    const [approvalAlert, setApprovalAlert] = useState(false);
    const [approveFailed, setApproveFailed] = useState('');
    const [rejectAlert, setRejectAlert] = useState(false);
    const [rejectFailed, setRejectFailed] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [remarks, setRemarks] = useState(false);
    const [remarksfield, setRemarksfield] = React.useState('');
    const disableRemarks = !remarksfield;

    const handleRowClick = async (row) => {
        setSelectedRow(row);
        setExpanded(true);
        try {
            let regdata = {
                user_id: row._id
            }
            let resp = await regList();
            setRegistrationData(resp.result.filter(x => x.user_id == row._id)[0]);
        } catch (error) {

            console.log(error)
        }
    };

    const handleapproveUser = async (user) => {
        setLoading(true);
        try {
            let data = {
                email_id: user.email_id,
                decision: "Approve",
                verified_by: userId
            }
            let resp = await verifyUser(data);
            setApprovalAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setExpanded(false);
                    fetchFileData();
                    setApprovalAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setApproveFailed(error.response.data.message);
                setApprovalAlert(true);
            }

            else {
                console.log("An unexpected error occurred:", error.message);
                setApproveFailed('Approve failed');
                setApprovalAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setExpanded(false);
                fetchFileData();
                setApprovalAlert(false);
            }, setTimeoutsecs);
        }
    }

    const handleRejectUser = async (user) => {
        setLoading(true);
        try {
            let data = {
                email_id: user.email_id,
                decision: "Reject",
                remarks: remarksfield,
                verified_by: userId
            }
            let resp = await verifyUser(data);
            setRejectAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setRemarks(false);
                    fetchFileData();
                    setRejectAlert(false);
                    setRemarksfield('');
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setRejectFailed(error.response.data.message);
                setRejectAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setRejectFailed('Rejection failed');
                setRemarksfield('');
                setRejectAlert(true);
            } setTimeout(() => {
                setLoading(false);
                setRemarks(false);
                fetchFileData();
                setRejectAlert(false);
            }, setTimeoutsecs);
        }
    }

    const fetchFileData = async () => {
        try {
            let resp = await userList();
            const filteredList = resp.result.filter(x => x.status == 'Pending');
            setUserData(filteredList.reverse());
        } catch (error) {

            console.log(error)
        }
    };
    useEffect(() => {
        fetchFileData();
    }, []);

    const filteredData = userData.filter(user => {
        const Dob = formatDate(user.date_of_birth).toLowerCase();
        const searchQueryLower = searchQuery.toLowerCase();

        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });
        const matchesDOBDate = Dob.includes(searchQueryLower);

        return matchesSearchQuery || matchesDOBDate;
    });

    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container component="main" sx={{ width: '80vw' }}>

            <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                    {userData.length > 0 && <>
                        <FormControl sx={{ width: '130px' }}>
                            <TextField
                                label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl> </>}
                </Box >

            </Box>

            {filteredData.length > 0 ?
                < Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                                <TableRow sx={{ alignItems: 'center' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email Id</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '11%' }}>DOB</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Address</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredData
                                ).map((row) => (
                                    <TableRow key={row.email_id} onClick={() => handleRowClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#fcf2e7',
                                            },
                                        }}>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.full_name}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row" >
                                            {row.email_id}
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            {row.mobile_number}
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            {formatDate(row.date_of_birth)}
                                        </TableCell>
                                        {row.address === "" || row.address === undefined ?
                                            <TableCell sx={{ textTransform: 'none', whiteSpace: 'pre-line' }} component="th" scope="row" >
                                                -</TableCell> : <TableCell sx={{ textTransform: 'none', whiteSpace: 'pre-line' }} component="th" scope="row" >
                                                {row.address}
                                            </TableCell>}
                                        <TableCell
                                            sx={{
                                                textTransform: 'none',
                                                color:
                                                    row.status === 'Active'
                                                        ? 'green'
                                                        : row.status === 'Rejected'
                                                            ? 'red'
                                                            : row.status === 'Pending' ? 'darkblue'
                                                                : '#d3d3d3',
                                            }}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.status}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={userData.length}
                                        page={page} rowsPerPage={rowsPerPage}
                                        sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                        onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                        ActionsComponent={TPA}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}
            {selectedRow && <>
                <Dialog open={expanded} fullWidth
                    maxWidth='lg' >
                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                        <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Confirmation</Typography>
                        <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setExpanded(false)} />
                    </DialogTitle>
                    <DialogContent sx={{ marginTop: '15px' }}>
                        <Container component="main" maxWidth='100%'>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Name</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.full_name}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Phone number</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.mobile_number}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Email ID</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.email_id}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{formatDate(selectedRow.date_of_birth)}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Status</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.status}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Gender</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.gender}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Postal address</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{selectedRow.address ? selectedRow.address : "-"}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who did you lose to suicide</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Typography>{registrationData && registrationData.relationship === "Other" ? `${registrationData.relationship} - ${registrationData.other_relationship}` : registrationData.relationship}</Typography> */}
                                    <Typography>{registrationData && registrationData.relationship === "Other" ? `${registrationData.other_relationship}` : registrationData.relationship}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Date you lost him/her</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{registrationData.date_you_lost_them ? formatDate(registrationData.date_you_lost_them) : "-"}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Who supported you at that point of time</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{registrationData.supported_person}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Did you take any professional help</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {registrationData ? (registrationData.any_proffessional_help ? <Typography>Yes</Typography> : <Typography>No</Typography>) : "-"}

                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>How did you hear about SNEHA-SAS</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{registrationData.how_you_know_SAS ? registrationData.how_you_know_SAS : "-"}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Session preference</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{registrationData.support_via}</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>

                    <DialogActions>
                        <Button hidden={PerChk('REJECT_USER')} type="button"
                            variant="contained" disabled={loading}
                            sx={nBtn} onClick={() => { setExpanded(false); setRemarks(true) }}>Reject</Button>
                        <Button hidden={PerChk('APPROVE_USER')} type="submit"
                            variant="contained" disabled={loading}
                            sx={pBtn} onClick={() => handleapproveUser(selectedRow)}>Approve{loading ? <CircularProgress /> : null}</Button>
                    </DialogActions>
                    {approveFailed ? <Snackbar
                        open={approvalAlert}
                        autoHideDuration={5000}
                    >
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {approveFailed}
                        </Alert>
                    </Snackbar> : <Snackbar
                        open={approvalAlert}
                        autoHideDuration={5000}
                    >
                        <Alert
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            User approved
                        </Alert>
                    </Snackbar>}

                </Dialog>
            </>}
            <Dialog open={remarks} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Remarks</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => { setRemarks(false); setRemarksfield('') }} />
                </DialogTitle>
                <DialogContent>
                    <TextField margin="normal" required label="Remarks" autoFocus value={remarksfield}
                        onChange={(e) => setRemarksfield(e.target.value)} />

                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={disableRemarks || loading}
                        variant="contained"
                        sx={nBtn} onClick={() => handleRejectUser(selectedRow)}>Submit{loading ? <CircularProgress /> : null}</Button>
                </DialogActions>

                {rejectFailed ? <Snackbar
                    open={rejectAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {rejectFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={rejectAlert}
                    autoHideDuration={5000}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        User rejected
                    </Alert>
                </Snackbar>}

            </Dialog>
        </Container>
    </Box>
    );
}





