import React from 'react';
import { Typography, Card, Link, Box } from '@mui/material';


export default function Sneha() {
  return (<Card>
    <Typography variant="h6">About Sneha</Typography>

    <Typography sx={{ mb: '20px' }}>
      SNEHA is a suicide prevention organization based in Chennai, India. We offer unconditional emotional
      support to anyone who could be feeling distressed, depressed or suicidal. No matter the situation or
      issue, SNEHA offers callers an open space to talk about anything that burdens or weighs them down.
      We believe that talking things through with someone who is caring and non-judgmental could help a person
      cope with his distress; that talking things through could give the person the respite and maybe,
      the energy, to make a sound decision and move on.
    </Typography>

    <Typography sx={{ mb: '20px' }}>
      SNEHA, run by a small band of volunteers, offers unconditional emotional support, 24-hours, 365 days
      a year, to people who are feeling upset, distressed, depressed and suicidal. <b>Our services are completely free of charge.</b>
    </Typography>

    <Typography sx={{ mb: '20px' }}>
      The volunteers remain anonymous and offer total confidentiality and anonymity to those who contact them. The underlying belief of the service is that emotional pain of a human being can be alleviated by another, by conveying compassion, acceptance, respect and empathy through listening.
    </Typography>

    <Typography sx={{ mb: '20px' }}>
      Every SNEHA volunteer is there for the person in distress, to travel with him/her on their painful emotional journey, and wait alongside patiently, providing emotional support, until the person is ready to move on and re-establish a link with life.
      The role of a SNEHA volunteer is to provide emotional support in the form of non-judgmental and uncritical listening in complete confidentiality. This could give relief and much-needed respite to a person burdened with worries, fears and concerns that are weighing him/her down.
    </Typography>

    <Typography sx={{ mb: '20px' }}>
      Services are totally free.
      If you are interested in knowing more about SNEHA, you can visit our website at <Link href="http://www.snehaindia.org" style={{ fontWeight: 'bold', fontSize: '18px' }}>www.snehaindia.org</Link>.
    </Typography>
  </Card>)
}
