import React, { useState, useEffect } from 'react';
import { Card, Typography, Grid, Toolbar, Tab, Tabs, Dialog, DialogTitle, DialogContent, Divider, TablePagination, useMediaQuery, FormControl, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { tabThemeAdmin } from './TabsCore';
import { ListMtg } from '../services/MeetingService';
import { trClose, TPA, FormatDT, txtElp, modCard } from '../common/Common';

export default function MyMeet() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const userId = sessionStorage.getItem('userId');
    const [pastMeets, setPastMeets] = useState([]);
    const [upcomingMeets, setUpcomingMeets] = useState([]);
    const [rptModal, setRptModal] = useState(null);
    const [ti, setti] = useState('0');
    const [page, setpage] = useState(0);
    const rowsPerPage = 3 * (isMobile ? 1 : 3);
    const [searchFilter, setsearchFilter] = useState('');
    const sfTLC = searchFilter?.toLowerCase();
    const pastF = pastMeets.filter((r) => {
        return (
            r.title?.toLowerCase().includes(sfTLC) ||
            FormatDT(r.date_time)?.toLowerCase().includes(sfTLC) ||
            r.type?.toLowerCase().includes(sfTLC) ||
            r.link?.toLowerCase().includes(sfTLC) ||
            r.venue?.toLowerCase().includes(sfTLC) ||
            r.total_participants.length.toString().includes(sfTLC) ||
            r.description?.toLowerCase().includes(sfTLC) ||
            r.summary?.toLowerCase().includes(sfTLC)
        )
    }); const pastP = pastF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const upcomingF = upcomingMeets.filter((r) => {
        return (
            r.title?.toLowerCase().includes(sfTLC) ||
            FormatDT(r.date_time)?.toLowerCase().includes(sfTLC) ||
            r.type?.toLowerCase().includes(sfTLC) ||
            r.link?.toLowerCase().includes(sfTLC) ||
            r.venue?.toLowerCase().includes(sfTLC) ||
            r.total_participants.length.toString().includes(sfTLC) ||
            r.description?.toLowerCase().includes(sfTLC)
        )
    }); const upcomingP = upcomingF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        const fetchMeetings = async () => {
            try {
                const resp = await ListMtg();
                if (resp) {
                    const myMeets = resp.result.filter(report => report.total_participants.includes(userId));
                    setPastMeets(myMeets.filter(meet => new Date(meet.date_time) < new Date()));
                    setUpcomingMeets(myMeets.filter(meet => new Date(meet.date_time) >= new Date()));
                }
            } catch (error) {
                console.log(error);
            }
        }; fetchMeetings();
    }, []);

    const Rpt = ({ report }) => (<Grid item xs={isMobile ? 12 : 4}><Card onClick={() => setRptModal(report)} sx={modCard}>
        <Typography variant='h6'>{report.title}</Typography>
        <Typography sx={{ mb: '5px', color: 'GrayText' }}>{FormatDT(report.date_time)} | {report.type}: {report.link || report.venue}</Typography>
        <Typography variant='body2' sx={txtElp}>{report.description}</Typography>
    </Card></Grid>)

    return (<Card sx={{ minHeight: '75vh' }}>
        <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 6}><Toolbar><ThemeProvider theme={tabThemeAdmin}><Tabs value={ti} onChange={(e, v) => { setti(v); setpage(0); setsearchFilter(''); }}>
                <Tab value="0" label="Upcoming meetings" />
                <Tab value="1" label="Past meetings" />
            </Tabs></ThemeProvider></Toolbar></Grid>

            {((ti === '0' && upcomingMeets.length > 0) || (ti === '1' && pastMeets.length > 0)) && <Grid item xs={isMobile ? 12 : 6}
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <FormControl sx={{ width: '150px' }}><TextField label='Search' value={searchFilter}
                    size='small' onChange={(e) => setsearchFilter(e.target.value)} /></FormControl>

                <TablePagination count={ti === '0' ? upcomingF.length : pastF.length} page={page} rowsPerPage={rowsPerPage} ActionsComponent={TPA}
                    sx={{ border: 'none', '.MuiTablePagination-displayedRows': { display: 'none' } }} onPageChange={(e, p) => setpage(p)} rowsPerPageOptions={[]} />
            </Grid>}

            {(ti === '0' && upcomingP.length > 0) ? <>{upcomingP.map((r, i) => (<Rpt key={i} report={r} />))}</>
                : (ti === '1' && pastP.length > 0) ? <>{pastP.map((r, i) => (<Rpt key={i} report={r} />))}</>
                    : <Grid item xs={12}><Typography variant='h6' sx={{ textAlign: 'center' }}>
                        {searchFilter ? 'No results for your search' : 'No meetings to display'}</Typography></Grid>}
        </Grid>

        <Dialog fullWidth open={rptModal}>
            <Close sx={trClose} onClick={() => setRptModal(null)} />

            <DialogTitle sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mt: '10px', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6' sx={{ mb: 0 }}>{rptModal?.title}</Typography>
            </DialogTitle>

            <DialogContent><Card>
                <Typography><b>Type</b>: {rptModal?.type}</Typography>

                <Typography>{rptModal?.type === 'Face-to-Face' && <><b>Venue: </b>{rptModal?.venue}</>}
                    {rptModal?.type === 'Online' && <><b>Link: </b><a href={rptModal?.link}>{rptModal?.link}</a></>}</Typography>

                <Typography><b>Date & time</b>: {FormatDT(rptModal?.date_time)}</Typography>

                <Divider sx={{ mt: 1, mb: 2, border: '1px solid #b5651d' }} />

                <Typography variant='body2' style={{ display: 'block', wordBreak: 'break-word' }}>{rptModal?.description}</Typography>
            </Card> </DialogContent>
        </Dialog>
    </Card>)
}