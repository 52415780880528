import React, { useState } from 'react';
import { Toolbar, Tab, Tabs, useMediaQuery, Box } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import ExpList from '../MyContent/ExpList';
import RejectedList from '../MyContent/RejectedList'
import { mainTheme, tabThemeAdmin } from '../../core/TabsCore';
import { PerChk } from '../../common/Common';

export default function ExpTab() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tabIndex, settabIndex] = useState(PerChk('MYCONTENT_EXPLIST') ? '1' : '0')

    return (<ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', flexDirection: 'column' }}>

        <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right', bgcolor: '#1px solid grey', p: 2, height: '20px', position: 'sticky', top: 0, zIndex: 99 }}>
            <ThemeProvider theme={tabThemeAdmin}>
                <Tabs value={tabIndex} onChange={(e, ti) => settabIndex(ti)} sx={{ width: '100%' }}>
                    <Tab value="0" label="Experience List" hidden={PerChk('MYCONTENT_EXPLIST')} />
                    <Tab value="1" label="Rejected" hidden={PerChk('MYCONTENT_EXP_REJECTEDLIST')} />
                </Tabs>
            </ThemeProvider>
        </Toolbar>

        <Box>
            {(tabIndex === '0' && !PerChk('MYCONTENT_EXPLIST')) && <ExpList />}
            {(tabIndex === '1' && !PerChk('MYCONTENT_EXP_REJECTEDLIST')) && <RejectedList />}
        </Box>
    </Box ></ThemeProvider >)
}