import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, createTheme, styled } from '@mui/material/styles';
import {
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination,
    TableRow, Paper, FormControl, Snackbar, Alert, useMediaQuery, TextField, Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { setAutoHideDurationTimeoutsecs, TPA } from '../../../common/Common';
import { getGroupedData, getTotalCount, getUniqueCount } from '../../../services/VisitorService';

export const mainTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiTextField: { styleOverrides: { root: { marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
        MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
        MuiInputBase: { styleOverrides: { root: { backgroundColor: '#ffffff' } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
        MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } }
    }
})

const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 220,
    height: 100,
    backgroundColor: '#fcf2e7',
    textAlign: 'center',
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function VisitorCount({ setRefresh }) {
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [visitorList, setVisitorList] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalCount, setTotalCount] = React.useState(0);
    const [uniqueCount, setUniqueCount] = React.useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [message, setMessage] = useState(false);
    const [severityColor, setSeverityColor] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleRowClick = async (row) => {
        setSelectedRow(row);
    };

    const fetchVisitorList = async () => {
        try {
            let resp = await getGroupedData();
            setVisitorList(resp.result);
        } catch (error) {

            console.log(error)
        }
    };

    const fetchTotalCount = async () => {
        try {
            let resp = await getTotalCount();
            setTotalCount(resp.totalVisitorCount);
        } catch (error) {

            console.log(error)
        }
    };

    const fetchUniqueCount = async () => {
        try {
            let resp = await getUniqueCount();
            setUniqueCount(resp.totalVisitorCount);
        } catch (error) {

            console.log(error)
        }
    };

    useEffect(() => {
        fetchVisitorList();
        fetchTotalCount();
        fetchUniqueCount();
    }, []);

    const filteredData = visitorList.filter(user => {
        const matchesSearchQuery = Object.values(user).some(value => {
            if (typeof value === 'number') {
                return value.toString().includes(searchQuery);
            } else if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });

        return matchesSearchQuery;
    });

    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 2 : 6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <DemoPaper>
                <Typography variant='h6' textAlign='center'>Total visits</Typography>
                <Typography variant='h6' textAlign='center' sx={{ color: 'GrayText' }}>{totalCount}</Typography>
            </DemoPaper>
            <DemoPaper>
                <Typography variant='h6' textAlign='center'>Distinct visits</Typography>
                <Typography variant='h6' textAlign='center' sx={{ color: 'GrayText' }}>{uniqueCount}</Typography>
            </DemoPaper>
        </Stack>
        <Container component="main" >
            <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                    <FormControl sx={{ width: '130px' }}>
                        <TextField
                            label="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </Box >
            </Box>

            {filteredData.length > 0 ? <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer><Table>
                    <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                        <TableRow sx={{ alignItems: 'center' }}>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Year</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Total</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Distinct</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : filteredData
                        ).map((row) => (
                            <TableRow>
                                <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row">
                                    {row.year}
                                </TableCell>
                                <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row" >
                                    {row.totalCount}
                                </TableCell>
                                <TableCell sx={{ textTransform: 'none', textAlign: 'center' }} component="th" scope="row" >
                                    {row.uniqueCount}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={filteredData.length}
                                page={page} rowsPerPage={rowsPerPage}
                                sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                ActionsComponent={TPA}
                            />
                        </TableRow>
                    </TableFooter>
                </Table></TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}

            <Snackbar open={openSnackbar} autoHideDuration={setAutoHideDurationTimeoutsecs} onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={severityColor}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Container></Box>)
}