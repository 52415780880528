import React, { useState } from 'react';
import { styled, useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import ApprovalRoundedIcon from '@mui/icons-material/ApprovalRounded';
import HomeIcon from '@mui/icons-material/Home';
import Collapse from '@mui/material/Collapse';
import ApprovalDecision from './Admin/UserManagement/ApprovalDecision';
import UserList from './Admin/UserManagement/UserList';



export const mainTheme = createTheme({
  palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
  fontFamily: ' Roboto, sans-serif',
  components: {
    MuiButton: { styleOverrides: { root: { borderRadius: '10px', color: '#ffffff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' } }, icon: { color: '#ffffff', marginLeft: '7px' } } },
    MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
    MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
    // MuiDialog: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
    MuiTextField: { styleOverrides: { root: { backgroundColor: '#ffffff', marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
    MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
    MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } },
  }
})

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AdminView() {
  const drawerItems = [
    { text: 'Home', icon: <HomeIcon /> },
    {
      text: 'User Management', icon: <PeopleOutlineRoundedIcon />, subItems: [
        { name: 'UserList', subIcon: <ViewListRoundedIcon /> },
        { name: 'ApprovalDecision', subIcon: <HowToRegRoundedIcon /> }
      ]
    },
    {
      text: 'Experience Management', icon: <PsychologyRoundedIcon />, subItems: [
        { name: 'ExperienceList', subIcon: <ViewListRoundedIcon /> },
        { name: 'ApprovalDecision', subIcon: <ApprovalRoundedIcon /> }
      ]
    }
    // { text: 'Media Management', icon: <PsychologyRoundedIcon /> }
  ];

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openNested, setOpenNested] = React.useState(Array(drawerItems.length).fill(false));
  const [submenu, setSubmenu] = useState('');
  const [mainmenu, setMainmenu] = useState('');
  const handleItemClick = (item, isSubItem = false, index) => {
    if (isSubItem) {
      setSubmenu(item);
    } else {
      setMainmenu(item.text);
      setSubmenu('');
      const newOpenNested = [...openNested];
      newOpenNested[index] = !newOpenNested[index];
      setOpenNested(newOpenNested);
    }
  };

  // const handleNestedClick = (item) => {
  //   const index = drawerItems.indexOf(item);
  //   const newOpenNested = [...openNested];
  //   newOpenNested[index] = !newOpenNested[index];
  //   setOpenNested(newOpenNested);
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ bgcolor: '#a5644e' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5, color: 'white',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" noWrap component="div" sx={{ color: 'white' }}>
              Administration
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {drawerItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton onClick={() => handleItemClick(item, false, index)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                    {item.subItems && (openNested[index] ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </ListItem>
                {item.subItems && (
                  <Collapse in={openNested[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem key={subIndex} disablePadding>
                          <ListItemButton onClick={() => handleItemClick(subItem.name, true, index)}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              pl: open ? 10 : 4,
                              px: 5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {subItem.subIcon}
                            </ListItemIcon>
                            <ListItemText primary={subItem.name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
                {index < drawerItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {(mainmenu === 'Home') ? (<UserList />) : (mainmenu === 'User Management' && submenu === 'UserList') ? (
            <UserList />) : (mainmenu === 'User Management' && submenu === 'ApprovalDecision') ? (
              <ApprovalDecision />) : (mainmenu === 'Experience Management' && submenu === 'ExperianceList') ? (
                <ApprovalDecision />
              ) : (mainmenu === 'Experience Management' && submenu === 'ApprovalDecision') ? (
                <Typography paragraph>
                  Content for Experience Management - ApprovalDecision selected.
                </Typography>
              ) : (mainmenu === 'Media Management' && submenu === '') ? (
                <Typography paragraph>
                  Content for Media Management selected.
                </Typography>
              ) : (
            <Typography paragraph>
              <UserList />
            </Typography>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
