import React, { useState } from 'react';
import { CircularProgress, useMediaQuery, Button, TextField, Box, Grid, Typography, Card, Checkbox, Alert, Dialog, DialogTitle, DialogContent, List, ListItemText, Stepper, Step, StepLabel, Divider, MenuItem, Link, Snackbar } from '@mui/material';
import { Close, Circle, SubdirectoryArrowRight } from '@mui/icons-material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { mainTheme } from '../core/TabsCore';
import { emailVerification, resendOtp, verifyOtp } from '../services/AuthService';
import { addRegistration } from '../services/RegistrationService';
import { setTimeoutsecs } from '../common/Common';

export default function SignUp() {
    const [loading, setLoading] = React.useState(false);
    const today = new Date().toISOString().split('T')[0];
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [checked, setChecked] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Enter email', 'Verify email', 'Enter details', 'Registration done'];
    const gen = ['Male', 'Female', 'Transgender'];
    const meetingData = ['Online', 'Face-to-Face'];
    const rel = ['Husband', 'Wife', 'Brother', 'Sister', 'Father', 'Mother', 'Grandfather', 'Grandmother', 'Friend', 'Colleague', 'Other']
    const [disabled, setDisabled] = useState(false);
    const [formObj, setFormObj] = React.useState({
        otp: '',
        name: '',
        sex: '',
        dob: '',
        relation: '',
        otherRelation: '',
        lossDate: '',
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: ''
    })
    const [formObjerror, setFormObjError] = React.useState({
        otp: '',
        name: '',
        sex: '',
        dob: new Date(),
        relation: '',
        otherRelation: '',
        lossDate: new Date(),
        support: '',
        profHelp: null,
        adrs: '',
        phnNum: '',
        emailID: '',
        ref: '',
        meeting: ''
    })
    const disableSendOtpbutton = !formObj.emailID || formObjerror.emailID;
    const disableConfirmbutton = !formObj.emailID || !formObj.otp;
    const disableRegisterbutton = !formObj.emailID || !formObj.name || !formObj.sex || !formObj.dob || !formObj.relation || !formObj.support || !formObj.phnNum || !checked || !formObj.meeting || formObjerror.phnNum || (formObj.relation === "Other" && (!formObj.otherRelation || formObj.otherRelation.trim() === ""));
    const [regAlert, setRegAlert] = useState(false);
    const [regFailed, setRegFailed] = useState('');
    const validateEmail = (email_id) => { const re = /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+.[a-zA-Z]{2,4}$/; return re.test(email_id); };
    const validatePhoneNumber = (phone_number) => {
        const re = /^\+?(\d{1,4})?[\s\-]?\(?\d{1,4}\)?[\s\-]?\d{3,7}([\s\-]?\d{3,7})?$/
        ; return re.test(phone_number);
    };

    const handleOtp = async () => {
        if (formObj.emailID.trim()) {
            setFormObjError(prevState => ({
                ...prevState,
                emailID: ''
            }));
            setLoading(true);
            try {
                let data = {
                    email_id: formObj.emailID.trim()
                }
                let resp = await emailVerification(data);
                setLoading(false);
                setActiveStep(1);
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    console.log("Email verification failed with status code 500:", error.response.data.message);
                    setFormObjError(prevState => ({
                        ...prevState,
                        emailID: error.response.data.message
                    }));
                } else {
                    console.log("An unexpected error occurred:", error.message);
                }
                setLoading(false);
            }
        }
    }

    const handleresendOtp = async () => {
        try {
            let data = {
                email_id: formObj.emailID.trim()
            }
            let resp = await resendOtp(data);
            setDisabled(true);
            if (resp) {
                setTimeout(() => {
                    setDisabled(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            setDisabled(true);
            console.log(error);
            setTimeout(() => {
                setDisabled(false);
            }, setTimeoutsecs);
        }
    }

    const handleverifyOtp = async () => {
        if ((formObj.emailID.trim() && (formObj.otp.trim()))) {
            setLoading(true);
            try {
                let data = {
                    email_id: formObj.emailID.trim(),
                    code: formObj.otp.trim()
                }
                let resp = await verifyOtp(data);
                setLoading(false);
                if (resp.valid) {
                    setActiveStep(2);
                }
                else {
                    setFormObjError(prevState => ({
                        ...prevState,
                        otp: resp.message
                    }));
                }
            } catch (error) {
                console.log("An unexpected error occurred:", error);
                setLoading(false);

            }
        }
    }

    const handleRegister = async () => {
        setLoading(true);
        try {
            let data = {
                full_name: formObj.name.trim(),
                gender: formObj.sex,
                date_of_birth: new Date(formObj.dob),
                relationship: formObj.relation,
                date_you_lost_them: new Date(formObj.lossDate),
                supported_person: formObj.support.trim(),
                any_proffessional_help: formObj.profHelp,
                mobile_number: formObj.phnNum.trim(),
                email_id: formObj.emailID.trim(),
                address: formObj.adrs && formObj.adrs.trim(),
                how_you_know_SAS: formObj.ref && formObj.ref.trim(),
                support_via: formObj.meeting,
                other_relationship: formObj.otherRelation && formObj.otherRelation.trim()

            }
            let resp = await addRegistration(data);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                }, setTimeoutsecs);
                setActiveStep(3);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setRegFailed(error.response.data.message);
                setRegAlert(true);
            } else {
                console.log("An unexpected error occurred:", error);
                setRegFailed('Updated failed');
                setRegAlert(true);
            }
            setTimeout(() => {
                setLoading(false);
                setRegAlert(false);
            }, setTimeoutsecs);
        }
    }

    return (<ThemeProvider theme={mainTheme}><Box display="flex" justifyContent="center" alignItems="center" bgcolor='#efe8de' style={{ minHeight: '100vh' }}>
        <Card sx={{ width: isMobile ? '90%' : (activeStep === 2 ? '60%' : '30%'), display: 'flex', position: 'relative', flexDirection: 'column', alignItems: 'center', border: '2px solid #b5651d', m: '10px', p: 4 }}>
            {activeStep !== 3 && <Link href="/sas" sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
                <Close sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} />
            </Link>}

            <Typography variant='h6' sx={{ color: '#a5644e', mx: '10px' }}>SNEHA-SAS</Typography>

            <Divider textAlign='center' component='li' sx={{ width: '100%', my: 2 }}>Create an account</Divider>

            <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between', my: '12px' }}>
                {activeStep === 0 && <>
                    <Grid item xs={12}>
                        <TextField required label="Email Address" value={formObj.emailID} onChange={(e) => {
                            const emailValue = e.target.value;
                            setFormObj({
                                ...formObj,
                                emailID: emailValue
                            });
                            setFormObjError({
                                ...formObjerror,
                                emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                            });
                        }}
                            error={!!formObjerror.emailID} helperText={formObjerror.emailID} />
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "flex-end" }}>
                        <Button disabled={disableSendOtpbutton} onClick={handleOtp}>Send OTP   {loading ? <CircularProgress /> : null}</Button>
                    </Grid>
                </>}

                {activeStep === 1 && <>
                    <Grid item xs={12}><TextField required label="Email Address" value={formObj.emailID} disabled /></Grid>
                    <Grid item xs={12}><Typography sx={{ color: '#d09d70', mb: '5px', '&:hover': { cursor: 'pointer', color: '#633a09', textDecoration: 'underline' } }}
                        onClick={() => { setActiveStep((prevActiveStep) => prevActiveStep - 1) }}>
                        Incorrect email? Click here to re-enter your email.</Typography></Grid>

                    <Grid item xs={12}><TextField required label="Enter the OTP sent to your email" value={formObj.otp} onChange={(e) => {
                        setFormObj({
                            ...formObj,
                            otp: e.target.value
                        });
                        setFormObjError({
                            ...formObjerror,
                            otp: ''
                        });
                    }}
                        error={!!formObjerror.otp} helperText={formObjerror.otp} /></Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            sx={{
                                color: disabled ? '#ccc' : '#d09d70', // Change color if disabled
                                my: '5px',
                                '&:hover': {
                                    cursor: disabled ? 'not-allowed' : 'pointer', // Change cursor if disabled
                                    color: disabled ? '#ccc' : '#633a09',
                                    textDecoration: 'underline'
                                }
                            }}
                            onClick={!disabled ? handleresendOtp : null} // Only call handler if not disabled
                        >
                            Didn't receive an OTP? Click here to resend.
                        </Typography>
                        <Button disabled={disableConfirmbutton} onClick={handleverifyOtp}>Confirm {loading ? <CircularProgress /> : null}</Button>
                        <Snackbar
                            open={disabled}
                            autoHideDuration={5000}
                            message="OTP has been resent to your email. If you haven't received it, you can try again after a few minutes."
                        />
                    </Grid>
                </>}

                {activeStep === 2 && <>
                    <List sx={{ width: '100%', fontWeight: 'bold', mb: '10px' }}>
                        <ListItemText sx={{ my: 2 }} primary={<span onClick={() => setModalOpen(true)} style={{ cursor: 'pointer', }}><Circle sx={{ fontSize: '60%', mr: '5px' }} />Please read the <b style={{ fontWeight: 'bold', color: '#c9762b' }}>terms and conditions</b> before filling out the form.</span>} />
                        <ListItemText sx={{ my: 2 }} primary={<span><Circle sx={{ fontSize: '60%', mr: '5px' }} />Do fill in all the details requested in the form. This will help us in organizing the sessions effectively.</span>} />
                        <ListItemText sx={{ my: 2 }} primary={<span><Circle sx={{ fontSize: '60%', mr: '5px' }} />Once you submit the form a SNEHA – SAS representative will reach out to you via phone/ email to confirm your acceptance.</span>} />
                    </List>

                    <Grid item xs={6}><TextField required label='Name' value={formObj.name}
                        onChange={(e) => setFormObj({ ...formObj, name: e.target.value })} /></Grid>


                    <Grid item xs={6}><TextField select required label="Gender" value={formObj.sex}
                        onChange={(e) => setFormObj({ ...formObj, sex: e.target.value })}>
                        {gen.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField required type="date" label="Date of Birth" value={formObj.dob.toString()} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setFormObj({ ...formObj, dob: e.target.value })} inputProps={{ max: today }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField select required label="Who did you lose to suicide?" value={formObj.relation} onChange={(e) => setFormObj({ ...formObj, relation: e.target.value })}>
                            {rel.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                        </TextField>
                    </Grid>

                    {formObj.relation === 'Other' && (
                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <SubdirectoryArrowRight />
                            <TextField label='Specify other relationship' value={formObj.otherRelation} onChange={(e) => setFormObj({ ...formObj, otherRelation: e.target.value })} />
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <TextField type="date" label="Date you lost him/her" value={formObj.lossDate.toString()} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setFormObj({ ...formObj, lossDate: e.target.value })} inputProps={{ max: today }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField required label="Who supported you at that point of time?" value={formObj.support} onChange={(e) => setFormObj({ ...formObj, support: e.target.value })} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField select required label='Did you take any professional help?' value={formObj.profHelp} onChange={(e) => setFormObj({ ...formObj, profHelp: e.target.value })}>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Postal address" value={formObj.adrs} onChange={(e) => setFormObj({ ...formObj, adrs: e.target.value })} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField required label="Phone number" value={formObj.phnNum} onChange={(e) => {
                            const phoneNum = e.target.value;
                            setFormObj({
                                ...formObj,
                                phnNum: phoneNum
                            });
                            setFormObjError({
                                ...formObjerror,
                                phnNum: validatePhoneNumber(phoneNum) ? '' : 'Please enter a valid Mobile Number'
                            });
                        }}
                            error={!!formObjerror.phnNum} helperText={formObjerror.phnNum} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField required label="Email ID" value={formObj.emailID} disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="How did you hear about SNEHA-SAS?" value={formObj.ref} onChange={(e) => setFormObj({ ...formObj, ref: e.target.value })} />
                    </Grid>

                    <Grid item xs={6}> <TextField select required label="Session preference" value={formObj.meeting}
                        onChange={(e) => setFormObj({ ...formObj, meeting: e.target.value })}>
                        {meetingData.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography variant='body2'>
                            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                            <span onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}>
                                I agree to the <b style={{ fontWeight: 'bold', color: '#c9762b' }}>terms and conditions</b> of SNEHA - SAS</span>
                        </Typography>
                        <Button disabled={disableRegisterbutton} onClick={handleRegister}>Register {loading ? <CircularProgress /> : null}</Button>
                    </Grid>
                </>}

                {activeStep === 3 && <>
                    <Grid item xs={12} sx={{ mb: 2 }}><Typography>Your details are submitted for review. You will receive an email with further instructions.</Typography></Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button type="submit" href='sas' variant="contained">Okay</Button></Grid>
                </>}
            </Grid>

            <Stepper activeStep={activeStep} alternativeLabel component='footer' style={{ margin: '5px 0' }}>
                {steps.map((label) => (<Step key={label}><StepLabel>{label}</StepLabel></Step>))}
            </Stepper>
        </Card>

        <Dialog open={modalOpen} sx={{ border: '2px solid black', borderRadius: '10px' }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                <Typography component='p' variant='h6'>SAS Terms & Conditions</Typography>
                <Close sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setModalOpen(false)} />
            </DialogTitle>

            <DialogContent><List>
                <ListItemText sx={{ my: 2 }} primary="This is a support group for those who have lost someone by suicide. If you want to talk about other issues which may be disturbing you, or have survived a suicide attempt, we request you to get in touch with SNEHA. " />
                <ListItemText sx={{ my: 2 }} primary="SNEHA - SAS is committed to data privacy and protecting your personal information. Any details you share on this form will not be shared with anyone outside the organisation. Authorised individuals within SNEHA – SAS will be able to access your information." />
                <ListItemText sx={{ my: 2 }} primary="A valid email address and phone number are required  for registration. By providing these details, you agree to allow SNEHA – SAS to contact you on the given email id and the phone number. " />
                <ListItemText sx={{ my: 2 }} primary="Anyone registering with SNEHA - SAS is required  to attend the monthly Sessions that are organised by the group. If you are unable to attend any Session, prior intimation via phone or email, is appreciated." />
                <ListItemText sx={{ my: 2 }} primary="All Sessions will be facilitated by a SNEHA volunteer. " />
                <ListItemText sx={{ my: 2 }} primary="Sessions will be conducted in Tamil and/or English. " />
                <ListItemText sx={{ my: 2 }} primary="SNEHA – SAS requests all participants not to disclose anything shared in the Sessions outside the group." />
                <ListItemText sx={{ my: 2 }} primary="All attendees are requested to use proper language and to be aware of the sensitivities of other group members. Bringing up political, religious and other sensitive topics is not accepted." />
                <ListItemText sx={{ my: 2 }} primary="SNEHA – SAS reserves the right to remove anyone from the group for violating these conditions." />
            </List></DialogContent>
        </Dialog>

        {regFailed && <Snackbar open={regAlert} autoHideDuration={3000}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>{regFailed}</Alert>
        </Snackbar>}
    </Box></ThemeProvider>)
}