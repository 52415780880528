import React, { useState, useEffect } from 'react';
import { Toolbar, Tab, Tabs, useMediaQuery, Box } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import ApprovalDecision from './ApprovalDecision';
import UserList from './UserList';
import { mainTheme, tabThemeAdmin } from '../../TabsCore';
import { PerChk } from '../../../common/Common';

export default function UserManagementTab() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tabIndex, setTabIndex] = useState('0');
    const isUserListHidden = PerChk('USER_LIST');
    const isApprovalListHidden = PerChk('USER_APPROVALLIST');

    useEffect(() => {
        if (isUserListHidden && !isApprovalListHidden) {
            setTabIndex('1');
        } else if (isUserListHidden && isApprovalListHidden) {
            setTabIndex(null);
        }
    }, [isUserListHidden, isApprovalListHidden]);

    if (isUserListHidden && isApprovalListHidden) {
        return null;
    }

    return (
        <ThemeProvider theme={mainTheme}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'right',
                        bgcolor: '#1px solid grey',
                        p: 2,
                        height: '20px',
                        position: 'sticky',
                        top: 0,
                        zIndex: 99,
                    }}
                >
                    <ThemeProvider theme={tabThemeAdmin}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs value={tabIndex} onChange={(e, ti) => setTabIndex(ti)}>
                                {!isUserListHidden && <Tab value="0" label="User List" />}
                                {!isApprovalListHidden && <Tab value="1" label="Approval" />}
                            </Tabs>
                        </Box>
                    </ThemeProvider>
                </Toolbar>

                <Box>
                    {!isUserListHidden && tabIndex === '0' && <UserList />}
                    {!isApprovalListHidden && tabIndex === '1' && <ApprovalDecision />}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
