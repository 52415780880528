import { jwtDecode } from "jwt-decode";

const checkTknExpiry = (onExpireCallback, delay = 1000) => {

    if (sessionStorage.getItem('restoken') !== null) {
        const token = sessionStorage.getItem('restoken');
        const decodedToken = jwtDecode(token);
        const expiryTime = new Date(decodedToken.exp * 1000).getTime();
        const currentTime = new Date().getTime();
        const timeout = expiryTime - currentTime;

        const onExpire = () => {
            if (onExpireCallback) {
                onExpireCallback(true);
            }
            setTimeout(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.href = '/signin';
            }, delay);
        };

        if (timeout > 0) {
            const timerRef = setTimeout(onExpire, timeout);
            return { timerRef, tknExpired: false };
        } else {
            onExpire();
        }
    }
    return null;
}

export default checkTknExpiry;