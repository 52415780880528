import axios from "axios";

export async function getVisitorList() {
    const configuration = {
        url: process.env.SERVER + 'count/list',
        method: 'get',
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddVisitor(data) {
    const configuration = {
        url: process.env.SERVER + 'count/addVistor',
        method: 'post',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function getTotalCount() {
    const configuration = {
        url: process.env.SERVER + 'count/getTotalCount',
        method: 'get',
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function getUniqueCount() {
    const configuration = {
        url: process.env.SERVER + 'count/getUniqueCount',
        method: 'get',
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function getGroupedData() {
    const configuration = {
        url: process.env.SERVER + 'count/getGroupedData',
        method: 'get',
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}