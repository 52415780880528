import axios from "axios";

export async function PermissionList() {

    const configuration = {
        url: process.env.SERVER + 'permission/list',
        method: 'get',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}