import axios from "axios";

export async function ListMedia() {
    const configuration = {
        url: process.env.SERVER + 'media/list',
        method: 'post'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddMedia(data) {
    const configuration = {
        url: process.env.SERVER + 'media/',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'post',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditMedia(id, data) {
    const configuration = {
        url: process.env.SERVER + `media/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UpdateMedia(id, data) {
    const configuration = {
        url: process.env.SERVER + `media/updateStatus/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ArchiveMedia(id) {
    const configuration = {
        url: process.env.SERVER + `media/archive/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UnarchiveMedia(id) {
    const configuration = {
        url: process.env.SERVER + `media/unarchive/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}