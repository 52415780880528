import React from 'react';
import { Typography, List, ListItem, Grid, Link, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';

export default function Support() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (<Card><Grid container spacing={2}>
    <Grid item xs={12}><Typography variant='h6'>Support Us</Typography></Grid>

    <Grid item xs={12}>
      <Typography variant="body1" fontWeight='bold' sx={{ mb: '30px', textTransform: 'none' }}>Show your support and be a part of SNEHA SAS through the following ways:</Typography>
      <Typography fontWeight='bold' sx={{ mb: '5px' }}>Organise Awareness Programmes</Typography>
    </Grid>

    <Grid item xs={isMobile ? 12 : 9}>
      <Typography sx={{ mb: '20px' }}>
        The awareness sessions are interactive and encourages people to share their thoughts and feelings about suicide.
        <span style={{ display: 'block' }}>SNEHA conducts awareness programs for:</span>

        <List>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Schools (for students, parents and teachers)</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Colleges (for students and teaching staff)</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Nursing colleges, medical colleges and Hospitals</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Other NGOs and self-help groups</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Police personnel</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Indian Air Force</ListItem>
          <ListItem><CircleIcon sx={{ fontSize: '65%', mr: '5px' }} />Numerous workplaces</ListItem>
        </List>
      </Typography>

      <Typography sx={{ mb: '20px' }}>
        Interested in knowing more or in organising an Awareness Program at your institution?
        Please write to us at <Link href="mailto:awareness@snehaindia.org" style={{ fontWeight: 'bold' }}>awareness@snehaindia.org</Link> or call us on our helpline at   <Link href="tel:044 24640050" style={{ fontWeight: 'bold' }}>044 24640050</Link>, <Link href="tel:044 24640050" style={{ fontWeight: 'bold' }}>044 24640060</Link>.
      </Typography>
    </Grid>

    <Grid item xs={isMobile ? 12 : 3}>
      <Card sx={{ m: 0, p: 0, bgcolor: '#fff' }}>
        <CardMedia component="img" image="images/Support (1).jpg" alt="images/Support (1).jpg" height='250' />
        <CardContent component={Typography} sx={{ textAlign: 'center' }}>Organise and Create Awareness</CardContent>
      </Card>
    </Grid>

    <Grid item xs={isMobile ? 12 : 9}>
      <Typography fontWeight='bold'>Financial Contribution</Typography><Typography sx={{ mb: '20px' }}>
        Though our services are offered free of charge, SNEHA maintains a premises in Chennai. The premises offers support to callers via telephone, chat and in-person visits. In addition, SNEHA also organises outreach programmes and other activities, especially around World Suicide Prevention Day.
      </Typography>
      <Typography sx={{ mb: '20px' }}>
        If you would like to be a part of SNEHA’s services and want to contribute financially, do call us at <Link href="tel:+91 9445120050" style={{ fontWeight: 'bold' }}>+91 9445120050</Link> or email us at <Link href="mailto:sas@snehaindia.org" style={{ fontWeight: 'bold' }}>sas@snehaindia.org</Link>.
      </Typography>
    </Grid>

    <Grid item xs={isMobile ? 12 : 3}>
      <a href="https://snehaindia.org/new/index.php/donate-2/" target="_blank" rel="noopener noreferrer">
        <Card sx={{ m: 0, p: 0, bgcolor: '#fff', '&:hover': { bgcolor: '#dddddd' } }}>
          <CardMedia component="img" image="images/Support (2).jpg" alt="images/Support (2).jpg" height='250' />
          <CardContent component={Typography} sx={{ textAlign: 'center' }}>Learn how you can contribute</CardContent>
        </Card>
      </a>
    </Grid>
  </Grid></Card>)
}