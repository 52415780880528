import React, { useState } from 'react';
import { Tab, Tabs, useMediaQuery, Grid, Menu, MenuItem } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { tabThemeAdmin } from './TabsCore';
import ExpTab from './MyContent/ExpTab'
import MemTab from './MyContent/MemTab';
import { PerChk } from '../common/Common';

export default function MyCon() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [tabIndex, settabIndex] = useState((PerChk('MYCONTENT_EXPLIST') && PerChk('MYCONTENT_EXP_REJECTEDLIST')) ? '1' : '0');
    const [mobileMenu, setMobileMenu] = useState(null);
    const handleClick = (event) => { setMobileMenu(event.currentTarget) }
    const handleClose = () => { setMobileMenu(null) }

    return (<Grid container sx={{ bgcolor: 'background.paper', height: '100vh', overflow: 'auto' }}>
        <Grid item xs={isMobile ? 12 : 2}>{isMobile ?
            <>
                <MenuIcon id="basic-button" onClick={handleClick} />
                <Menu id="basic-menu" anchorEl={mobileMenu} open={mobileMenu} onClose={handleClose}>
                    <MenuItem hidden={(PerChk('MYCONTENT_EXPLIST') && PerChk('MYCONTENT_EXP_REJECTEDLIST'))} onClick={() => { setMobileMenu(null); settabIndex('0'); }}>My Experience</MenuItem>
                    <MenuItem hidden={(PerChk('MYCONTENT_MEMOLIST') && PerChk('MYCONTENT_MEMO_REJECTEDLIST'))} onClick={() => { setMobileMenu(null); settabIndex('1'); }}>My Memorial Wall</MenuItem>
                </Menu>
            </>
            :
            <ThemeProvider theme={tabThemeAdmin}><Tabs orientation="vertical" value={tabIndex}
                onChange={(e, ti) => settabIndex(ti)} sx={{ bgcolor: '#fcf2e7', height: '100vh' }}>
                <Tab value='0' label="My Experience" hidden={(PerChk('MYCONTENT_EXPLIST') && PerChk('MYCONTENT_EXP_REJECTEDLIST'))} />
                <Tab value='1' label="My Memorial Wall" hidden={(PerChk('MYCONTENT_MEMOLIST') && PerChk('MYCONTENT_MEMO_REJECTEDLIST'))} />
            </Tabs></ThemeProvider>
        }</Grid>

        <Grid item xs={isMobile ? 12 : 10} sx={{ height: '100vh', overflow: 'auto', p: 2 }}>
            {(tabIndex === '0' && (!PerChk('MYCONTENT_EXPLIST') || !PerChk('MYCONTENT_EXP_REJECTEDLIST'))) && <ExpTab />}
            {(tabIndex === '1' && (!PerChk('MYCONTENT_MEMOLIST') || !PerChk('MYCONTENT_MEMO_REJECTEDLIST'))) && <MemTab />}
        </Grid>
    </Grid>)
}