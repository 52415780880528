import React, { useState } from 'react';
import {
    useMediaQuery, Button, TextField, Box, Grid, Typography, Card, Dialog,
    DialogActions, DialogContent, DialogTitle, Stepper, Step, StepLabel, Divider, Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { mainTheme } from '../core/TabsCore';
import { verifyOtp } from '../services/AuthService';
import { registrationVerification } from '../services/RegistrationService';
import { forgotPassword } from '../services/AuthService';
import CircularProgress from '@mui/material/CircularProgress';


export default function ForgotPassword() {
    const [loading, setLoading] = React.useState(false);
    const theme = useTheme(); const isMobile = useMediaQuery(theme.breakpoints.down('sm')); //mobile
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Enter email', 'Verify email', 'Enter New Password'];
    const [formObj, setFormObj] = React.useState({
        otp: '',
        emailID: '',
        newpassword: ''
    });
    const [formObjerror, setFormObjError] = React.useState({
        otp: '',
        emailID: '',
        newpassword: ''
    });
    const disableSendOtpbutton = !formObj.emailID || formObjerror.emailID;
    const disableConfirmbutton = !formObj.emailID || !formObj.otp;
    const disableSubmitbutton = !formObj.emailID || !formObj.newpassword || formObjerror.newpassword;

    const validateEmail = (email_id) => {
        const re = /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+.[a-zA-Z]{2,4}$/;
        return re.test(email_id);
    };

    const validatePassword = (passworddata) => {
        const minLength = 8; // Minimum password length
        const maxLength = 15; // Maximum password length

        if (passworddata.length < minLength) {
            return `Password must be at least ${minLength} characters long`;
        } else if (passworddata.length > maxLength) {
            return `Password must be less than ${maxLength + 1} characters long`;
        } else {
            return ''; // Password is valid
        }
    };

    const handleOtp = async () => {
        if (formObj.emailID.trim()) {
            setFormObjError(prevState => ({
                ...prevState,
                emailID: ''
            }));
            setLoading(true);
            try {
                let data = {
                    email_id: formObj.emailID
                }
                let resp = await registrationVerification(data);
                setLoading(false);
                setActiveStep(1);
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    console.log("Email verification failed with status code 500:", error.response.data.message);
                    setFormObjError(prevState => ({
                        ...prevState,
                        emailID: error.response.data.message
                    }));
                } else {
                    console.log("An unexpected error occurred:", error.message);
                }
                setLoading(false);
            }
        }
    }

    const handleverifyOtp = async () => {
        if ((formObj.emailID.trim() && (formObj.otp.trim()))) {
            try {
                let data = {
                    email_id: formObj.emailID,
                    code: formObj.otp
                }
                let resp = await verifyOtp(data);
                if (resp.valid) {
                    setActiveStep(2);
                }
                else {
                    setFormObjError(prevState => ({
                        ...prevState,
                        otp: resp.message
                    }));
                }
            } catch (error) {
                console.log("An unexpected error occurred:", error);
            }
        }
    }


    const handleforgotPassword = async () => {
        setLoading(true);
        try {
            let data = {
                email_id: formObj.emailID,
                newPassword: formObj.newpassword.trim(),
            }
            let resp = await forgotPassword(data);
            setLoading(false);
            setOpen(true);
        } catch (error) {
            console.log(error);
            setLoading(false);
            window.alert("Password Updated failed");

        }
    }

    return (<ThemeProvider theme={mainTheme}>
        <Box display="flex" justifyContent="center" alignItems="center" bgcolor='#efe8de' style={{ minHeight: '100vh' }}>
            <Card sx={{ width: isMobile ? '90%' : '30%', display: 'flex', position: 'relative', flexDirection: 'column', alignItems: 'center', border: '2px solid #b5651d', m: '10px', p: 4 }}>
                <Link href="/sas" sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} />
                </Link>

                <Typography variant='h6' sx={{ color: '#a5644e', mx: '10px' }}>SNEHA-SAS</Typography>

                <Divider textAlign='center' component='li' sx={{ width: '100%', my: 2 }}>Forgot Password</Divider>

                <Box component={Grid} container spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
                    {activeStep === 0 && (<>
                        <Grid item xs={12}>
                            <TextField required label="Email Address" value={formObj.emailID} onChange={(e) => {
                                const emailValue = e.target.value;
                                setFormObj({
                                    ...formObj,
                                    emailID: emailValue
                                });
                                setFormObjError({
                                    ...formObjerror,
                                    emailID: validateEmail(emailValue) ? '' : 'Please enter a valid email address'
                                });
                            }}
                                error={!!formObjerror.emailID} helperText={formObjerror.emailID} />
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button disabled={disableSendOtpbutton} onClick={handleOtp}>Send OTP   {loading ? <CircularProgress /> : null}</Button>
                        </Grid>
                    </>)}

                    {activeStep === 1 && (<>
                        <Grid item xs={12}><TextField required label="Email Address" value={formObj.emailID} disabled /></Grid>


                        <Grid item xs={12}><TextField required label="Enter the OTP sent to your email" value={formObj.otp} onChange={(e) => {
                            setFormObj({
                                ...formObj,
                                otp: e.target.value
                            });
                            setFormObjError({
                                ...formObjerror,
                                otp: ''
                            });
                        }}
                            error={!!formObjerror.otp} helperText={formObjerror.otp} /></Grid>

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button disabled={disableConfirmbutton} onClick={handleverifyOtp}>Confirm</Button>
                        </Grid>
                    </>)}

                    {activeStep === 2 && (<>
                        <Grid item xs={12}><TextField required label="Email Address" value={formObj.emailID} disabled /></Grid>

                        <Grid item xs={12}>
                            <TextField required label="New Password" type='password' value={formObj.newpassword} onChange={(e) => {
                                const pwValue = e.target.value;
                                setFormObj({
                                    ...formObj,
                                    newpassword: pwValue
                                });
                                setFormObjError({
                                    ...formObjerror,
                                    newpassword: validatePassword(pwValue)
                                });
                            }}
                                error={!!formObjerror.newpassword} helperText={formObjerror.newpassword} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button disabled={disableSubmitbutton} onClick={handleforgotPassword}>Submit  {loading ? <CircularProgress /> : null}</Button>
                        </Grid>
                    </>)}
                </Box>

                <Stepper activeStep={activeStep} alternativeLabel component='footer' style={{ margin: '5px 0' }}>
                    {steps.map((label) => (<Step key={label}><StepLabel>{label}</StepLabel></Step>))}
                </Stepper>
            </Card>
            <Dialog open={open} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Forgot Password </Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setOpen(false)} />
                </DialogTitle>
                <DialogContent>
                    <Typography>Your Password is Updated</Typography>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" href='SignIn'
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none', backgroundColor: 'brown', '&:hover': { backgroundColor: 'maroon' } }} onClick={() => setOpen(false)}>Okay</Button>
                </DialogActions>
            </Dialog>
        </Box >

    </ThemeProvider >)
}