import axios from "axios";

export async function AddMem(data) {
    const configuration = {
        url: process.env.SERVER + 'memorial',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditMem(data, memId) {
    const configuration = {
        url: process.env.SERVER + `memorial/${memId}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UpdateMem(data, memId) {

    const configuration = {
        url: process.env.SERVER + `memorial/updateStatus/${memId}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}


export async function MemDetailslist() {

    const configuration = {
        url: process.env.SERVER + 'memorial/list',
        method: 'post',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function archiveMem(memId) {

    const configuration = {
        url: process.env.SERVER + `memorial/archive/${memId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UnarchiveMem(memId) {

    const configuration = {
        url: process.env.SERVER + `memorial/unarchive/${memId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}