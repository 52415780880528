import axios from "axios";

export async function PublicListMemo() {
    const configuration = {
        url: process.env.SERVER + 'memorial/publicPosts',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'post'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ListMemo() {
    const configuration = {
        url: process.env.SERVER + 'memorial/list',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'post'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddMemo(data) {
    const configuration = {
        url: process.env.SERVER + 'memorial/',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'post',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditMemo(id, data) {
    const configuration = {
        url: process.env.SERVER + `memorial/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UpdateMemo(id, data) {
    const configuration = {
        url: process.env.SERVER + `memorial/updateStatus/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ArchiveMemo(id) {
    const configuration = {
        url: process.env.SERVER + `memorial/archive/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UnarchiveMemo(id) {
    const configuration = {
        url: process.env.SERVER + `memorial/unarchive/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}