import axios from "axios";

export async function ListMtg() {
    const configuration = {
        url: process.env.SERVER + 'meeting/list',
        method: 'get',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddMtg(data) {
    const configuration = {
        url: process.env.SERVER + 'meeting/',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'post',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EdtMtg(id, data) {
    const configuration = {
        url: process.env.SERVER + `meeting/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put',
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ArchMtg(id) {
    const configuration = {
        url: process.env.SERVER + `meeting/cancel/${id}`,
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        method: 'put'
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}