import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import {
    Card,
    Box, Container, Typography, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, MenuItem,
    FormControl, Snackbar, Alert, InputAdornment, OutlinedInput,
    Select, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Toolbar, Tab, Tabs, useMediaQuery, TextField, Link, Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { userList } from '../../../services/UserService';
import { setTimeoutsecs, pBtn, nBtn, setAutoHideDurationTimeoutsecs, formatDate, PerChk, TPA } from '../../../common/Common';
import { MemDetailslist, UpdateMem, EditMem } from '../../../services/MemorialService';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import '../../../../public/styles/client.css';
import { Info } from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageIcon from '@mui/icons-material/Image';


export const mainTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },

        MuiCard: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiContainer: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        // MuiDialog: { styleOverrides: { root: { backgroundColor: '#fcf2e7', borderRadius: '15px', padding: '15px', marginBottom: '20px' } } },
        MuiTextField: { styleOverrides: { root: { marginBottom: '5px' } }, defaultProps: { fullWidth: true } },
        MuiFormHelperText: { styleOverrides: { root: { backgroundColor: '#fcf2e7' } }, defaultProps: { fullWidth: true } },
        MuiInputBase: { styleOverrides: { root: { backgroundColor: '#ffffff' } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
        MuiLink: { styleOverrides: { root: { fontWeight: 'bold', color: '#b5651d', cursor: 'pointer' } }, defaultProps: { underline: 'hover' } }
    }
})

export const pdfTheme = createTheme({
    palette: { primary: { main: '#d09d70' }, secondary: { main: '#633a09' } },
    components: {
        MuiButton: { styleOverrides: { root: { borderRadius: '15px', color: '#fff', backgroundColor: '#a5644e', '&:hover': { backgroundColor: '#c9762b' }, '&.Mui-disabled': { backgroundColor: '#d3d3d3', color: '#333' } }, icon: { color: '#fff', marginLeft: '7px' } } },
        MuiCard: { styleOverrides: { root: { borderRadius: '15px', padding: '15px' } } },
        MuiContainer: { styleOverrides: { root: { borderRadius: '15px', padding: '15px', marginTop: "20px" } } },
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' }, h6: { textAlign: 'start', marginBottom: '10px', color: '#b5651d', fontWeight: 'bold' } } },
    }
})

const tabTheme = createTheme({
    palette: { primary: { main: '#000000' }, secondary: { main: '#ffffff' } },
    components: { MuiTab: { styleOverrides: { root: { textTransform: 'none', fontSize: '95%', fontWeight: 'bold', color: '#a5644e', '&:hover': { color: '#000000' } } } } }
})


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function MemorialApprovalList() {
    const [page, setPage] = React.useState(0);
    const [memList, setMemList] = useState([]);
    const [userData, setUserData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [fullContent, setFullContent] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rejectloading, setRejectLoading] = useState(false);
    const [approvalAlert, setApprovalAlert] = useState(false);
    const [approveFailed, setApproveFailed] = useState('');
    const [rejectAlert, setRejectAlert] = useState(false);
    const [rejectFailed, setRejectFailed] = useState('');
    const [remarks, setRemarks] = useState(false);
    const [remarksfield, setRemarksfield] = useState('');
    const [submitAlert, setSubmitAlert] = useState(false);
    const [submitFailed, setSubmitFailed] = useState('');
    const [valueData, setValue] = useState('');
    const visibilitydata = ['Members', 'Public'];
    const quill = useRef();
    const userId = sessionStorage.getItem('userId');
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [dod, setDod] = useState('');
    const [visibility, setVisibility] = useState('');
    const disableaddSubmitbutton = !visibilitydata || !name || !valueData;
    const [open, setOpen] = useState(false);
    const disableRemarks = !remarksfield;
    const [selectedFile, setSelectedFile] = useState('');
    const [preview, setPreview] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const fileInputRef = useRef('');
    const [imageView, setImageView] = useState(false);

    // useEffect(() => {
    //     if (selectedRow) {
    //         const base64Decoded = atob(selectedRow.message);
    //         const decodedHTML = decodeURIComponent(base64Decoded);
    //         setFullContent(decodedHTML);
    //         setValue(decodedHTML);
    //         setName(selectedRow.name);
    //         setDob(selectedRow.birth_date);
    //         setDod(selectedRow.death_date);
    //         setVisibility(selectedRow.visibility);
    //     }
    // }, [selectedRow]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRowClick = (row) => {
        setSelectedRow(row);
        setExpanded(true);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - memList.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleApprove = async (memData) => {
        setLoading(true);
        let data = {
            memopost_status: 'Published'
        }
        try {
            let resp = await UpdateMem(data, memData._id);
            setApprovalAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setExpanded(false);
                    userDatalist();
                    ExpDataList();
                    setApprovalAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setApproveFailed(error.response.data.message);
                setApprovalAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setApproveFailed('Updated failed');
                setApprovalAlert(true);
            } setTimeout(() => {
                setLoading(false);
                // setExpanded(false);
                setApprovalAlert(false);
            }, setAutoHideDurationTimeoutsecs);
        }
    };

    const handleReject = async (memData) => {
        setRejectLoading(true);
        let data = {
            memopost_status: 'Rejected',
            remarks: remarksfield,
        }
        try {
            let resp = await UpdateMem(data, memData._id);
            setRejectAlert(true);
            if (resp) {
                setTimeout(() => {
                    setRejectLoading(false);
                    setRemarks(false);
                    userDatalist();
                    ExpDataList();
                    setRejectAlert(false);
                    setRemarksfield('');
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setRejectFailed(error.response.data.message);
                setRejectAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setRejectFailed('Rejection failed');
                setRejectAlert(true);
                setRemarksfield('');
            } setTimeout(() => {
                setRejectLoading(false);
                // setRemarks(false);
                // setRejectAlert(false);
            }, setAutoHideDurationTimeoutsecs);
        }
    };



    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "color",
        "clean",
    ];

    const handleSubmit = async () => {
        const encodedContent = encodeURIComponent(valueData);
        const base64EncodedContent = btoa(encodedContent);
        setLoading(true);
        try {
            let data = {
                name: name,
                birth_date: dob,
                death_date: dod,
                image: preview,
                image_name: selectedFile && selectedFile.name ? selectedFile.name : selectedFile,
                message: base64EncodedContent,
                memopost_status: 'Pending',
                memopost_updatedby: userId,
                visibility: visibility
            }
            let resp = await EditMem(data, selectedRow._id);
            setSubmitAlert(true);
            if (resp) {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(false);
                    userDatalist();
                    ExpDataList();
                    setSubmitAlert(false);
                }, setTimeoutsecs);
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setSubmitFailed(error.response.data.message);
                setSubmitAlert(true);
            } else {
                console.log("An unexpected error occurred:", error.message);
                setSubmitFailed('Update failed');
                setSubmitAlert(true);
            } setTimeout(() => {
                setLoading(false);
                // setOpen(false);
                setSubmitAlert(false);
            }, setAutoHideDurationTimeoutsecs);
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link"],
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    );


    const handleEditRowClick = (row) => {
        setExpanded(false);
        setSelectedRow(row);
        setOpen(true);
    }

    const handleImageView = async (row) => {
        setImageView(true);
        setExpanded(false);
        setSelectedRow(row);
    };

    const ExpDataList = async () => {
        try {
            let resp = await MemDetailslist();
            const filteredList = resp.result.filter(x => x.memopost_status == 'Pending');
            setMemList(filteredList.reverse())
        } catch (error) {
            console.log(error)
        }
    };

    const userDatalist = async () => {
        try {
            let resp = await userList();
            setUserData(resp.result);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            await userDatalist();
            await ExpDataList();
        };

        if (isMounted) {
            fetchData();
            if (selectedRow) {
                const base64Decoded = atob(selectedRow.message);
                const decodedHTML = decodeURIComponent(base64Decoded);
                setFullContent(decodedHTML);
                setValue(decodedHTML);
                setName(selectedRow.name);
                setDob(selectedRow.birth_date);
                setDod(selectedRow.death_date);
                setVisibility(selectedRow.visibility);
                setSelectedFile(selectedRow.image_name);
                setPreview(selectedRow.image);
            }
        }

        return () => {
            isMounted = false;
        };
    }, [selectedRow]);

    const filteredUserData = userData.filter(user => {
        return user.full_name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const filteredData = memList.filter(memData => {
        const user = userData.find(user => user._id === memData.memopost_createdby);

        const matchesSearchQuery = Object.values(memData).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });
        const matchesUserData = filteredUserData.some(user => user._id === memData.memopost_createdby);

        return matchesSearchQuery || matchesUserData;
    });


    const getUserExpObj = (id) => {
        let obj = userData.filter(x => x._id == id)[0];
        return obj;
    }


    return (<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container component="main" sx={{ width: '80vw' }}>

            {memList.length > 0 && <>
                <Box sx={{ mb: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
                        <FormControl sx={{ width: '130px' }}>
                            <TextField
                                label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </FormControl>
                    </Box >

                </Box></>}
            {filteredData.length > 0 ?
                < Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }}>
                                <TableRow sx={{ alignItems: 'center' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date of Birth</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date of Death</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Posted By</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Visible</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '2%' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredData
                                ).map((row) => (
                                    <TableRow key={row._id} onClick={() => handleRowClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#fcf2e7',
                                            },
                                        }}>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {formatDate(row.birth_date)}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {formatDate(row.death_date)}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {getUserExpObj(row.memopost_createdby).full_name}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textTransform: 'none',
                                                color: 'darkblue'
                                            }}
                                            component="th"
                                            scope="row"
                                        >{row.memopost_status}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'none' }} component="th" scope="row">
                                            {row.visibility}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Tooltip hidden={PerChk('EDIT_MEMORIAL')} title="Edit">
                                                    <EditIcon sx={{ color: '#b5651d' }} onClick={(e) => { e.stopPropagation(); handleEditRowClick(row) }}></EditIcon>
                                                </Tooltip>
                                                {row.image &&
                                                    <Tooltip title="View Image">
                                                        <ImageIcon sx={{ color: '#b5651d' }} onClick={(e) => { e.stopPropagation(); handleImageView(row) }}></ImageIcon>
                                                    </Tooltip>
                                                }
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={memList.length}
                                        page={page} rowsPerPage={rowsPerPage}
                                        sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                        onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                        ActionsComponent={TPA}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer></Paper> : <Typography variant='h6' sx={{ textAlign: 'center' }}>No records</Typography>}
        </Container>
        {selectedRow &&
            <Dialog fullWidth maxWidth='md' open={imageView} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow.name} - Upload Image</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setImageView(false)} />
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`data:image/png;base64,${selectedRow.image}`} alt="Preview" style={{ width: '50%', height: '50%' }} />
                    </Box>
                </DialogContent>
            </Dialog>
        }
        {selectedRow &&
            <Dialog fullWidth maxWidth='lg' open={open} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Share Your Memorial Wall</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setOpen(false)} />
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Box component={Grid} container spacing={2} sx={{ flexGrow: 1, p: 3 }} >
                            <Grid item sm={6}><TextField required label="Name" value={name}
                                onChange={(e) => setName(e.target.value)}
                            /></Grid>
                            <Grid item sm={6}><TextField select required label="Visibility" value={visibility}
                                onChange={(e) => setVisibility(e.target.value)}>
                                {visibilitydata.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required type="date" label="Date of Birth" value={dob.toString()} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setDob(e.target.value)} inputProps={{ max: today }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required type="date" label="Date of Death" value={dod.toString()} InputLabelProps={{ shrink: true }} InputProps={{ onKeyDown: (e) => e.preventDefault() }} onChange={(e) => setDod(e.target.value)} inputProps={{ max: today }} />
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="icon-button-file"
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-image" disabled InputLabelProps={{ shrink: true }}>Upload Image</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-image"
                                        value={selectedFile && selectedFile.name ? selectedFile.name : selectedFile}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="upload picture" component="span" onClick={handleClick}>
                                                    <AddPhotoAlternateIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Upload Image"
                                        readOnly
                                        sx={{
                                            '& .MuiOutlinedInput-input': {
                                                cursor: 'default',
                                                pointerEvents: 'none',
                                                color: 'rgba(0, 0, 0, 0.38)', // Disabled text color
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(0, 0, 0, 0.23)', // Disabled border color
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Box>
                        <ReactQuill
                            ref={(el) => (quill.current = el)}
                            className="ql-container ql-snow"
                            value={valueData}
                            formats={formats}
                            modules={modules}
                            onChange={(content) => setValue(content)} />
                        <small><Info /> <b>The size of images shouldn't exceed 256 kB</b></small>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={disableaddSubmitbutton || loading}
                        variant="contained"
                        sx={pBtn} onClick={handleSubmit}>Save{loading ? <CircularProgress /> : null}</Button>
                </DialogActions>
                {submitFailed ? <Snackbar
                    open={submitAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {submitFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={submitAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Memorial post updated
                    </Alert>
                </Snackbar>}

            </Dialog>}

        {selectedRow &&
            <Dialog fullWidth maxWidth='lg' open={expanded}  >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>{selectedRow.name}</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => setExpanded(false)} />
                </DialogTitle>
                <DialogContent>
                    <Card sx={{ marginTop: '10px' }}>
                        <Typography className="ql-editor" dangerouslySetInnerHTML={{ __html: fullContent }}></Typography>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button hidden={PerChk('REJECT_MEMORIAL')} type="submit"
                        variant="contained" disabled={loading}
                        sx={nBtn} onClick={() => { setExpanded(false); setRemarks(true) }}>Reject</Button>

                    <Button hidden={PerChk('APPROVE_MEMORIAL')} type="submit"
                        variant="contained" disabled={loading}
                        sx={pBtn} onClick={() => handleApprove(selectedRow)}>Approve{loading ? <CircularProgress /> : null}</Button>

                </DialogActions>
                {approveFailed ? <Snackbar
                    open={approvalAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {approveFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={approvalAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Post approved
                    </Alert>
                </Snackbar>}

            </Dialog>
        }
        {selectedRow &&

            <Dialog open={remarks} sx={{ border: '2px solid black', borderRadius: '10px' }} >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', borderBottom: '2px solid #b5651d' }}>
                    <Typography component='p' variant='h6' sx={{ textTransform: 'none' }}>Remarks</Typography>
                    <CloseIcon sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={(e) => { setRemarks(false); setRemarksfield('') }} />
                </DialogTitle>
                <DialogContent>
                    <TextField margin="normal" required label="Remarks" autoFocus value={remarksfield}
                        onChange={(e) => setRemarksfield(e.target.value)} />

                </DialogContent>
                <DialogActions>
                    <Button type="submit"
                        variant="contained"
                        sx={nBtn} disabled={disableRemarks || rejectloading} onClick={() => handleReject(selectedRow)}>Submit{rejectloading ? <CircularProgress /> : null}</Button>
                </DialogActions>

                {rejectFailed ? <Snackbar
                    open={rejectAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {rejectFailed}
                    </Alert>
                </Snackbar> : <Snackbar
                    open={rejectAlert}
                    autoHideDuration={setAutoHideDurationTimeoutsecs}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Post rejected
                    </Alert>
                </Snackbar>}

            </Dialog>}
    </Box>
    );
}









