import axios from "axios";

export async function userList() {
    const configuration = {
        url: process.env.SERVER + 'users/list',
        method: 'get',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function usersList() {
    const configuration = {
        url: process.env.SERVER + 'users/all',
        method: 'get',
        // headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function user(id) {
    const configuration = {
        url: process.env.SERVER + `users/${id}`,
        method: 'get',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function verifyUser(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/verifyUser',
        method: 'post',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function phqQueGet() {
    const configuration = {
        url: process.env.SERVER + 'questions/list',
        method: 'get',
        headers: { token: `${sessionStorage.getItem("restoken")}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function phqAnsPost(data) {
    const configuration = {
        url: process.env.SERVER + 'responses/add',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem("restoken")}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function phqAnsList() {
    const configuration = {
        url: process.env.SERVER + 'responses/list',
        method: 'get',
        headers: { token: `${sessionStorage.getItem("restoken")}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function Edituser(userId, data) {
    const configuration = {
        url: process.env.SERVER + `users/${userId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function Disableuser(userId) {
    const configuration = {
        url: process.env.SERVER + `users/disable/${userId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function Enableuser(userId) {
    const configuration = {
        url: process.env.SERVER + `users/enable/${userId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddAdmin(data) {
    const configuration = {
        url: process.env.SERVER + 'users/addAdmin',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function AddMem(data) {
    const configuration = {
        url: process.env.SERVER + 'registration/addOfflineMember',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditMem(Id,data) {
    const configuration = {
        url: process.env.SERVER + `users/offlineMember/${Id}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}