import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'toastr/build/toastr.min.css';
//import '../public/styles/client.css';
//import '../public/styles/lux.bootswatch.css';
import axios from 'axios';
import toastr from 'toastr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import allBlogReducer from './blogs/reducers/allBlog';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import TabsCore from './core/TabsCore';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import Admin from './core/Admin_View';
import MyAc from './core/MyAc';
import { NotFound } from './auth/NotFound';
import '@babel/polyfill';
import ForgotPassword from './auth/Forgot_password';

axios.defaults.baseURL = process.env.API_URL;
toastr.options.positionClass = 'toast-bottom-right';
toastr.options.closeButton = true;
const reducers = combineReducers({ allBlog: allBlogReducer })

ReactDOM.render(<Provider store={createStore(reducers, applyMiddleware(thunk, logger))}>
  <BrowserRouter history={createBrowserHistory()}><Switch>
    <Route exact path="/"><Redirect to="/sas" /></Route>
    <Route path="/sas" component={TabsCore} />
    <Route path="/SignIn" component={SignIn} />
    <Route path="/ForgotPassword" component={ForgotPassword} />
    <Route path="/SignUp" component={SignUp} />
    <Route path="/sasadmin" component={Admin} />
    <Route path="/myaccount" component={MyAc} />
    <Route path="" component={NotFound} />
  </Switch></BrowserRouter>
</Provider>, document.getElementById('app'))