import axios from "axios";

export async function login(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/login',
        method: 'post',
        data: data

    };
    try {
        const response = await axios(configuration);
        sessionStorage.setItem('restoken', response.data.data.token)
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function forgotPassword(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/forgotPassword',
        method: 'put',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function emailVerification(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/emailVerification',
        method: 'post',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function resendOtp(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/resendOtp',
        method: 'post',
        data: data

    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export async function verifyOtp(data) {
    const configuration = {
        url: process.env.SERVER + 'auth/verifyOtp',
        method: 'post',
        data: data
    };
    try {
        const response = await axios(configuration);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching customer data:", error);
        throw error;
    }
}

export async function resetPassword(userId, data) {
    const configuration = {
        url: process.env.SERVER + `auth/resetPassword/${userId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function resetPasswordByAdmin(userId, data) {
    const configuration = {
        url: process.env.SERVER + `auth/changePassword/${userId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}