import React, { useState } from 'react';
import { Button, TextField, Link, Grid, Box, Card, Divider, Typography, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { mainTheme, StyledAlert } from '../core/TabsCore';
import { login } from '../services/AuthService';

export default function SignIn() {
  const [email_id, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errMsg, seterrMsg] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const disableLoginbutton = !email_id || !password || emailError;
  const validateEmail = (email_id) => { const re = /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+.[a-zA-Z]{2,4}$/; return re.test(email_id); };

  const handleLogin = async () => {
    if ((email_id.trim() && password.trim())) {
      setEmailError('');
      try {
        const resp = await login({ email_id: email_id, password: password }); if (resp) {
          sessionStorage.setItem('email_id', email_id);
          sessionStorage.setItem('userId', resp.data.id);
          sessionStorage.setItem('uPer', JSON.stringify(resp.data.permission))
          window.location.href = '/sas';
        }
      } catch (error) {
        console.log(error); setEmail(''); setPassword('');
        seterrMsg(true); setTimeout(() => seterrMsg(false), 3000);
      }
    }
  }

  return (<ThemeProvider theme={mainTheme}><Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#efe8de', minHeight: '100vh' }}>
    <StyledAlert severity='error' hidden={!errMsg}>Incorrect email and/or password</StyledAlert>

    <Card sx={{ width: isMobile ? '90%' : '30%', position: 'fixed', border: '2px solid #b5651d' }} component={Grid} container spacing={1}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}><Link href="/sas"><Close sx={{ color: 'white', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} /></Link></Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}><Typography variant='h6'>SAS - SNEHA</Typography></Grid>
      <Grid item xs={12}><Divider textAlign='center' component='li' sx={{ width: '100%', my: 2 }}>Sign In</Divider></Grid>

      <Grid item xs={12}><TextField margin="normal" required label="Email Address" autoFocus value={email_id} helperText={emailError} error={!!emailError}
        onChange={(e) => { const emailValue = e.target.value; setEmail(emailValue); setEmailError(validateEmail(emailValue) ? '' : 'Please enter a valid email address'); }} /></Grid>

      <Grid item xs={12}><TextField margin="normal" required label="Password" type="password" value={password}
        onChange={(e) => { const pwValue = e.target.value; setPassword(pwValue); }} /></Grid>

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component={Link} href="ForgotPassword">Forgot password?</Typography>
        <Button disabled={disableLoginbutton} onClick={handleLogin}>Sign In</Button>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}><Typography component={Link} href="SignUp">Don't have an account? Sign Up</Typography></Grid>
    </Card>
  </Box></ThemeProvider>)
}