import axios from "axios";

export async function AddExp(data) {
    const configuration = {
        url: process.env.SERVER + 'experience',
        method: 'post',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function EditExp(data, expId) {
    const configuration = {
        url: process.env.SERVER + `experience/${expId}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ExpDetailslist() {

    const configuration = {
        url: process.env.SERVER + 'experience/list',
        method: 'post',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function ExpPubDetailslist(data) {

    const configuration = {
        url: process.env.SERVER + 'experience/publicPosts',
        method: 'post',
        data: data,
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UpdateExp(data, expId) {

    const configuration = {
        url: process.env.SERVER + `experience/updateStatus/${expId}`,
        method: 'put',
        data: data,
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function archiveExp(expId) {

    const configuration = {
        url: process.env.SERVER + `experience/archive/${expId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function UnarchiveExp(expId) {

    const configuration = {
        url: process.env.SERVER + `experience/unarchive/${expId}`,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` }
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}


