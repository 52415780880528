import axios from "axios";

export async function getRoletypeList(data) {
    const configuration = {
        url: process.env.SERVER + 'roletype/list',
        method: 'post',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}

export async function updateRoletype(roleId, data) {
    const configuration = {
        url: process.env.SERVER + 'roletype/' + roleId,
        method: 'put',
        headers: { token: `${sessionStorage.getItem('restoken')}` },
        data: data
    };
    try { const response = await axios(configuration); return response.data; }
    catch (error) { throw error }
}